import React from 'react';

import styles from '../../styles/user/UserProfile.module.scss';

import { SEARCH_CATEGORIES } from './utils';

const searchTypes = [
  {
    id: 'btnstaff',
    value: SEARCH_CATEGORIES.STAFF,
    label: 'Staff',
  },
  {
    id: 'btncommittee',
    value: SEARCH_CATEGORIES.COMMITTEE,
    label: 'Committee',
  },
  {
    id: 'btndivision',
    value: SEARCH_CATEGORIES.DIVISION,
    label: 'Division',
  },
]

const SearchBarRadio = ({ currentRadio, handleRadioChange }) => {
  return (
    <div
      className={`btn-group btn-group-sm ${styles['directory-toggle-btn']} mw-100 w-100 mb-1`}
      role='group'
      aria-label='Search between Staff, Committee, and Division'>
      {searchTypes.map((t, inx) => <React.Fragment key={`search-radio${t.id}-${inx}`}>
        <input
          type='radio'
          className='btn-check'
          name='radio'
          id={t.id}
          autoComplete='off'
          checked={currentRadio === t.value}
          value={t.value}
          onChange={handleRadioChange}
        />
        <label className='btn btn-outline-primary' htmlFor={t.id}>
          {t.label}
        </label>
      </React.Fragment>)}
    </div>)
}

export default SearchBarRadio