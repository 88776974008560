import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/report-tracking/ReportTile.module.scss';
import { format } from 'date-fns';

const ReportTile = props => {
  const { day, onTileClick, report, status, setHeaderColor } = props;
  let tileClass, reportStatus;
  if (status) {
    reportStatus = status;
    reportStatus = reportStatus.split('_');
    reportStatus = reportStatus.join(' ');

    switch (reportStatus.toLowerCase()) {
      case 'upcoming':
        tileClass = styles['upcoming'];
        break;
      case 'past due':
        tileClass = styles['past-due'];
        break;
      case 'completed':
        tileClass = styles['completed'];
        break;
      case 'received':
        tileClass = styles['received'];
        break;
      default:
        tileClass = '';
        break;
    }
  }

  const onTileClickHandler = (report, status) => {
    onTileClick(report, status);
    setHeaderColor(status);
  };

  const emailDateCheck = (report, day) => {
    if (status === 'past_due') {
      let reportOfDate = {};
      let dayOf = new Date(day).getTime();
      for (let i = report.attachment.length - 1; 0 <= i; i--) {
        let dueDate = new Date(report.attachment[i].due_date).getTime();
        if (dueDate === dayOf) {
          reportOfDate = report.attachment[i];
          break;
        }
      }
      if (reportOfDate.late_email_sent) {
        let emailDate = new Date(reportOfDate.late_email_sent);
        return format(emailDate, 'MM/dd/yy');
      }
    }
    return '';
  };

  let committees = '';
  for (let i = 0; i < report.committees.length; i++) {
    committees += report.committees[i].committee_name + ', ';
  }
  let emailDate = emailDateCheck(report, day);

  return (
    <div
      onClick={() => {
        onTileClickHandler(report, status);
        props.setHeaderColor(report.op_status);
      }}
      className={styles['due-reports-list-box']}>
      <div className={styles['due-reports-list-idx']}>
        <div className={`${styles['report-sidelabel-container']} ${tileClass}`}>
          {status ? (
            <p className={`${styles['report-sidelabel-text']}`}>
              {reportStatus}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles['due-reports-list-content']}>
        <p className={styles['due-reports-list-title']}>{report.title}</p>
        <p className={styles['due-reports-list-locallaw']}>
          {report.all_local_laws}
          <br />
          Type: <span className='capitalize'>{report.tracker_type}</span>
          {status === 'past_due' && emailDate && (
            <span className={styles['email-date']}>
              Late Advisory: {emailDate}
            </span>
          )}
        </p>
        <p className={styles['due-reports-list-subtitle']}>
          {committees.substring(0, committees.length - 2)}
        </p>
      </div>
    </div>
  );
};

ReportTile.propTypes = {
  id: PropTypes.number,
  onTileClick: PropTypes.func.isRequired,
  report: PropTypes.shape({
    title: PropTypes.string.isRequired,
    committees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        committee_name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ReportTile;
