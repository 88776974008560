import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { acceptedFileTypes } from '../../utils/helper';
import { creatingMilestoneConfirm, submitAgencyReport } from '../../actions/reportTrackingAction';
import styles from '../../styles/report-tracking/Agency.module.scss';
import { format } from 'date-fns';
import File from '../library/File';
import TypeDropdown from '../library/TypeDropdown';
import {
  FileUploadIcon,
  CalendarIcon,
  BoxArrowInDownIcon,
  TrashIcon,
} from '../../services/SvgLibrary';
import MiniFileUpload from '../library/MiniFileUpload';

export const FileUploadArea = ({ setAttachment }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const dropRef = useRef(null);

  const handleFiles = useCallback(
    files => {
      const fileArray = Array.from(files);
      setAttachment(prevState => {
        const tempAttach = [...prevState];
        fileArray.forEach(file => {
          file.file_name = file.name;
          tempAttach.push(file);
        });
        return tempAttach;
      });
    },
    [setAttachment]
  );

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    if (dropRef.current && !dropRef.current.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const onFileChange = e => {
    const { files } = e.target;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const openFileDialog = e => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileExtensions = acceptedFileTypes
    .split(',')
    .map(type => type.trim())
    .filter(type => type.startsWith('.'));

  const displayFormats = fileExtensions.join(', ');

  return (
    <div className={styles['upload-area']}>
      <label className='mb-3'>Attachments</label>
      <div
        ref={dropRef}
        className={`${styles['drop-zone']} ${
          isDragging ? styles['dragging'] : ''
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        style={{
          borderRadius: '8px',
          padding: '40px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          transition: 'all 0.2s ease-in-out',
        }}>
        {isDragging ? (
          <div className={styles['drag-content']}>
            <div className={styles['drag-icon']}>
              <BoxArrowInDownIcon width='80px' height='80px' fill='#5850ed' />
            </div>
            <h3 className={styles['drag-text']}>Release to Upload</h3>
            <p className={styles['drag-subtext']}>
              Your files will be added to the report
            </p>
          </div>
        ) : (
          <>
            <div className='mb-3'>
              <FileUploadIcon
                width='100px'
                height='100%'
                className={styles['upload-icon']}
              />
            </div>

            <h5 className='mb-3'>
              Drag & drop your files here or choose a file
            </h5>
            <p className='mb-4 text-body-tertiary'>
              Supported formats: {displayFormats}
            </p>

            <button
              type='button'
              className={styles['browse-btn']}
              onClick={openFileDialog}>
              Browse Files
            </button>
          </>
        )}

        <input
          type='file'
          name='attachment'
          className={styles['file-input']}
          accept={acceptedFileTypes}
          multiple
          ref={fileInputRef}
          onChange={onFileChange}
        />
      </div>
    </div>
  );
};

const UploadReport = ({ className, ...props }) => {
  const [attachment, setAttachment] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [fileLimitExceeded, setFileLimitExceeded] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [reportFocus, setReportFocus] = useState(false);
  const [reportSearch, setReportSearch] = useState('');
  const [reportSelect, setReportSelect] = useState(null);
  const reports = props.allReports.filter((tracker)=>tracker.tracker_type === props.trackerTypeValue && (!tracker.completed || tracker.tracker_type === 'report'))
  const [confirmName, setConfirmName] = useState('')
  const [confirmTitle, setConfirmTitle] = useState('')
  const [confirmAgency, setConfirmAgency] = useState(null)
  const [confirmEmail, setConfirmEmail] = useState('')
  const [confirmDate, setConfirmDate] = useState('')
  const [confirmSign, setConfirmSign] = useState(false)
  const [confirmUpload, setConfirmUpload] = useState([])
  const [validateConfirm, setValidateConfirm] = useState(['name', 'title', 'agency', 'email', 'date', 'sign'])
  const [confirmNotes, setConfirmNotes] = useState('')
  const dispatch = useDispatch();
  const message = useSelector(state => state.reportTrackingReducer.message);
  const [isSubmitted, setIsSubmitted] = useState(false)

  const uploadOpReqReport = e => {
    e.preventDefault();
    if (props.trackerTypeValue === 'report') {
      dispatch(
        submitAgencyReport(
          reportSelect.id,
          attachment,
          encodeURIComponent(dueDate)
        )
      );
    }
  };

  const confirmingMilestone = e => {
    e.preventDefault();
    dispatch(
      creatingMilestoneConfirm({
        'milestone' : reportSelect.id,
        'confirmName': confirmName,
        'confirmTitle': confirmTitle,
        'confirmAgency': confirmAgency,
        'confirmEmail': confirmEmail,
        'confirmDate': confirmDate,
        'milestone_attachment': confirmUpload,
        'confirmNotes': confirmNotes,
      })
    )
  }

  let dueDateDict = {};
  if (reportSelect) {
    reportSelect.attachment.forEach(report => {
      if (dueDateDict[report.due_date] && report.file) {
        dueDateDict[report.due_date].push(report);
      } else {
        if (report.file) {
          dueDateDict[report.due_date] = [report];
        } else {
          dueDateDict[report.due_date] = [];
        }
      }
    });
  }

  let getOrderedDates = Object.keys(dueDateDict).sort((a, b) => {
    a = new Date(a);
    b = new Date(b);
    return a > b ? -1 : a < b ? 1 : 0;
  });

  const onDeleteNewAttachment = (id) => {
    setAttachment(prevState => {
      const newArray = [...prevState];
      newArray.splice(id, 1);
      return newArray;
    });
  };

  // Deletes the attached report file from the database
  const onDeleteMilestoneAttachment = (e, id) => {
    setConfirmUpload(prevState => {
      const newArray = [...prevState];
      newArray.splice(id, 1);
      return newArray;
    })
    e.stopPropagation();
  };

  const findInList = (textSelect, list, param, param2=null) => {
    let selected = null
    list.forEach((rep)=> {
      if( (!param2 && rep[param] === textSelect) || ( param2 && `${rep[param]} - ${rep[param2]}` === textSelect)) {
        selected = rep;
      }
    });
    return selected;
  };

  const updateValidation = (field, oldValue, newValue) => {
    if (!oldValue && newValue) {
      let index = validateConfirm.indexOf(field);
      validateConfirm.splice(index, 1);
      setValidateConfirm(validateConfirm);
    } else if (oldValue && !newValue) {
      validateConfirm.push(field);
      setValidateConfirm(validateConfirm);
    }
  };

  let attaches = [];
  if (attachment.length > 0) {
    attaches = attachment.map((report, idx) => {
      return (
        <div key={idx} className={`card p-2 mb-3 ${styles['new-file']}`}>
          <div className='card-body d-flex align-items-center justify-content-between p-0 m-0 w-100'>
            <File
              file={report}
              id={`new-${idx}`}
              getFileAndDownload={() => {}}
              deletePermission={false}
              newUpload={true}
            />
            <TrashIcon
              classNameProp={`${styles['delete-icon']} ms-3`}
              onClickFunction={() => {
                onDeleteNewAttachment(idx)
              }}
            />
          </div>
        </div>
      );
    });
  }

  const handleReportClick = e => {
    let rep = findInList(e.target.innerText, reports, 'title', 'all_local_laws');
    if (rep && reportSelect !== rep) {
      setDueDate('');
      setAttachment([]);
      setReportSelect(rep);
    }
    setReportSearch(e.target.innerText);
    setReportFocus(false);
  };

  const handleReportClickOutside = () => {
    setReportFocus(false);
    if (reportSelect) {
      setReportSearch(reportSelect.title);
    }
  };

  return (
    <form
      onSubmit={(e)=> {
        if(props.trackerTypeValue === 'report'){
          uploadOpReqReport(e)
          setIsSubmitted(true)
        } else if(props.trackerTypeValue === 'milestone') {
          confirmingMilestone(e)
          setIsSubmitted(true)
        }
      }}
      className={`${className} ${
        styles['form']
      } w-100 h-100 d-flex flex-column justify-content-center ${
        dueDate || props.trackerTypeValue === 'milestone'
          ? styles['form-with-date']
          : ''
      }`}>

      <div
        className={`${styles['form-container']} w-100 mx-auto mw-100 flex-grow-1 d-flex flex-column justify-content-center`}>
        <div
          className={`${
            !(dueDate || props.trackerTypeValue === 'milestone')
              ? styles['centered-selection']
              : ''
          } w-100 d-flex flex-column align-items-center transition-transform px-3`}>
          <h1 className='text-center fs-3 fw-semibold lh-base mt-5 mb-1'>
            Select a{' '}
            {props.trackerTypeValue === 'report'
              ? 'report to upload'
              : 'milestone to confirm'}
          </h1>
          <TypeDropdown
            className={`d-flex flex-column flex-md-row mb-3 position-relative justify-content-center w-100 gap-3 ${styles['search-and-due-date']}`}
            id='comboBoxReportMenu'
            name='title'
            placeholder='Enter Title'
            setFocus={setReportFocus}
            setSearch={setReportSearch}
            search={reportSearch}
            list={reports}
            field='title'
            secondField='all_local_laws'
            handleClick={handleReportClick}
            clickOutside={handleReportClickOutside}
            onDeleteSelected={()=>{
              setDueDate('');
              setAttachment([]);
              setReportSelect(null);
            }}
            focused={reportFocus}
            hasShadow={true}
            numbered={true}
            isSelected={reportSelect !== null}
          />

          {reportSelect && reportSearch && props.trackerTypeValue === 'report' && (
            <div
              className={`d-flex align-items-center pb-4 ${
                styles['due-date-container']
              } ${dueDate ? styles['selected'] : ''}  gap-3`}>
              <div className={styles['step-number']}>2</div>
              <button
                className={`btn h-100 border-0 dropdown-toggle bg-white ${styles['due-date-btn']} ${styles['due-date-input']}`}
                type='button'
                id='dueDateDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                {dueDate ? (
                  <>
                    {format(new Date(dueDate), 'M/d/yyyy')}
                    {dueDateDict[dueDate].length === 0 ? (
                      <span className='badge rounded-pill text-bg-light ms-2'>
                        Empty
                      </span>
                    ) : (
                      <span className='badge rounded-pill text-bg-light ms-2'>
                        {dueDateDict[dueDate].length} file
                        {dueDateDict[dueDate].length > 1 ? 's' : ''}
                      </span>
                    )}
                  </>
                ) : (
                  'Due Date'
                )}
              </button>
              <ul
                className='dropdown-menu dropdown-menu-end'
                aria-labelledby='dueDateDropdown'>
                {getOrderedDates.map((date, index) => {
                  const fileCount = dueDateDict[date].length;
                  const isEmpty = fileCount === 0;
                  return (
                    <li key={index}>
                      <button
                        className='dropdown-item d-flex align-items-center justify-content-between'
                        type='button'
                        onClick={() => {
                          setDueDate(date);
                          setAttachment([]);
                        }}>
                        <span>{format(new Date(date), 'M/d/yyyy')}</span>
                        {isEmpty ? (
                          <span className='badge rounded-pill text-bg-light'>
                            Empty
                          </span>
                        ) : (
                          <span className='badge rounded-pill text-bg-light'>
                            {fileCount} file{fileCount > 1 ? 's' : ''}
                          </span>
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        {(dueDate ||
          (props.trackerTypeValue === 'milestone' && reportSelect)) && (
          <div className={`px-3 w-100${styles['animate-slide-up']}`}>
            {props.trackerTypeValue === 'report' && (
              <div
                className={`gap-3 d-flex flex-row mx-auto ${styles['report-form-wrapper']}`}
                style={{
                  maxWidth: '900px',
                }}>
                <div className={styles['milestone-step-number']}>3</div>
                <div className={`w-100 bg-white rounded-3 shadow-sm mb-3 p-4`}>
                  {dueDate && (
                    <>
                      <div className='mb-4'>
                        <h3 className='mb-3 fw-semibold'>Upload Report</h3>
                        <div className='alert alert-info d-flex align-items-center'>
                          <span className='me-2'>Due Date:</span>
                          <strong>
                            {format(new Date(dueDate), 'M/d/yyyy')}
                          </strong>
                        </div>
                      </div>

                      <div className='mb-4'>
                        <FileUploadArea setAttachment={setAttachment} />
                      </div>

                      {attachment.length > 0 && (
                        <div className='mb-4'>
                          <h4
                            className={`${styles['uploaded-files-heading']} mb-3`}>
                            Newly Uploaded Files
                          </h4>
                          <div className='files-container'>{attaches}</div>
                        </div>
                      )}

                      {/* Report Attachment */}
                      {dueDateDict[dueDate] &&
                        dueDateDict[dueDate].length > 0 && (
                          <>
                            {attachment.length > 0 && (
                              <hr className='my-4 text-body-tertiary' />
                            )}
                            <div className='mb-4'>
                              <h4
                                className={`${styles['uploaded-files-heading']} mb-3`}>
                                Previously Uploaded Files
                              </h4>
                              <div className='files-container'>
                                {dueDateDict[dueDate].map((file, idx) => (
                                  <div key={idx} className='card p-2 mb-3'>
                                    <div className='card-body d-flex flex-column flex-md-row align-items-center justify-content-between p-0 m-0 w-100'>
                                      <File
                                        file={file}
                                        id={`old-files-${idx}`}
                                        getFileAndDownload={() => {}}
                                        onDelete={onDeleteNewAttachment}
                                        deletePermission={
                                          props.deletePermission
                                        }
                                      />
                                      <div className='file-meta-info mt-2 mt-md-0'>
                                        {file.timestamp && (
                                          <p className='m-0 text-muted small'>
                                            {new Date(
                                              file.timestamp
                                            ).toLocaleString()}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            )}
            {props.trackerTypeValue === 'milestone' && (
              <div className={`gap-3 ${styles['milestones-form-wrapper']}`}>
                <div className={styles['milestone-step-number']}>2</div>
                <div className={`p-4 bg-white rounded-4 shadow-sm`}>
                  {reportSelect && (
                    <>
                      <div className='mb-4'>
                        <h3 className='mb-3 fw-semibold'>Milestone Details</h3>
                        <div className='alert alert-info align-items-center'>
                          <p>
                            <span className='me-2 text-uppercase'>Due Date:</span>
                            <strong>{ format( new Date(reportSelect.start_date), 'M/d/yyyy' ) }</strong>
                          </p>
                          {reportSelect.general_notes &&
                            <p className='m-0'>
                              <span className='me-2 text-uppercase'>General Notes:</span>
                              <strong>{ reportSelect.general_notes }</strong>
                            </p>
                          }
                        </div>
                      </div>

                      <div className='row g-4 mb-4'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='confirm-name' className='mb-2'>
                              Name <span style={{'color':'red'}}>*</span>
                            </label>
                            <input
                              id='confirm-name'
                              className={`form-control ${styles['adjust-input']}`}
                              type='text'
                              value={confirmName}
                              placeholder='Enter Name'
                              onChange={e => {
                                updateValidation(
                                  'name',
                                  confirmName,
                                  e.target.value
                                );
                                setConfirmName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='confirm-email' className='mb-2'>
                              Email <span style={{'color':'red'}}>*</span>
                            </label>
                            <input
                              id='confirm-email'
                              className={`form-control ${styles['adjust-input']}`}
                              type='email'
                              value={confirmEmail}
                              placeholder='Enter a contact email'
                              onChange={e => {
                                updateValidation(
                                  'email',
                                  confirmEmail,
                                  e.target.value
                                );
                                setConfirmEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='confirm-title' className='mb-2'>
                              Job Title <span style={{'color':'red'}}>*</span>
                            </label>
                            <input
                              id='confirm-title'
                              className={`form-control ${styles['adjust-input']}`}
                              type='text'
                              value={confirmTitle}
                              placeholder='Enter Job Title'
                              onChange={e => {
                                updateValidation(
                                  'title',
                                  confirmTitle,
                                  e.target.value
                                );
                                setConfirmTitle(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='confirm-agency' className='mb-2'>
                              Agency <span style={{'color':'red'}}>*</span>
                            </label>
                            <select name="confirm-agency" style={confirmAgency ? {} : {'color': '##595a5c'}} value={confirmAgency} onChange={(e)=>{
                              setConfirmAgency(e.target.value)
                              updateValidation('agency', confirmAgency, e.target.value);
                            }} className={`form-control ${styles['adjust-input']}`}>
                              <option value="" disabled selected>Enter Agency</option>
                              {reportSelect.submitting_agency.map((agency)=>{
                                return (<option value={agency.id}>{agency.name}</option>)
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='row g-4 mb-4'>
                        <div className='col-md-6'>
                          <div className='form-group position-relative'>
                            <label htmlFor='confirm-date' className='mb-2'>
                              Date Milestone is Completed <span style={{'color':'red'}}>*</span>
                            </label>
                            <div className='input-group'>
                              <span className='input-group-text bg-white border-end-0'>
                                <CalendarIcon width='20' height='20' />
                              </span>
                              <input
                                id='confirm-date'
                                className={`form-control  ${styles['adjust-input']} border-start-0 ps-0`}
                                type='date'
                                value={confirmDate}
                                onChange={e => {
                                  updateValidation(
                                    'date',
                                    confirmDate,
                                    e.target.value
                                  );
                                  setConfirmDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                        
                          <div className='form-group'>
                            <label htmlFor='confirm-upload' className='mb-2'>
                              Attachments
                            </label>
                            <MiniFileUpload
                              value={confirmUpload}
                              onChange={(e)=>{
                                let newUploadedMilestoneAttachment = [...confirmUpload];
                                let files = e.target.files;
                                let error_messages = []
                                for (let i = 0; i < files.length; i++) {
                                  // If the file size is more than 100MB do not input
                                  if (files[i].size > 100000000) {
                                    error_messages.push(
                                      files[i].name + ' is too large'
                                    );
                                  }
                                }

                                if (error_messages.length) {
                                  e.target.value = null;
                                  setFileLimitExceeded('confirmUpload')
                                  setFileErrorMessage(error_messages.join(', '))
                                } else {
                                  for (let i = 0; i < files.length; i++) {
                                    files[i].file_name = files[i].name;
                                    newUploadedMilestoneAttachment.push(files[i]);
                                  }
                                  setConfirmUpload(newUploadedMilestoneAttachment)
                                }
                              }}
                            />
                          </div>
                          
                        </div>
                        <div className='files-container'>

                          {/* Milestone attachments */}
                          { 
                            confirmUpload.map((file, idx) => {
                              return (
                                <div key={idx} className={`card p-2 mb-3 ${styles['new-file']}`}>
                                  <div className='card-body d-flex align-items-center justify-content-between p-0 m-0 w-100'>
                                    <File
                                      file={file}
                                      id={`milestone-attach-${idx}`}
                                      date={file.timestamp}
                                      getFileAndDownload={() => {}}
                                      onDelete={e=>onDeleteMilestoneAttachment(e,idx)}
                                      newUpload={true}
                                    />
                                    <TrashIcon
                                      classNameProp={`${styles['delete-icon']} ms-3`}
                                      onClickFunction={(e) => {
                                        onDeleteMilestoneAttachment(e,idx)
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })
                          }
                          { reportSelect.milestone_attachment.length > 0 &&
                            <div>
                              <h4
                                className={`${styles['uploaded-files-heading']} mb-3`}>
                                Previously Uploaded Files
                              </h4>
                              {
                                reportSelect.milestone_attachment.map((file, idx2) => {
                                  return (
                                      <div key={idx2} className='card p-2 mb-3'>
                                        <div className='card-body d-flex flex-column flex-md-row align-items-center justify-content-between p-0 m-0 w-100'>
                                          <File
                                            file={file}
                                            id={`milestone-attach-${idx2}`}
                                            date={file.timestamp}
                                            getFileAndDownload={() => {}}
                                            deletePermission={false}
                                          />
                                          <div className='file-meta-info mt-2 mt-md-0'>
                                            {file.timestamp && (
                                              <p className='m-0 text-muted small'>
                                                {new Date(
                                                  file.timestamp
                                                ).toLocaleString()}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                  );
                                })
                              }
                            </div>

                          }
                        </div>
                        { fileLimitExceeded === 'confirmUpload' &&
                          <div>
                            {fileErrorMessage}
                          </div>
                        }
                        <div className='col-12'>
                          <div className='form-group'>
                            <label htmlFor='confirm-notes' className='mb-2'>
                              Notes
                            </label>
                            <textarea
                              id='confirm-notes'
                              className='form-control'
                              rows='4'
                              placeholder='Enter Additional Information (optional)'
                              value={confirmNotes}
                              onChange={e => setConfirmNotes(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${styles['confirm-section']} p-4 rounded`}>
                        <div className={styles['confirm-checkbox']}>
                          <input
                            id='confirm-sign'
                            className='form-check-input me-3'
                            type='checkbox'
                            value={confirmSign}
                            onClick={e => {
                              updateValidation(
                                'sign',
                                confirmSign,
                                e.target.checked
                              );
                              setConfirmSign(e.target.checked);
                            }}
                          />
                          <label
                            htmlFor='confirm-sign'
                            className='form-check-label'>
                            <span style={{'color':'red'}}>*</span> By 
                            checking this box you hereby assert to the City
                            Council that your agency has completed all
                            components of the operational milestone selected
                            above.
                          </label>
                        </div>
                      </div>
                      <br/>
                    </>
                  )}
                  <Alert
                    color={message.includes('successfully') ? 'success' : 'danger'}
                    isOpen={message !== ''}>
                    {message}
                  </Alert>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        aria-hidden={!isSubmitted}
        aria-modal={isSubmitted}
        className={`modal fade ${props.isLoading ? 'show' : ''}`}
        role='dialog'
        style={{
          display: isSubmitted ? 'block' : 'none',
          backgroundColor: isSubmitted ? 'rgba(0,0,0,.4)' : 'transparent',
        }}
        tabIndex='-1'>
        <div className='modal-dialog modal-dialog-centered'>
          (<div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Please wait...</h5>
            </div>
            <div className='modal-body'>
              <p>
                We are processing your request. This page will refresh once it is successful.
              </p>
            </div>
          </div>)
        </div>
      </div>

      <div className='d-flex justify-content-center gap-3 pt-3 pb-5 mt-auto'>
        <button
          type='button'
          className={`${styles['cancel-btn']} btn btn-light text-primary border`}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            props.setTrackerTypeValue('');
          }}>
          Cancel
        </button>
        <button
          type='submit'
          className={`${styles['submit-btn']} btn btn-primary text-white`}
          disabled={
            props.trackerTypeValue === 'report'
              ? attachment.length === 0
              : validateConfirm.length > 0
          }>
          {props.trackerTypeValue === 'report'
            ? attachment.length > 0
              ? `Submit ${attachment.length} file${
                  attachment.length !== 1 ? 's' : ''
                }`
              : 'Submit'
            : 'Submit'
          }
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.reportTrackingReducer.isLoading,
    successMessage: state.reportTrackingReducer.agencySuccessMessage,
    deletePermission:
      state.userReducer.userProfile.permissions.reportTracking
        .delete_attachment,
    agencies: state.reportTrackingReducer.allAgencies,
    allReports: state.reportTrackingReducer.allReports,
  };
};

export default connect(mapStateToProps)(UploadReport);
