import { call, put, takeEvery } from 'redux-saga/effects';
import { url } from '../config';
import axios from 'axios';
import {
  setAuthorizationToken,
  delay,
  saveToken,
  getNextResults,
  logout,
} from '../utils/helper';
import {
  userActions,
  setUsername,
  setPassword,
  loading,
  checkingLoginStatus,
  checkingCreateNewUserStatus,
  gettingErrorMessage,
  gettingSuccessMessage,
  changingPassword,
  checkingSuperuser,
  savingUserInfo,
  saveAgencyUserInfo,
  saveAllAgencies,
  userList,
  storeCommittees,
  storeDivision,
  savePassDueReportsInfo,
  saveUpcomingReportsInfo,
  saveRecentlyUploadedReportsInfo,
  saveNewUserID,
  checkingChangePasswordStatus,
  addMoreReports,
  setReportLoading,
  loadEmail,
  saveNotifications,
  gettingTwoFactorAuthMessage,
  saveFaceSquare,
  savingTrainingLinks,
  addToastInfo,
} from '../actions/userAction';
import { searchReports } from '../actions/reportTrackingAction';
import {defaultLSRDocumentTypes, defaultLSRFieldTypes} from '../components/ls-requests/LsAdvancedSearch'

// HTTP.POST request for user login
// The data should be in data-form
function userLoginRequest(username, password, twoFactorToken, otp) {
  return axios.post(url + '/api/users/login/', {
    username,
    password,
    twoFactorToken,
    otp,
  });
}

// HTTP.POST request for user profile information
// Should use authentication token
function gettingUserProfile() {
  return axios.get(url + '/api/users/dashboard/');
}

function getAgencyUser() {
  return axios.get(url + '/api/reports/agencyUser/0/');
}

// HTTP.GET for getting all agencies
function getAllAgenciesRequest() {
  return axios.get(url + '/api/reports/agencies/');
}

// HTTP.GET request for user list
// Should use authentication token
function gettingUserList() {
  return axios.get(url + '/api/users/info/');
}

// HTTP.POST request for creating a new user
// The data should be an object
//user obj should contain: username, first_name, last_name, email, password, phone, role, committee, permission
function creatingNewUserRequest(user) {
  return axios.post(url + '/api/users/new/', user);
}

function createAgencyUser(user) {
  return axios.post(url + '/api/reports/agencyUser/', user);
}

// HTTP.PUT request for updating a user
// The user object can be made of of any: username, first_name, last_name, email, phone, role, committee, permission
// The user object SHOULD NOT contain the password. Check with Johnathan
function updateUserRequest(id, user) {
  return axios.put(`${url}/api/users/update/${id}/`, user);
}

// HTTP.PATCH request for chaning the password
// Expecting an id and password
function changePasswordRequest(id, old_password, new_password) {
  return axios.patch(`${url}/api/users/update/${id}/`, {
    old_password: old_password,
    new_password: new_password,
  });
}

function changePasswordByTokenRequest(token, password) {
  return axios.post(url + '/api/users/changePasswordByToken/', {
    token: token,
    password: password,
  });
}

// HTTP.GET request
function getCommitteesRequest() {
  return axios.get(url + '/api/users/committees/');
}

function getDivisionRequest() {
  return axios.get(url + '/api/users/division/');
}

// HTTP.POST
function emailPasswordTokenRequest(username) {
  return axios.post(url + '/api/users/emailPasswordReset/', {
    username: username,
  });
}

// HTTP.GET for a list of reports passed due under user committee(s)
// Should use authentication token
function reportsDashboardRequest(committees) {
  return axios.get(
    `${url}/api/reports/reportsDashboard/${
      committees ? '?committee=' + committees : ''
    }`
  );
}

async function retrieveNotifications() {
  return await axios.get(url + '/api/notification/notifications/');
}

function retrieveFaceData(photo) {
  return axios.post(url + '/api/users/facialData/', photo);
}

function saveProfilePic(photo) {
  return axios.post(url + '/api/users/uploadProfilePic/', photo);
}

function updateUserPreferences(id, pref) {
  return axios.put(`${url}/api/users/userPreferences/${id}/`, pref);
}

//HTTP.PATCH for changing the time for users.
//The json needs to follow this format: [{user: id, time_availbility: num},...]
// function changeTimeRequest(json){
//   return axios.patch(url+'/api/users/updateTime/',json);
// }

// Deal with user login side effect
export function* userLogin(action) {
  if (action !== undefined) {
    try {
      yield put(loading(true));
      action.username = action.username.toLowerCase();
      let localStorageToken,
        nyccEmailIndex,
        response,
        tokens = {};
      for (let i of [
        '@council.nyc.gov',
        '@pubadvocate.nyc.gov',
        '@advocate.nyc.gov',
      ]) {
        if (action.username.indexOf(i) > -1) {
          nyccEmailIndex = action.username.indexOf(i);
          action.username = action.username.slice(0, nyccEmailIndex);
          break;
        }
      }

      // Remove the old twoFactorToken localStorage and use the new twoFactorTokens object to store multiple tokens
      // If the browser has the old structure of the twoFactorToken string, re-save it as a stringified object
      if (localStorage.getItem('twoFactorToken')) {
        localStorageToken = localStorage.getItem('twoFactorToken');
        tokens[action['username']] = localStorageToken;
        localStorage.setItem('twoFactorTokens', JSON.stringify(tokens));
        localStorage.removeItem('twoFactorToken');
      } else if (localStorage.getItem('twoFactorTokens')) {
        // Retrieve the token that belongs to the user trying to log in, JSON parse will throw an error if localStorage is non-parsable string, but NULL if localStorage item doesn't exist
        tokens = JSON.parse(localStorage.getItem('twoFactorTokens'));
        localStorageToken = tokens[action.username];
      } else {
        // For new users, someone logging in for the first time on new machine, or if someone happened to clear their localStorage
        localStorage.setItem('twoFactorTokens', '{}');
      }

      // Apply the token, otp, or neither to the login request
      if (localStorageToken && action.otp) {
        response = yield call(
          userLoginRequest,
          action.username,
          action.password,
          localStorageToken,
          action.otp
        );
      } else if (localStorageToken) {
        response = yield call(
          userLoginRequest,
          action.username,
          action.password,
          localStorageToken
        );
      } else if (action.otp) {
        response = yield call(
          userLoginRequest,
          action.username,
          action.password,
          null,
          action.otp
        );
      } else {
        response = yield call(
          userLoginRequest,
          action.username,
          action.password
        );
      }

      // Save the authenticated token to local storage
      const token = response.data.token;
      const userType = response.data.user_type;
      let user;
      saveToken(token, userType);
      // Set Authorization token in the header
      setAuthorizationToken(token);
      localStorage.setItem('user', action.remember ? action.username : '');
      localStorage.removeItem('doc-search-fields');
      localStorage.removeItem('lsr-search-fields');
      
      if (response.data.two_factor_auth_status !== 'success') {
        // Failed login
        yield put(loading(false));
        yield put(setUsername(action.username));
        yield put(setPassword(action.password));
        yield put(gettingTwoFactorAuthMessage(response.data));
      } else if (userType === 'agencyuser') {
        // Successful agency login
        // Get the user profile
        const response1 = yield call(getAgencyUser);
        user = response1.data;
        // Save the user profile to redux store
        yield put(saveAgencyUserInfo(user));
        yield put(savingUserInfo(user.userprofile));
        getAllAgencyResponse()
        yield put(searchReports(
          'title',                      // sortBy
          '',                           // searchCommittee
          '',                           // searchTitle
          '',                           // searchDivision
          `${user.agency}`,             // searchAgency
          '',                           // searchLaw
          '',                           // searchAll
          {                             // filters
            displayReports: true,
            displayMilestones: true,
            completeFilter: true,
            receivedFilter: true,
            upcomingFilter: true,
            pastDueFilter: true,
            fromDate: '',
            toDate: '',
          },
          '',                           // tasks
          false));                      // paginated
        yield call(delay, 0);
        tokens = JSON.parse(localStorage.getItem('twoFactorTokens'));
        tokens[user.username] = response.data.twoFactorToken;
        localStorage.setItem('twoFactorTokens', JSON.stringify(tokens));
        yield put(checkingLoginStatus('agency-success'));
        yield put(loading(false));
      } else {
        // Successful non-agency login
        const response1 = yield call(gettingUserProfile);
        user = response1.data.user;
        yield put(savingTrainingLinks(response1.data.videos));
        // Check whether current user is the superuser
        yield put(checkingSuperuser(user.is_superuser));
        // Save the user profile to redux store
        yield put(savingUserInfo(user));
        const userListResponse = yield call(gettingUserList);
        yield put(userList(userListResponse.data.users));
        const committeesResponse = yield call(getCommitteesRequest);
        yield put(storeCommittees(committeesResponse.data.committees));
        const divisionResponse = yield call(getDivisionRequest);
        yield put(storeDivision(divisionResponse.data.division));
        // Get all passed due reports
        // Get the committees for a user
        let committeeList = user.committees.map(c => c.id).join(',');
        if (user.permissions.reportTracking.view_report) {
          const reportsResponse = yield call(
            reportsDashboardRequest,
            committeeList
          );
          yield put(savePassDueReportsInfo(reportsResponse.data.data.past_due)); //This is now pageinated, will need to get the rest of the results
          yield put(
            saveUpcomingReportsInfo(reportsResponse.data.data.upcoming)
          );
          yield put(
            saveRecentlyUploadedReportsInfo(reportsResponse.data.data.recent)
          );
        }
        yield call(delay, 0);
        if (action.location !== '/') {
          window.location.href = action.location;
        }
        tokens = JSON.parse(localStorage.getItem('twoFactorTokens'));
        tokens[user.username] = response.data.twoFactorToken;
        localStorage.setItem('twoFactorTokens', JSON.stringify(tokens));
        yield put(setUsername(''));
        yield put(setPassword(''));
        yield put(checkingLoginStatus('success'));
        yield put(loading(false));
      }
      yield put(gettingErrorMessage(''));
    } catch (e) {
      console.log(e.message);
      yield put(checkingLoginStatus('fail'));
      yield put(loading(false));
      yield put(
        gettingErrorMessage(e.response.data.non_field_errors.join('\n'))
      );
    }
  }
}

// Get the due reports and save them in the redux store
function* getAllAgencyResponse(action) {
  if (action !== undefined) {
    try {
      //yield put(isLoadingStatus(true));
      // Get the reponse of all the agencies]
      const response1 = yield call(getAllAgenciesRequest);
      yield put(saveAllAgencies(response1.data));
    } catch (e) {
      console.log(e);
    }
  }
}

// Deal with new user creation side effect
function* userSignup(action) {
  if (action !== undefined) {
    try {
      const response = yield call(creatingNewUserRequest, action.user);
      yield put(saveNewUserID(response.data.id));
      yield put(checkingCreateNewUserStatus('success'));
      // addToastInfo(
      //   'User Created',
      //   `User ${action.user.username} has been created`,
      //   '/img/Navbar/user-icon.png',
      //   'Profile icon'
      // );
      window.location.assign(`/staff/${response.data.id}`);
    } catch (e) {
      yield put(checkingCreateNewUserStatus('fail'));
      yield put(gettingErrorMessage(e.response.data));
    }
  }
}

function* agencyUserSignup(action) {
  if (action !== undefined) {
    try {
      const response = yield call(createAgencyUser, action.user);
      yield put(saveNewUserID(response.data.id));
      yield put(checkingCreateNewUserStatus('success'));
      window.location.assign(`/staff/${response.data.id}`);
    } catch (e) {
      yield put(checkingCreateNewUserStatus('fail'));
      yield put(gettingErrorMessage(e.response.data));
    }
  }
}

function* updateUser(action) {
  if (action !== undefined) {
    try {
      const response = yield call(updateUserRequest, action.id, action.user);
      yield put(checkingCreateNewUserStatus('success'));
      yield call(delay);
      // addToastInfo(
      //   'User updated',
      //   `User ${action.user.username} has been updated`,
      //   '/img/Navbar/user-icon.png',
      //   'Profile icon'
      // );

      if (action.isOwnProfile) {
        window.location.reload();
      } else {
        window.location.assign(`/staff/${response.data.id}`);
      }
    } catch (e) {
      yield put(checkingCreateNewUserStatus('fail'));
      yield put(gettingErrorMessage(e.response.data));
    }
  }
}

// Send get user profile request using the existing token
// Check whether the current token is valid or not
// If not valid, the end user is required to login
// If valid, no need to put username and password again
function* userLoginByToken(action) {
  if (action !== undefined) {
    try {
      yield put(loading(true));
      setAuthorizationToken(action.token);
      let user;
      if (action.userType === 'agencyuser') {
        const response = yield call(getAgencyUser);
        user = response.data;
        // Save the user profile to redux store
        yield put(saveAgencyUserInfo(user));
        yield put(savingUserInfo(user.userprofile));
        yield put(searchReports(
          'title',                    // sortBy
          '',                         // searchCommittee 
          '',                         // searchTitle 
          '',                         // searchDivision 
          `${user.agency}`,           // searchAgency 
          '',                         // searchLaw 
          '',                         // searchAll 
          {                           // filter 
            displayReports: true,
            displayMilestones: true,
            completeFilter: true,
            receivedFilter: true,
            upcomingFilter: true,
            pastDueFilter: true,
            fromDate: '',
            toDate: ''
          },
          '',                         // tasks 
          false));                    // paginated 
        yield call(delay, 0);
        yield put(checkingLoginStatus('agency-success'));
      } else {
        const response = yield call(gettingUserProfile);
        user = response.data.user;
        yield put(savingTrainingLinks(response.data.videos));
        yield put(checkingSuperuser(user.is_superuser));
        // Save the user profile to redux store
        yield put(savingUserInfo(user));
        const userListResponse = yield call(gettingUserList);
        yield put(userList(userListResponse.data.users));
        const committeesResponse = yield call(getCommitteesRequest);
        yield put(storeCommittees(committeesResponse.data.committees));
        const divisionResponse = yield call(getDivisionRequest);
        yield put(storeDivision(divisionResponse.data.division));
        // Get all passed due reports (dashboard only)
        let committeeList = user.committees.map(c => c.id).join(',');
        const locationHRef = window.location.href;
        if (
          locationHRef[locationHRef.length - 1] === '/' &&
          user.permissions.reportTracking.view_report
        ) {
          const reportsResponse = yield call(
            reportsDashboardRequest,
            committeeList
          );
          yield put(savePassDueReportsInfo(reportsResponse.data.data.past_due)); //This is now pageinated, will need to get the rest of the results
          yield put(
            saveUpcomingReportsInfo(reportsResponse.data.data.upcoming)
          );
          yield put(
            saveRecentlyUploadedReportsInfo(reportsResponse.data.data.recent)
          );
        }
        yield put(checkingLoginStatus('success'));
      }
      // This is the current dirty hack I am using to get around the page refresh getting rid of the toast info from store.
      // If page will not refresh, add toast to store.
      // If page will refresh, add toast to localStorage and,
      // upon refresh, add to store and remove from localStorage (below)

      const toast = localStorage.getItem('toastInfo');
      if (toast) {
        yield put(addToastInfo(JSON.parse(toast)));
        localStorage.removeItem('toastInfo');
      }
      yield put(loading(false));
    } catch (e) {
      yield put(checkingLoginStatus('fail'));
      yield put(loading(false));
      yield call(logout);
      yield put(
        gettingErrorMessage('Given credentials are invalid or expired')
      );
    }
  }
}
// User logout
// Remove the token authentication information in the local storage
function* userLogout(action) {
  if (action !== undefined) {
    try {
      // Remove the token
      yield call(logout);
    } catch (e) {
      console.log(e);
    }
  }
}

function* userChangePassword(action) {
  if (action !== undefined) {
    try {
      yield put(changingPassword(true));
      yield call(
        changePasswordRequest,
        action.id,
        action.currentPassword,
        action.newPassword
      );
      yield put(checkingChangePasswordStatus('success'));
      yield put(gettingSuccessMessage('Password successfully changed!'));

      // Delay for displaying the message
      // yield call(delay, 5);
      // Require the use to login again using new password
      // Remove the token
      // yield call(logout);
    } catch (e) {
      yield put(checkingChangePasswordStatus('fail'));
      yield put(gettingErrorMessage(e.response.data));
    }
    yield put(changingPassword(false));
  }
}

function* changePasswordByToken(action) {
  if (action !== undefined) {
    try {
      yield put(changingPassword(true));
      const response = yield call(
        changePasswordByTokenRequest,
        action.token,
        action.password
      );
      // addToastInfo(
      //   'Password Changed',
      //   `Your password has been changed`,
      //   '/img/Navbar/user-icon.png',
      //   'Profile icon'
      // );
      yield put(gettingSuccessMessage(response.data));
      yield call(delay);
      window.location.assign('/');
    } catch (e) {
      yield put(gettingErrorMessage(e.response.data['errors']));
    }
    yield put(changingPassword(false));
  }
}

function* emailPasswordToken(action) {
  if (action !== undefined) {
    try {
      yield put(loadEmail(true));
      action.username = action.username.toLowerCase();
      const nyccEmailIndex = action.username.indexOf('@council.nyc.gov');
      if (nyccEmailIndex > -1) {
        action.username = action.username.slice(0, nyccEmailIndex);
      }
      const response = yield call(emailPasswordTokenRequest, action.username);
      yield put(loadEmail(false));
      yield put(gettingSuccessMessage(response.data));
    } catch (e) {
      console.log(e.response.data);
      yield put(loadEmail(false));
      yield put(gettingErrorMessage('Failed to set the password'));
    }
  }
}

function* userLoadMoreReports(action) {
  if (action !== undefined) {
    yield put(setReportLoading(true));
    const response = yield call(getNextResults, action.url);
    yield put(addMoreReports(response.data.results, response.data.next));
    yield put(setReportLoading(false));
  }
}

function* getNotifications(action) {
  if (action !== undefined) {
    const response = yield call(retrieveNotifications);
    yield put(saveNotifications(response.data));
  }
}

function* updateUserPreferencesSaga(action) {
  try {
    const response = yield call(
      updateUserPreferences,
      action.id,
      action.userPreferences
    );
    yield action.setToastText(response.data.detail);
    yield action.setToastVisible(true);
  } catch (error) {
    console.log(error);
    yield action.setToastText('An error has occured');
    yield action.setToastVisible(true);
  }
}

function* sendFaceDataRequest(action) {
  if (action !== undefined) {
    const formData = new FormData();
    formData.append('profile_pic', action.photo);
    const response = yield call(retrieveFaceData, formData);
    yield put(saveFaceSquare(response.data));
  }
}

function* sendProfilePic(action) {
  if (action !== undefined) {
    const formData = new FormData();
    const filename = `${action.username}-profile-pic.jpg`;
    formData.append('profile_pic', action.pic, filename);
    formData.append('selected_square', action.square);
    const response = yield call(saveProfilePic, formData);
    yield put(saveFaceSquare(response.data));
  }
}

function* userAPI() {
  yield takeEvery(userActions.LOGIN, userLogin);
  yield takeEvery(userActions.CREATING_NEW_USER, userSignup);
  yield takeEvery(userActions.CREATING_AGENCY_USER, agencyUserSignup);
  yield takeEvery(userActions.UPDATE_USER, updateUser);
  yield takeEvery(
    userActions.UPDATE_USER_PREFERENCES,
    updateUserPreferencesSaga
  );
  yield takeEvery(userActions.LOGGING_IN_USING_TOKEN, userLoginByToken);
  yield takeEvery(userActions.LOGGING_OUT, userLogout);
  yield takeEvery(userActions.GET_ALL_AGENCIES, getAllAgencyResponse);
  yield takeEvery(userActions.CHANGE_PASSWORD, userChangePassword);
  yield takeEvery(userActions.EMAIL_PASSWORD_RESET, emailPasswordToken);
  yield takeEvery(userActions.CHANGE_PASSWORD_BY_TOKEN, changePasswordByToken);
  yield takeEvery(userActions.LOAD_MORE_USER_REPORTS, userLoadMoreReports);
  yield takeEvery(userActions.GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(userActions.FIND_FACE_FROM_PHOTO, sendFaceDataRequest);
  yield takeEvery(userActions.SAVE_PROFILE_PIC, sendProfilePic);
}

export default userAPI;
