import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../../../styles/user/UserProfile.module.scss';
import PasswordForm from '../../PasswordForm';
import { EditPageIcon, CameraCaptureIcon } from '../../../../services/SvgLibrary';

const UserProfileWidget = props => {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const {
    userProfile,
  } = props;

  const {
    alternative_phone,
    email,
    full_name,
    id,
    phone,
    role,
    username,
  } = userProfile;

  function togglePasswordForm() {
    setShowPasswordForm(!showPasswordForm);
  }

  return (
    <>
      <div className='card border-0 rounded-3'>
        {userProfile.permissions.auth.change_user && (
          <button
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#editUserProfile'
            className={styles['edit-profile-button']}>
            <EditPageIcon /> Edit my profile
          </button>
        )}
        <img
          src='/img/my-profile-cover-image.jpg'
          className='card-img-top'
          style={{ height: '100px', width: '100%', objectFit: 'cover' }}
          aria-hidden='true'
          alt='Placeholder user profile banner'
        />
        <h1 className={styles['users-name']}>{full_name}</h1>
        <div className={styles['profile-container']}>
          <div
            data-bs-toggle='modal'
            data-bs-target='#edit-profile-picture'
            className={styles['user-profile-picture']}
            style={{
              backgroundImage: `url(${
                userProfile.profile_pic_icon
                  ? userProfile.profile_pic_icon.file
                  : '/img/profile-image-placeholder.png'
              })`,
            }}
            alt='User profile icon'>
            <CameraCaptureIcon classNameProp={styles['camera-icon']} />
          </div>
        </div>
        <div className='card-body overflow-auto'>
          <table className={styles['user-profile-left-details']}>
            <tbody>
              <tr>
                <td>Username</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td> {role}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td> {email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td> {phone !== '' ? phone : 'N/A'}</td>
              </tr>
              <tr>
                <td>Alternative Phone</td>
                <td>
                  {alternative_phone !== '' ? alternative_phone : 'N/A'}
                </td>
              </tr>
              {userProfile.desk_number && (
                <tr>
                  <td>Desk Number</td>
                  <td>{userProfile.desk_number}</td>
                </tr>
              )}
              {parseInt(userProfile.id, 10) === parseInt(id, 10) &&
                !showPasswordForm && (
                  <tr>
                    <td colSpan='2'>
                      <button
                        onClick={togglePasswordForm}
                        className={`${styles['change-password-link']} submit-button`}>
                        Change Password
                      </button>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
      {showPasswordForm && (
        <PasswordForm
          needCurrentPassword={true}
          closePasswordForm={togglePasswordForm}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(UserProfileWidget);