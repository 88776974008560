import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  clearLsRequestArray,
  clickedLsRequest,
  fetchOneLsr,
  loadLsRequests,
  loadLsRequestStatusApprovalList,
  openOrCloseLsRequestServicePopup,
} from '../../actions/lsRequestAction';
import { LsFields, allFileFields } from '../../services/LsFields';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import LsRequestServicesPopup from './LsRequestServicesPopup';
import NewLsMenu from './NewLsMenu';
import LsRequestsTable from './LsRequestsTable';
import Sidebar from 'react-sidebar';
import LsInfoDisplay from './LsInfoDisplay';
import { adminRoles, nonLegDivisionRoles } from '../../services/constants';
import { useParams } from 'react-router-dom';
import { lsrFieldsToIdx, docFieldsToIdx } from '../../services/constants'
import { getFilteredDefaultLSRDocumentTypes, getFilteredDefaultLSRFieldTypes } from '../../utils/helper';

const defaultColumns = [
  'LSR #',
  'Sponsor',
  'LSR Type',
  'Describe The Problem',
  'Inspiration For LSR',
  'Legislative Solution',
  'Status',
  window.location.href.includes('myActiveLs') && 'Last Activated',
].join(',');
const orderedFields = [...LsFields]
  .filter(col => !['new_status', 'new_status_requestor'].includes(col.field))
  .sort((a, b) => (a.table_order > b.table_order ? 1 : -1));
const make_first_prime_public_index = orderedFields.findIndex(
  item => item.field === 'make_first_prime_public'
);
orderedFields[make_first_prime_public_index].title = 'Waived Confidentiality';

const LsRequestsList = props => {
  /** Column States */
  const [columnPreset, setColumnPreset] = useState(
    localStorage.getItem('active-table-column') ||
      `column-${props.userProfile.username}`
  );
  const [optionsChanged, setOptionsChanged] = useState(false);
  localStorage.setItem('active-table-column', columnPreset);

  const allColumns = nonLegDivisionRoles.includes(props.userProfile.role)
    ? orderedFields.filter(field => field.viewability !== 'staffOnly')
    : orderedFields;

  let savedColumns = localStorage.getItem(columnPreset) || defaultColumns;
  const presetColumns = allColumns.map(column => {
    if (
      savedColumns.includes(column.title) ||
      (column.title === 'Last Activated' &&
        window.location.href.includes('myActiveLs'))
    ) {
      return { ...column, show: true };
    } else {
      return { ...column, show: false };
    }
  });

  const [columns, setColumns] = useState(presetColumns);
  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const checkAllColumns = columns.every(col => col.show);

  const { ls_num } = useParams();

  let columnsToSave = columns.reduce((acc, column) => {
    if (column.show) {
      acc.push(column.title);
    }
    return acc;
  }, []);
  localStorage.setItem(columnPreset, columnsToSave.join(','));

  /** Filter States */
  const defaultFilterValues = {
    'committee': { check: false, value: [], locked: false },
    'submitted_by': { check: false, value: [], locked: false },
    'division': { check: false, value: [], locked: false },
    'first_prime': {
      check: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
      value: nonLegDivisionRoles.includes(props.userProfile.role)
        ? props.userProfile.under_council_member
          ? [props.userProfile.under_council_member.toString()]
          : [props.userProfile.id.toString()]
        : [],
      locked: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
    },
    'first_in_time': { check: false, value: [], locked: false },
    'last_activated': {
      check: window.location.pathname.toLowerCase().includes('myactivels')
        ? true
        : false,
      value: window.location.pathname.toLowerCase().includes('myactivels')
        ? 'all'
        : '',
      locked: false,
    },
    'ls_number': { check: false, value: '', locked: false },
    'ls_type': { check: false, value: [], locked: false },
    'staff': {
      check:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
      value:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? [props.userProfile.id.toString()]
          : [],
      locked:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
    },
    'status': { check: false, value: [], locked: false },
  };

  

  let searchParams = new URLSearchParams(window.location.search);

  const [filterPreset, setFilterPreset] = useState(
    searchParams.has('lsr_batch')
      ? 'filter-lsr_batch'
      : localStorage.getItem('active-table-filter') ||
          `filter-${props.userProfile.username}`
  );

  let savedFilterValues =
    JSON.parse(localStorage.getItem(filterPreset)) || defaultFilterValues;

  savedFilterValues['last_activated'].check =
    window.location.pathname.includes('myActiveLs');
  savedFilterValues['last_activated'].value = 'all';

  if (filterPreset === 'filter-lsr_batch') {
    savedFilterValues['ls_number'].check = true;
    savedFilterValues['ls_number'].value = searchParams.get('lsr_batch');
  }

  const [filterValues, setFilterValues] = useState(savedFilterValues);

  if (filterPreset !== 'filter-lsr_batch') {
    localStorage.setItem('active-table-filter', filterPreset);
  }
  localStorage.setItem(filterPreset, JSON.stringify(filterValues));

  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState(searchInput);
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  // A flag for the filter button to be conditionally rendered depends on the parent component
  const [filterFlag, setFilterFlag] = useState(true);

  /** Table States */
  const [filtered, setFiltered] = useState({ bool: false, url: '' });
  const [sortedBy, setSortedBy] = useState('-ls_number');

  /** Other States */
  const [curtPopup, setCurtPopup] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [statusChangeRequestCounter, setStatusChangeRequestCounter] =
    useState(0);
  /** useEffect Props */
  const { match, fetchOneLsr } = props;

  function applyFilters(
    search = searchInput,
    filters = filterValues,
    sort = sortedBy,
  ) {
    let combination = {};
    if (search) combination['keyword'] = search;

    if (!localStorage.getItem('lsr-search-fields')) {
      localStorage.setItem('lsr-search-fields', JSON.stringify({...getFilteredDefaultLSRFieldTypes(props.userProfile.role, LsFields)}))
    }
    
    if (!localStorage.getItem('doc-search-fields')) {
      localStorage.setItem('doc-search-fields', JSON.stringify({...getFilteredDefaultLSRDocumentTypes(props.userProfile.role, LsFields)}))
    }

    const storedLsrFilters = localStorage.getItem('lsr-search-fields') 
      ? JSON.parse(localStorage.getItem('lsr-search-fields'))
      : {...defaultLSRFieldTypes}
    const storedDocFilters = localStorage.getItem('doc-search-fields')
      ? JSON.parse(localStorage.getItem('doc-search-fields'))
      : {...defaultLSRDocumentTypes}


    // Get list of fields that should be included in search by index
    const lsrFields = Object.keys(storedLsrFilters).filter(k => k !== "Check All" && storedLsrFilters[k]).map(f => lsrFieldsToIdx[f])
    const docFields = Object.keys(storedDocFilters).filter(k => k !== "Check All" && storedDocFilters[k]).map(f => docFieldsToIdx[f])

    // Make array of 0s of length equal to number of fields
    let lsrBoolString = new Array(LsFields.length - allFileFields.length - 1).fill(0)
    let docBoolString = new Array(allFileFields.length - 1).fill(0)

    // Convert those included in search to 1s
    lsrFields.forEach(ele => lsrBoolString[ele] = 1)
    docFields.forEach(ele => docBoolString[ele] = 1)

    if (lsrBoolString.every(ele => ele === 0) && docBoolString.every(ele => ele === 0) && search !== ''){
      setSearch('')
      alert('You must select at least one field to search')
    }
    else {
      // Add result to filters as a string
      filters.lsrFields = {check: true, value: lsrBoolString.join(''), locked: false}
      filters.docFields = {check: true, value: docBoolString.join(''), locked: false}
      filters.isLMSearch = {check: true, value: props.searchLMs, locked: false}
      
      for (let key in filters) {
        if (filters[key].check) {
          combination[key] = filters[key].value;
        }
      }

      if (window.location.href.includes('myActiveLs')) {
        combination['table'] = 'myactivels';
      } else if (window.location.href.includes('allMyLs')) {
        combination['table'] = 'all';
      }

      const filtered = {
        bool: Object.values(combination).length > 0,
        url: encodeURI(JSON.stringify(combination)),
      };

      if (props.lsRequests.length > 0) {
        props.clearLsRequestArray();
      }

      props.loadLsRequests(null, sort, filtered);
      setFiltered(filtered);
     }
    
  }

  const applyFiltersOnLoad = useCallback(applyFilters, []);
  const loadApprovalOnLoad = useCallback(
    props.loadLsRequestStatusApprovalList,
    []
  );
  /** Fired on component mount */
  useEffect(() => {
    setStatusChangeRequestCounter(props.lsRequestStatusApprovals.length);
  }, [props.lsRequestStatusApprovals.length]);

  useEffect(
    () => {
      applyFiltersOnLoad();
      if (props.userProfile.permissions.lsRequest_api.approve_status_change) {
        loadApprovalOnLoad();
      }
      try {
        if (ls_num) fetchOneLsr(ls_num, () => setSidebarOpen(true));
      } catch (e) {
        console.log(e);
      }

      if (!filterFlag) {
        setFilterFlag(true);
      }
    },
    ls_num
      ? [ls_num, fetchOneLsr, applyFiltersOnLoad]
      : [fetchOneLsr, applyFiltersOnLoad]
  );

  /**
   * COLUMN HANDLING FUNCTIONS
   * * toggleColumn
   *   toggles a column to be shown or hidden
   * * setDefaultColumns
   *   reset selected columns to default columns
   * * changeColumnPreset
   *   change user selected column preset and select saved columns
   */
  function toggleColumn(title) {
    setColumns(cols =>
      cols.map(col => (col.title === title ? { ...col, show: !col.show } : col))
    );
  }

  function setDefaultColumns() {
    let columnView = allColumns.map(column => {
      if (defaultColumns.includes(column.title)) {
        return { ...column, show: true };
      } else {
        return { ...column, show: false };
      }
    });
    setColumns(columnView);
  }

  function changeColumnPreset(columnPresetOption) {
    localStorage.setItem('active-table-column', columnPresetOption);
    // If column preset already exists, set columns to preset values or default.
    if (localStorage.getItem(columnPresetOption)) {
      let savedColumns = localStorage.getItem(columnPresetOption).split(',');
      setColumns(cols =>
        cols.map(col => {
          col.show = savedColumns.includes(col.title);
          return col;
        })
      );
    } else {
      setDefaultColumns();
    }
    setColumnPreset(columnPresetOption);
  }

  // Check or uncheck all the checkbox
  function toggleAllColumns() {
    // If not already all checked, check all
    if (!checkAllColumns) {
      setColumns(cols => cols.map(col => ({ ...col, show: true })));
    } else {
      // otherwise, set to default
      setDefaultColumns();
    }
  }
  /**
   * * END COLUMN HANDLING FUNCTIONS
   */

  /** FILTER HANDLING FUNCTIONS
   * * onClearSearch : clear search
   * * onClearFilter : clear filters
   * * onGetFilterResult : call api to load filter results
   * * changeFilterPreset : change filter preset
   */
  // On clear the filter fields
  function onClearSearch() {
    setSearchInput('');
    applyFilters('', filterValues, sortedBy);
  }

  function onClearFilter() {
    setDefaultFilters();
    applyFilters(searchInput, defaultFilterValues, sortedBy);
  }

  function setDefaultFilters() {
    setFilterValues({ ...defaultFilterValues });
  }

  function changeFilterPreset(filterPresetOption) {
    localStorage.setItem('active-table-filter', filterPresetOption);
    // If filter preset already exists, get saved values
    if (localStorage.getItem(filterPresetOption)) {
      let savedFilters = JSON.parse(localStorage.getItem(filterPresetOption));
      setFilterValues(savedFilters);
    } else {
      setDefaultFilters();
    }
    setFilterPreset(filterPresetOption);
  }

  /**
   * onAddFilter
   * @param field: which field to add filter to
   * @param filter: value to be added to field value
   *
   * If the field is an LSR number, check if the number already exists first.
   *   If it doesn't exist and is not empty, add to field
   *
   * Otherwise, for other fields, check if the value already exists.
   *   If it doesn't exist, add filter
   *
   * If no filters previously existed for the field, activate check flag.
   * Calls state setter for filter values with updated filter values.
   */
  function onAddFilter(field, filter) {
    let values = { ...filterValues };
    if (field === 'ls_number' && filter.length > 0) {
      // prevent duplicates
      let ls_nums_to_add = filter.split(',');
      let current_ls_nums =
        values[field].value.length > 0 ? values[field].value.split(',') : [];
      ls_nums_to_add.forEach(ls_num => {
        if (!current_ls_nums.includes(ls_num) && ls_num.length > 0) {
          current_ls_nums.push(ls_num);
        }
      });
      values[field].value = current_ls_nums.join(',');
      if (!values[field].check) values[field].check = true;
    } else {
      if (!values[field].value.includes(filter)) {
        values[field].value.push(filter);
        if (!values[field].check) values[field].check = true;
      }
    }
    setFilterValues(values);
  }

  /**
   * onRemoveFilter
   * @param field: field to remove filter from
   * @param filter: filter to be removed from ls field
   *
   * If field is number, convert value from string to list and remove the filter.
   * For other fields, check if the filter exists and remove from list.
   *
   * If there are no more filters after removing, deactivate check flag.
   */
  function onRemoveFilter(field, filter) {
    let values = { ...filterValues };
    if (field === 'ls_number') {
      let ls_nums = values[field].value.split(','); // convert field to list
      values[field].value = ls_nums.filter(val => val !== filter).join(','); //remove filter and rejoin into string
      if (values[field].value.length === 0 && values[field].check)
        values[field].check = false;
    } else {
      if (values[field].value.includes(filter)) {
        values[field].value = values[field].value.filter(val => val !== filter);
        if (values[field].value.length === 0 && values[field].check)
          values[field].check = false;
      }
    }
    setFilterValues(values);
  }

  const buttonFilterToggle = (e, days) => {
    const dayButtons = document.getElementsByClassName(
      styles['day-toggle-buttons']
    );
    for (let i = 0; i < dayButtons.length; i++) {
      dayButtons[i].classList.remove('focus');
    }
    e.target.classList.add('focus');
    let values = { ...filterValues };
    values['last_activated'].check = true;
    values['last_activated'].value = days;
    setFilterValues(values);

    applyFilters(searchInput, values, sortedBy);
  };
  /**
   * * END FILTER HANDLING FUNCTIONS
   */

  // Save selected request information
  function onGetLsRequest(ls) {
    props.clickedLsRequest(ls || props.updateTargetLs, 'dropdown');
  }

  function onSortByHeader(field) {
    // If the field differs from current sort, set it to new field (as descending). Otherwise, alternate asc/desc for current field.
    let newSortedBy =
      sortedBy[0] === '-' && field === sortedBy.substring(1)
        ? field
        : '-' + field;
    setSortedBy(newSortedBy);

    if (props.lsRequests.length > 0) {
      props.clearLsRequestArray();
    }
    props.loadLsRequests(null, newSortedBy, filtered);
  }

  function onOpenOrCloseSidebar(isOpen) {
    setSidebarOpen(isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'auto';
    }
  }

  function onOpenCreate() {
    setCurtPopup('create');
    props.openOrCloseLsRequestServicePopup(true);
  }

  function onOpenStatusChange() {
    setCurtPopup('statusChange');
    props.openOrCloseLsRequestServicePopup(true);
  }

  function loadMoreLsRequests() {
    if (props.nextApi) {
      props.loadLsRequests(props.nextApi, sortedBy, filtered);
    }
  }

  return (
    <>
      <NewLsMenu
        filterFlag={filterFlag}
        statusChangeRequestCounter={statusChangeRequestCounter}
        columns={columns}
        columnPreset={columnPreset}
        showColumnMenu={showColumnMenu}
        checkAllColumns={checkAllColumns}
        toggleColumn={toggleColumn}
        toggleColumnMenu={() => setShowColumnMenu(!showColumnMenu)}
        changeColumnPreset={changeColumnPreset}
        toggleAllColumns={toggleAllColumns}
        onOpenCreate={onOpenCreate}
        onOpenStatusChange={onOpenStatusChange}
        sidebarOpen={sidebarOpen}
        sortedBy={sortedBy}
        defaultFilterValues={defaultFilterValues}
        filterPreset={filterPreset}
        filterValues={filterValues}
        search={search}
        setSearch={setSearch}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        showFilterMenu={showFilterMenu}
        onClearFilter={onClearFilter}
        onClearSearch={onClearSearch}
        changeFilterPreset={changeFilterPreset}
        toggleFilterMenu={() => setShowFilterMenu(!showFilterMenu)}
        onAddFilter={onAddFilter}
        onRemoveFilter={onRemoveFilter}
        applyFilters={applyFilters}
        buttonFilterToggle={buttonFilterToggle}
        optionsChanged={optionsChanged}
        setOptionsChanged={setOptionsChanged}
      />
      {
        <LsRequestsTable
          id='lsTable'
          columns={columns}
          sortedBy={sortedBy}
          onSortByHeader={onSortByHeader}
          onGetLsRequest={onGetLsRequest}
          loadMoreLsRequests={loadMoreLsRequests}
          onChangeCreateSubLS={() => setCurtPopup('createSubLS')}
          onChangeToBillHistory={() => setCurtPopup('history')}
          onChangeToDuplicateCheck={() => setCurtPopup('duplicate')}
          onChangeToUpdate={() => setCurtPopup('update')}
          onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
          optionsChanged={optionsChanged}
        />
      }
      {props.lsRequestServicePopupStatus && (
        <LsRequestServicesPopup currentDisplay={curtPopup} />
      )}
      {sidebarOpen && (
        <Sidebar
          onSetOpen={e => {
            onOpenOrCloseSidebar(e);
          }}
          open={sidebarOpen}
          pullRight={true}
          sidebar={
            <LsInfoDisplay
              infoLocation='sidebar'
              onChangeToUpdate={() => setCurtPopup('update')}
              onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
              onGetLsRequest={onGetLsRequest}
            />
          }
          styles={{
            sidebar: {
              background: 'white',
              zIndex: 4,
              overflow: 'hidden',
              maxWidth: '350px',
              width: '100%',
              marginTop: '200px',
              marginRight: '30px',
              borderRadius: '8px 8px 0px 0px',
              bottom: '30px',
            },
            overlay: { zIndex: 3 },
          }}>
          <div style={{ display: 'none' }}></div>
        </Sidebar>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    lsRequests: state.lsRequestsReducer.lsRequests,
    lsRequestStatusApprovals: state.lsRequestsReducer.lsRequestStatusApprovals,
    userProfile: state.userReducer.userProfile,
    lsRequestServicePopupStatus:
      state.lsRequestsReducer.lsRequestServicePopupStatus,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    nextApi: state.lsRequestsReducer.nextApi,
    lsrFields: state.lsRequestsReducer.lsSearchFields,
    docFields: state.lsRequestsReducer.lsDocSearchFields,
    searchLMs: state.lsRequestsReducer.isLegalMemoSearch,
  };
};

const mapDispatchToProps = {
  loadLsRequests,
  loadLsRequestStatusApprovalList,
  clearLsRequestArray,
  clickedLsRequest,
  fetchOneLsr,
  openOrCloseLsRequestServicePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsRequestsList);
