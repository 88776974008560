import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from '../../styles/ls-request/LsRequestsList.module.scss';
import { connect } from 'react-redux';
import {
  activateLsRequest,
  loadLsRequestStatusApprovalList,
  clickedLsRequest,
  openOrCloseLsRequestServicePopup,
  updateTargetLsRequest,
  changeOldStatusToNewStatus,
} from '../../actions/lsRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { formatDateTime, isConfidential } from '../../utils/helper';
import File from '../library/File';
import parse from 'html-react-parser';
import TableHeader from '../library/TableHeader';
import DuplicatesTooltip from './DuplicatesTooltip';
import {
  activeStatuses,
  rolesUnderCouncilMembers,
} from '../../services/constants';
import { ThreeDotsVertical } from '../../services/SvgLibrary';
import ColumnResizer from 'react-table-column-resizer';

const LsRequestsStatusTable = props => {
  const tableContainerRef = useRef(null);
  const tableRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const [altTag, setAltTag] = useState('');
  const [approvedStatesRequestsArray, setApprovedStatesRequestsArray] =
    useState([]);
  const [rejectedStatusRequestsArray, setRejectedStatusRequestsArray] =
    useState([]);
  const [payloadObj, setPayloadObj] = useState({});
  const [statusChangeActionIsDispateched, setStatusChangeActionDispatch] =
    useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const {
    duplicateCheckResults,
    invalidSearch,
    isLoading,
    lsRequestStatusApprovals,
    changeOldStatusToNewStatus,
    clickedLsRequest,
    columns,
    currentDupsearchLsNum,
    getFileAndDownload,
    id,
    onOpenOrCloseSidebar,
    userProfile,
  } = props;

  const loadApprovalOnLoad = useCallback(
    props.loadLsRequestStatusApprovalList,
    []
  );

  // Handling table rows highlighting

  const handleMarking = (e, ls) => {
    let val = e.target.value;
    let lsStatusRequestPayload = {
      updated_at: ls.updated_at,
      ls_number: ls.ls_number,
      new_status: ls.new_status,
      new_status_requestor: ls.new_status_requestor,
    };
    const findHelper = (targetArray, lsStatusRequestPayload) => {
      return targetArray.find(
        x => x.ls_number === lsStatusRequestPayload.ls_number
      );
    };

    const removeFromStatusRequestsArray = (targetArray, lsToRemove) => {
      return targetArray.filter(
        lsStatusRequestObj =>
          lsStatusRequestObj.ls_number !== lsToRemove.ls_number
      );
    };

    if (val === 'green') {
      if (findHelper(rejectedStatusRequestsArray, lsStatusRequestPayload)) {
        setRejectedStatusRequestsArray(prevHighlighted =>
          removeFromStatusRequestsArray(prevHighlighted, lsStatusRequestPayload)
        );
      }
      setApprovedStatesRequestsArray(prevHighlighted => [
        ...prevHighlighted,
        lsStatusRequestPayload,
      ]);
    } else if (val === 'red') {
      if (findHelper(approvedStatesRequestsArray, lsStatusRequestPayload)) {
        setApprovedStatesRequestsArray(prevHighlighted =>
          removeFromStatusRequestsArray(prevHighlighted, lsStatusRequestPayload)
        );
      }
      setRejectedStatusRequestsArray(prevHighlighted => [
        ...prevHighlighted,
        lsStatusRequestPayload,
      ]);
    } else if (val === 'unmarked') {
      if (findHelper(approvedStatesRequestsArray, lsStatusRequestPayload)) {
        setApprovedStatesRequestsArray(prevHighlighted =>
          removeFromStatusRequestsArray(prevHighlighted, lsStatusRequestPayload)
        );
      }
      if (findHelper(rejectedStatusRequestsArray, lsStatusRequestPayload)) {
        setRejectedStatusRequestsArray(prevHighlighted =>
          removeFromStatusRequestsArray(prevHighlighted, lsStatusRequestPayload)
        );
      }
    }
  };

  // Handling multi status approval submit:
  const submitAllStatusRequests = () => {
    setPayloadObj({
      approved: approvedStatesRequestsArray,
      rejected: rejectedStatusRequestsArray,
    });
    newStatusApprovalHandler({
      approved: approvedStatesRequestsArray,
      rejected: rejectedStatusRequestsArray,
    });
  };

  const newStatusApprovalHandler = payloadObj => {
    triggerDispatchNewStatusUpdate(payloadObj);
  };

  const triggerDispatchNewStatusUpdate = useCallback(async payloadObj => {
    setShowSpinner(true);

    await changeOldStatusToNewStatus(payloadObj);
    setPayloadObj({});
    setStatusChangeActionDispatch(true);
    setApprovedStatesRequestsArray([]);
    setRejectedStatusRequestsArray([]);

    setTimeout(() => {
      setShowSpinner(false);
      window.location.reload();
    }, 3000);
  }, []);

  useEffect(() => {
    if (statusChangeActionIsDispateched) {
      loadApprovalOnLoad();
      setStatusChangeActionDispatch(false);
    }
  }, [statusChangeActionIsDispateched]);

  useEffect(() => {
    if (!isLoading && lsRequestStatusApprovals.length === 0) {
      setErrorMessage('No Status Approval Requested');
      setErrorImage('/../img/no-status-approval-request.svg');
      setAltTag('No Valid Results');
    } else {
      setErrorMessage('');
      setErrorImage('');
      setAltTag('');
    }
  }, [isLoading, invalidSearch, lsRequestStatusApprovals]);

  function onOpenSidebar(ls) {
    onOpenOrCloseSidebar(true);
    clickedLsRequest(ls, 'clicked');
    document.body.style.overflow = 'hidden';
  }

  // Filter the columns
  const lsrColumns = columns.filter(column => column.field === 'ls_number');
  const statusColumns = columns.filter(column => column.field === 'status');
  const newStatusColumns = columns.filter(
    column => column.field === 'new_status'
  );
  const newStatusRequestorColumns = columns.filter(
    column => column.field === 'new_status_requestor'
  );

  const restOfColumns = columns.filter(
    column =>
      column.field !== 'status' &&
      column.field !== 'ls_number' &&
      column.field !== 'new_status' &&
      column.field !== 'new_status_requestor'
  );

  // Create a new array with the desired columns order
  const inOrderColumns = [
    ...lsrColumns,
    ...statusColumns,
    ...newStatusColumns,
    ...newStatusRequestorColumns,
    ...restOfColumns,
  ];

  /** Table Headers */
  // Display the table header
  const tableHeaders = inOrderColumns.map((column, idx) => {
    if (
      !(
        rolesUnderCouncilMembers.includes(userProfile.role) &&
        column.field === 'confidential_comments'
      )
    ) {
      return (
        (column.show ||
          column.field === 'ls_number' ||
          column.field === 'status' ||
          column.field === 'new_status' ||
          column.field === 'new_status_requestor') && (
          <React.Fragment key={idx}>
            <TableHeader
              title={column.title}
              key={idx}
              description={column.description}
              toolTipId={idx}
            />
            <ColumnResizer className={styles['resizer']} />
          </React.Fragment>
        )
      );
    }
    return null;
  });

  /** Table Content (Rows) */
  // Display table rows -> lsRequests state mapped
  const tableRows = lsRequestStatusApprovals.map((ls, idx) => {
    // If the ls number is the one currently being reviewed, exclude from table
    if (ls.ls_number !== currentDupsearchLsNum) {
      // All other ls requests
      let rowClassName;
      let inlineStyles;
      if (approvedStatesRequestsArray.find(x => x.ls_number === ls.ls_number)) {
        rowClassName = 'table-tr-status-mark-to-approve';
      } else if (
        rejectedStatusRequestsArray.find(x => x.ls_number === ls.ls_number)
      ) {
        rowClassName = 'table-tr-status-mark-to-reject';
      } else {
        rowClassName = 'table-tr';
      }
      return (
        <tr className={rowClassName} style={inlineStyles} key={idx}>
          {inOrderColumns.map((column, idx1) => {
            if (
              column.show ||
              column.field === 'ls_number' ||
              column.field === 'status' ||
              column.field === 'new_status' ||
              column.field === 'new_status_requestor'
            ) {
              let cell;
              let field = column.field;
              let value = ls[field] || '';

              if (field === 'ls_number') {
                cell = <td key={idx1}>{value}</td>;
              } else if (field === 'status') {
                cell = (
                  <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                    <div>{parse(value)}</div>
                  </td>
                );
              } else if (field === 'new_status') {
                cell = (
                  <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                    <div>{parse(value)}</div>
                  </td>
                );
              } else if (field === 'new_status_requestor') {
                cell = (
                  <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                    <div>{parse(value.full_name)}</div>
                  </td>
                );
              } else if (column.show) {
                if (field === 'date_received') {
                  cell = <td key={idx1}>{formatDateTime(ls.date_received)}</td>;
                } else if (column.type === 'file') {
                  const files = value;
                  cell = (
                    <td key={idx1}>
                      {files.map((file, index) => (
                        <File
                          id={`lsr_${field}-${idx}-${idx1}-${index}`}
                          file={file}
                          getFileAndDownload={getFileAndDownload}
                          key={index}
                        />
                      ))}
                    </td>
                  );
                } else if (field === 'committee') {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {parse(value.committee_name)}
                    </td>
                  );
                } else if (field === 'associated_report_tracking') {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {parse(
                        value
                          ? value.map(report => report.title).join(', ')
                          : ''
                      )}
                    </td>
                  );
                } else if (
                  ['first_prime', 'staff', 'submitted_by'].includes(field)
                ) {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {parse(value.full_name)}
                    </td>
                  );
                } else if (field === 'contact_person') {
                  cell = (
                    <td
                      onClick={() => onOpenSidebar(ls)}
                      key={idx1}
                      id={`${ls.ls_number}-${field}`}>
                      <pre className={styles['pre']}>
                        {parse(value.full_name)}
                        <br />
                        {parse(value.phone)}
                        <br />
                        {parse(value.email)}
                      </pre>
                    </td>
                  );
                } else if (
                  [
                    'description_problem',
                    'description_legislative_solution',
                    'description_related_discussions',
                    'cm_notes',
                    'division',
                    'ls_type',
                    'first_in_time',
                    'confidential_comments',
                    'background',
                    'background_inspiration',
                    'background_existing_laws',
                    'attachment_descriptions',
                  ].includes(field)
                ) {
                  if (field === 'confidential_comments') {
                    value = value ? value : '';
                  }

                  cell = (
                    <td
                      className={styles['ls-' + field.replace(/_/g, '-')]}
                      onClick={() => onOpenSidebar(ls)}
                      key={idx1}>
                      <div>{parse(value)}</div>
                    </td>
                  );
                } else if (field === 'reintroduction') {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {value === true ? 'Yes' : value === false ? 'No' : 'N/A'}
                    </td>
                  );
                } else if (field === 'prev_sess_ls_number') {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {value ? value : 'N/A'}
                    </td>
                  );
                } else if (
                  [
                    'make_first_prime_public',
                    'duplicate_check',
                    'activation_requested',
                  ].includes(field)
                ) {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {value ? 'True' : 'False'}
                    </td>
                  );
                } else if (['overlapping_ls', 'related_ls'].includes(field)) {
                  let lsArray = value.map(ls => ls.ls_number);
                  cell = (
                    <td
                      className={styles['ls-duplicates']}
                      onClick={() => onOpenSidebar(ls)}
                      key={`ls-${field}-${ls['ls_number']}`}>
                      {lsArray.length < 1 ? null : (
                        <DuplicatesTooltip
                          index={`ls-${field}-${ls['ls_number']}`}
                          dupes={lsArray}
                          field={field.replace(/_/g, ' ').toUpperCase()}
                        />
                      )}
                    </td>
                  );
                } else if (field === 'last_activated') {
                  let sixtyText = null;
                  if (value) {
                    let activated = new Date(value);
                    activated.setDate(activated.getDate() + 1);
                    let today = new Date();
                    let daysDifference = Math.ceil(
                      Math.abs(today - activated) / (1000 * 60 * 60 * 24)
                    ); // ms * s * min * hour
                    if (daysDifference <= 60) {
                      sixtyText = (
                        <span
                          style={{
                            color: '#4A5F96',
                            fontSize: '.9em',
                            fontWeight: 'bold',
                          }}>{`${
                          60 - daysDifference
                        } day(s) UNTIL 60 days`}</span>
                      );
                    } else {
                      sixtyText = (
                        <span
                          style={{
                            color: '#CA4A4A',
                            fontSize: '.9em',
                            fontWeight: 'bold',
                          }}>{`${
                          daysDifference - 60
                        } day(s) PAST 60 days`}</span>
                      );
                    }
                  }
                  cell = (
                    <td
                      className={styles['ls-duplicates']}
                      onClick={() => onOpenSidebar(ls)}
                      key={`ls-${field}-${ls['ls_number']}`}>
                      {parse(value)}
                      {!rolesUnderCouncilMembers.includes(userProfile.role) &&
                        activeStatuses.includes(ls['status']) && (
                          <>
                            <br />
                            {sixtyText}
                          </>
                        )}
                    </td>
                  );
                } else if (field === 'matters') {
                  return (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {parse(
                        value
                          ? value.map(matter => matter.intro).join(', ')
                          : ''
                      )}
                    </td>
                  );
                } else {
                  cell = (
                    <td onClick={() => onOpenSidebar(ls)} key={idx1}>
                      {parse(value)}
                    </td>
                  );
                }
              }

              return (
                <React.Fragment key={idx1}>
                  {cell}
                  {cell && <td className={styles['resizer']} />}
                </React.Fragment>
              );
            }
          })}

          <td>
            <div>
              <div className='dropdown dropstart'>
                <button
                  className={`btn ${styles['column-menu']}`}
                  type='button'
                  id={`ls-options-dropdown-${ls.ls_number}`}
                  data-bs-toggle='dropdown'
                  data-bs-offset='0,11'
                  data-bs-reference='parent'
                  aria-expanded='false'>
                  <ThreeDotsVertical />
                </button>
                <ul
                  className={`dropdown-menu ${styles['dropdown-menu-adjusted']}`}
                  aria-labelledby={`ls-options-dropdown-${ls.ls_number}`}>
                  {!approvedStatesRequestsArray.some(
                    obj => obj.ls_number === ls.ls_number
                  ) && (
                    <li>
                      <button
                        className='dropdown-item'
                        type='button'
                        value='green'
                        onClick={e => handleMarking(e, ls)}>
                        Highlight To Approve
                      </button>
                    </li>
                  )}
                  {!rejectedStatusRequestsArray.some(
                    obj => obj.ls_number === ls.ls_number
                  ) && (
                    <li>
                      <button
                        className='dropdown-item'
                        type='button'
                        value='red'
                        onClick={e => handleMarking(e, ls)}>
                        Highlight To Reject
                      </button>
                    </li>
                  )}
                  <li>
                    <button
                      className='dropdown-item'
                      type='button'
                      value='unmarked'
                      onClick={e => handleMarking(e, ls)}>
                      Unmark
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      );
    }
    return null;
  });
  /** End Table Content */

  const disableSubmitButtonYesOrNo =
    approvedStatesRequestsArray.length === 0 &&
    rejectedStatusRequestsArray.length === 0
      ? true
      : false;

  return (
    <>
      <div
        ref={tableContainerRef}
        id={id}
        className={`${styles['table-container']} ${styles['status']}`}>
        <table
          ref={tableRef}
          id='lsrTable'
          cellSpacing='0'
          className={`${styles['list-table']} list-table`}>
          <thead>
            <tr className='table-headers'>
              {tableHeaders}
              <th className={styles['checkbox-header']}></th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
        {!isLoading &&
          (lsRequestStatusApprovals.length === 0 || invalidSearch) && (
            <div
              className={`text-center p-3 ${styles['no-valid-result-container']}`} style={{marginTop: '138px'}}>
              <img src={errorImage} alt={altTag} style={{width:'80%'}}></img>
              <p>{errorMessage}</p>
            </div>
          )}
      </div>

      <div className='d-flex align-items-center justify-content-end border-top p-3'>
        {
          <button
            disabled={disableSubmitButtonYesOrNo || showSpinner}
            onClick={() => {
              submitAllStatusRequests();
              props.onClose;
            }}
            className='submit-button'
            type='button'>
            {showSpinner && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'></span>
            )}{' '}
            Submit
          </button>
        }
      </div>

      {(isLoading ||
        (window.location.pathname.includes('LsDupSearch') &&
          (duplicateCheckResults === undefined ||
            duplicateCheckResults.length === 0))) && (
        <div
          className={`spinner-grow ${styles['spinner-animation']}`}
          role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    committeeList: state.userReducer.committees,
    invalidSearch: state.lsRequestsReducer.invalidSearch,
    isLoading: state.lsRequestsReducer.isLoading,
    lsRequests: state.lsRequestsReducer.lsRequests,
    lsRequestStatusApprovals: state.lsRequestsReducer.lsRequestStatusApprovals,
    selectedLs: state.lsRequestsReducer.updateTargetLs,
    nextApi: state.lsRequestsReducer.nextApi,
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    duplicateCheckResults: state.lsRequestsReducer.duplicateCheckResults,
    searchTerms: state.lsRequestsReducer.searchTerms,
  };
};

const mapDispatchToProps = {
  activateLsRequest,
  loadLsRequestStatusApprovalList,
  clickedLsRequest,
  getFileAndDownload,
  openOrCloseLsRequestServicePopup,
  updateTargetLsRequest,
  changeOldStatusToNewStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LsRequestsStatusTable);
