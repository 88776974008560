import React from 'react';

export const activeStatuses = [
  'Activation in Progress',
  'Assigned',
  'Resource Letter',
  'Returned for Edits',
  'Review – Director',
];

export const inactiveStatuses = [
  'Activation Requested',
  'Adopted',
  'Enacted',
  'Introduced',
  'Low-Priority Hold',
  'Recommending Withdrawal',
  'Rejected',
  'Sent to CM',
  'Staff Hold',
  'Specific Additional Information Requested',
  'Withdrawn',
  'Withdrawn - Former CM',
];
export const statuses = [...activeStatuses, ...inactiveStatuses].sort();

export const rolesUnderCouncilMembers = [
  'Council Member',
  'District Chief of Staff',
  'District Legislative Director',
  'District Legislative Fellow', // Legislative Interns at District Offices
  'District Proc/Citation Liaison',
  'Speaker/Council Member',
  // Borough Pres Roles
  'Bronx Borough President',
  'Brooklyn Borough President',
  'Manhattan Borough President',
  'Queens Borough President',
  'Staten Island Borough President',
  // Public Advocate Office specific
  'District Legislative/Policy Associate',
  'Former Public Advocate',
  'Public Advocate',
];
export const agencyRoles = ['Agency'];
export const superAdminRoles = ['Admin'];
export const adminRoles = [
  'Administrative Support',
  'Chief Data Scientist',
  'Deputy Director, Operations', // Johanna Castro
  'Designer',
  'Director',
  'Legislative Administrative Assistant',
  'Legislative Operations Coordinator', // Michelle Otis
  'Legislative Program Manager',
  'Operations Manager',
  'Senior Legislative Administrative Assistant',
  'Web Developer',
  // IT Div
  'Deputy Network Manager',
  'Network Manager',
];
export const thirdTierRoles = [
  'Chief of Staff', // Speaker's CoS
  'Deputy Chief of Staff',
  'Managing Deputy Director',
  'Special Counsel to the Speaker',
];
export const fourthTierRoles = ['Assistant Deputy Director', 'Deputy Director'];
export const fifthTierRoles = [
  'Attorney',
  'Counsel',
  'Legislative Counsel',
  'Senior Legislative Counsel',
];
export const sixthTierRoles = [
  'Data Scientist',
  'Data Visualization Specialist',
  'Legislative Fellow', // Leg Division Interns
  'Legislative Policy Analyst',
  'Legislative Project Support', // Role specifically for Vera Mekju
  'Senior Data Scientist',
  'Senior Legislative Policy Analyst',
];
export const legDivisionRoles = [
  ...adminRoles,
  ...thirdTierRoles,
  ...fourthTierRoles,
  ...fifthTierRoles,
  ...sixthTierRoles,
];
export const attorneyRoles = [
  'Associate Deputy Director',
  'Chief Financial Officer and Deputy Chief of Staff', // Speaker's Dep CoS
  'Finance Attorney',
  'General Counsel',
  'Governmental Affairs Attorney',
  'Infrastructure Division Attorney',
  'Land Use Attorney',
];
export const communityEngagementRoles = ['Citation Team', 'Proclamation Team'];
export const nonLegDivisionRoles = [
  ...rolesUnderCouncilMembers,
  ...agencyRoles,
  ...communityEngagementRoles,
];

export const roles = [
  ...attorneyRoles,
  ...legDivisionRoles,
  ...nonLegDivisionRoles,
].sort();

export const divisionList = [
  { name: 'BLA Caucus' },
  { name: 'Common-Sense Caucus' },
  { name: 'Irish Caucus' },
  { name: 'Italian Caucus' },
  { name: 'Jewish Caucus' },
  { name: 'LGBTQIA+ Caucus' },
  { name: 'Progressive Caucus' },
  { name: "Women's Caucus" },
];
export const shortDisclaimer = (
  <p>
    <strong>Disclaimer: </strong>The information contained on this website is
    privileged and confidential. If you are not an authorized member of Council
    staff, this serves as notice that you have been directed to this website in
    error and that any review, dissemination, distribution, or copying of the
    information contained on this website is strictly prohibited.
  </p>
);

export const fullDisclaimer = (
  <>
    <h3>Disclaimer and Use Policy</h3>
    <p style={{ fontSize: '.8rem' }}>
      CLEX is confidential and intended exclusively for internal use by select
      employees of the New York City Council (hereinafter “the Council”) at the
      direction of and authorized by the Director of the Legislative Division.
      If you are not an authorized member of Council staff, this disclaimer
      serves as notice that you have been directed to this website in error and
      that any review, dissemination, distribution, or copying of the
      information contained on this website is unauthorized and strictly
      prohibited under the terms of this policy.
    </p>
    <p style={{ fontSize: '.8rem' }}>
      The information contained in CLEX may be generated and viewed only by
      designated staff of the Council’s Legislative, Finance, Land Use and
      General Counsel Divisions (hereinafter “Council Staff”) to inform the
      Council’s legislative, budget and land use decision-making processes.
      Council Staff are prohibited from sharing CLEX or its contents with anyone
      who is not also Council Staff. Council Staff must take steps to avoid
      inadvertent disclosure of the contents of CLEX.
    </p>
    <p style={{ fontSize: '.8rem' }}>
      The articles maintained on CLEX are draft formulations of the legal
      research, analysis and opinions of the attorneys of the Council’s
      Legislative, Finance, Land Use and General Counsel Divisions. This
      iterative collection of materials exists for the purpose of advising the
      legislative body and its constituent members. As such, these articles,
      which are subject to continuous review and revision, do not reflect final
      agency policy or determinations of the Council. Any revisions to material
      maintained on CLEX may be made only by an attorney or by other Council
      Staff acting at the direction of and under attorney supervision.
    </p>
    <p style={{ fontSize: '.8rem' }}>
      While efforts have been made to provide accurate information on CLEX, the
      Council makes no warranties or representations regarding its accuracy or
      completeness. Readers are advised that the Council disclaims any liability
      for any damages in connection with unauthorized use of CLEX. The inclusion
      in CLEX of any hypertext or service is not intended as an endorsement.
    </p>
  </>
);

export const cmNonCreateFields = ['cm_notes'];

export const cmNonEditFields = [
  'bill',
  'cm_notes',
  'committee',
  'coversheet',
  'division',
  'first_in_time',
  'first_prime',
  'hearing_report',
  'hearing_summary',
  'ls_type',
  'make_first_prime_public', // Alaa said we can allow them to edit if the box is unchecked, but is disabled if checked
  'plain_language_summary',
  'reintroduction',
  'resource_letter',
  'staff',
  'status',
];

export const staffEditFields = [
  'attachment_descriptions',
  'bill_matrix',
  'bill',
  'cm_attachment',
  'cm_notes',
  'committee',
  'confidential_comments',
  'contact_person',
  'coversheet',
  'division',
  'first_in_time',
  'first_prime',
  'hearing_report',
  'hearing_summary',
  'legal_memo',
  'ls_type',
  'make_first_prime_public',
  'matters',
  'plain_language_summary',
  'prev_sess_ls_number',
  'reintroduction',
  'resource_letter',
  'staff',
  'status',
];

export const cmEditFields = [
  'attachment_descriptions',
  'contact_person',
  'cm_attachment',
  'make_first_prime_public',
];

export const lsModelTextFields = [
  'attachment_descriptions',
  'background_existing_laws',
  'background_inspiration',
  'background',
  'cm_notes',
  'confidential_comments',
  'description_legislative_solution',
  'description_problem',
  'description_related_discussions',
  'division',
  'first_in_time',
  'ls_type',
  'status',
];

export const eventToolTipText = (
  <>
    <p>
      <strong>Where and when will the proclamation be presented?</strong>
    </p>
    <p>
      This is usually a copy of an invitation, flyer or link, or an email from a
      constituent. This information essential for approving the proclamation and
      for ensuring that it is tailored to the specific occasion.
    </p>
  </>
);

export const honoreeToolTipText = (
  <>
    <p>
      This used for vetting as well as drafting or editing the proclamation. A
      résumé can help but is not adequate. The best proclamations are based on a
      brief bio or other background info (e.g. news articles, blog posts, etc.).
    </p>
  </>
);

export const reasonToolTipText = (
  <>
    <ul style={{ 'padding': '10px' }}>
      <li>
        <em>
          <strong>Birthday, Retirement, Anniversary</strong>&nbsp; proclamations
          must also focus on the honoree’s service and contributions to New York
          City.
        </em>
      </li>
      {/* <li>
        <em>
          <strong>Stated Meeting Ceremonial</strong>&nbsp; (currently suspended)
        </em>
      </li> */}
      <li>
        <em>
          <strong>Other</strong> events and honorees must still be significant
          and relevant to New York City.
        </em>
      </li>
    </ul>
  </>
);

export const otherToolTipText = (
  <>
    <p>
      NOTE: Proclamations
      <strong style={{ 'textDecoration': 'underline' }}>MAY NOT</strong> be
      produced for:
    </p>
    <ul>
      <li>Events that have already occurred</li>
      <li>Events outside the five boroughs</li>
      <li>Declarations of unofficial days, weeks, months, etc.</li>
      <li>The same honoree more than once within 12 months</li>
      <li>For-profit organizations without a clear record of public service</li>
      <li>
        Recipients or events that may damage the Council’s public image or
        reputation.
      </li>
      <li>
        Honorees outside of NYC whose work does not have a direct impact in NYC
      </li>
      <li>
        Political purposes (including to campaign fundraisers or those requested
        by lobbying firms)
      </li>
      <li>
        Small, private events, including but not limited to (1.) political
        events, weddings, or events (2.) strictly religious events like
        Baptisms, Bar Mitzvahs, revivals, and Christmas parades, (3.)
        business-type conferences/seminars, (4.) reunions, and (5.) casual
        occasions with a vague purpose.
      </li>
      <li>
        Current Council staff; or departing Council Staff with less than 10
        years of service.
      </li>
    </ul>
  </>
);
export const userPreferenceLabels = {
  'dashboard_widgets': {
    'title': 'Dashboard Widgets',
    'items': {
      'all': {
        'title': 'All Widgets',
        'items': {
          'commemorations': {
            'heading': 'Commemorations',
            'description': '',
          },
          'events': {
            'heading': 'Events',
            'description': '',
          },
          'active_lsr': {
            'heading': 'My Active LSRs',
            'description': '',
          },
          'clex_review': {
            'heading': 'CLEX Review',
            'description': '',
          },
          'late_op_req': {
            'heading': 'Late Operational Requirements',
            'description': '',
          },
          'upcoming_op_req': {
            'heading': 'Upcoming Operational Requirements',
            'description': '',
          },
          'recent_op_req': {
            'heading': 'Recently Added Operational Requirements',
            'description': '',
          },
        },
      },
    },
  },
  'emails': {
    'title': 'Emails',
    'items': {
      'lsrs': {
        'title': 'LS Requests',
        'items': {
          'status_change_sent_to_cm': {
            'heading': 'LSR Status Change Notifications',
            'description':
              "Receive emails when an LSR's status is changed to 'Sent to CM.'",
          },
          'active_over_60_days': {
            'heading': 'LSR Activity Duration Alerts',
            'description':
              'Receive emails when LSRs have been active for +60 consecutive days.',
          },
          'track_status_change_as_requestor': {
            'heading': 'Track LSR Status Updates',
            'description':
              "Receive emails requesting review to change an LSR's status.",
          },
          'user_assigned_as_staff': {
            'heading': 'Staff Assignment Notifications',
            'description':
              'Receive emails when assigned as the Staff member to an updated LS Request.',
          },
          'bdd_receive_emails': {
            'heading': 'Bill Drafting & Duplicate Search Team',
            'description': 'Receive email notifications.',
          },
        },
      },
      'operational_requirements': {
        'title': 'Operational Requirements',
        'items': {
          'new_report_uploaded': {
            'heading': 'New Reports',
            'description':
              'Receive emails when a new report has been uploaded.',
          },
          'send_tracker_reminders': {
            'heading': 'Tracker Reminders',
            'description': 'Receive emails containing report reminders.',
            'items': {
              'reports_30_days_late': {
                'heading': 'Reports 30 Days Late',
                'description':
                  'Receive email reminders for reports that are 30 days past due.',
              },
              'reports_15_days_late': {
                'heading': 'Reports 15 Days Late',
                'description':
                  'Receive email reminders for reports that are 15 days past due.',
              },
              'reports_today': {
                'heading': 'Reports Due Today',
                'description':
                  'Receive email reminders for reports that are due today',
              },
              'reports_in_15_days': {
                'heading': 'Reports Due in 15 Days',
                'description':
                  'Receive email reminders for reports due in 15 days',
              },
              'reports_in_30_days': {
                'heading': 'Reports Due in 30 Days',
                'description':
                  'Receive email reminders for reports due in 30 days',
              },
              'milestones_today': {
                'heading': 'Milestones Due Today',
                'description':
                  'Receive email reminders for milestones that are due today',
              },
              'milestones_in_30_days': {
                'heading': 'Milestones Due in 30 Days',
                'description':
                  'Receive email reminders for milestones due in 30 days',
              },
            },
          },
        },
      },
      'proclamations': {
        'title': 'Proclamations',
        'items': {
          'receive_emails': {
            'heading': 'Proclamations Team',
            'description': 'Receive email notifications',
          },
        },
      },
      'citations': {
        'title': 'Citations',
        'items': {
          'receive_emails': {
            'heading': 'Citations Team',
            'description': 'Receive email notifications.',
          },
        },
      },
    },
  },
  'notifications': {
    'title': 'IWOMM Notifications',
    'items': {
      'lsrs': {
        'title': 'LS Requests',
        'items': {
          'created': {
            'heading': 'New LSR Created',
            'description':
              'Receive notifications when an LSR associated to you is created.',
          },
          'updated': {
            'heading': 'LSR Updated',
            'description':
              'Receive notifications when an LSR associated to you is updated.',
          },
          'activation_requested': {
            'heading': 'LSR Activation Request',
            'description':
              'Receive notifications when you have been requested to accept or reject an LSR',
          },
          'status_requires_approval': {
            'heading': 'LSR Status Change Requires Approval',
            'description':
              'Receive notifications when a LSR status change requires approval.',
          },
          'status_change_approved': {
            'heading': 'LSR Status Change Approved',
            'description':
              'Receive notifications when a LSR status change has been approved.',
          },
          'status_change_rejected': {
            'heading': 'LSR Status Change Rejected',
            'description':
              'Receive notifications when a LSR status change has been rejected.',
          },
        },
      },
      'operational_requirements': {
        'title': 'Operational Requirements',
        'items': {
          'report_created': {
            'heading': 'New Report Created',
            'description':
              'Receive notifications when a new report has been created successfully.',
          },
          'report_updated': {
            'heading': 'Report Updated',
            'description':
              'Receive notifications when a report has been updated.',
          },
        },
      },
      'clex': {
        'title': 'CLEX',
        'items': {
          'created': {
            'heading': 'New CLEX Article Created',
            'description':
              'Receive notifications when a new CLEX article has been created successfully.',
          },
          'updated': {
            'heading': 'CLEX Article Updated',
            'description':
              'Receive notifications when a CLEX article has been updated.',
          },
        },
      },
      'proclamations': {
        'title': 'Proclamations',
        'items': {
          'request_created': {
            'heading': 'Proclamations Request Created',
            'description':
              'Receive notifications when a new proclamations request has been created successfully.',
          },
          'request_updated': {
            'heading': 'Proclamations Request Updated',
            'description':
              'Receive notifications when a proclamations request has been updated.',
          },
        },
      },
      'citations': {
        'title': 'Citations',
        'items': {
          'request_created': {
            'heading': 'Citations Request Created',
            'description':
              'Receive notifications when a new citations request has been created successfully.',
          },
          'request_updated': {
            'heading': 'Citations Request Updated',
            'description':
              'Receive notifications when a citations request has been updated.',
          },
        },
      },
    },
  },
};

export const filterNotificationStates = {
  lsr: { fullName: 'LS-Request', label: 'LSR', show: false },
  lsStatus: { fullName: 'LS-Status Change', label: 'Stat', show: false },
  opReq: { fullName: 'Operational Requirement', label: 'Req', show: false },
  clex: { fullName: 'CLEX', label: 'CLEX', show: false },
  proc: { fullName: 'Proclamation Request', label: 'Proc', show: false },
  cit: { fullName: 'Citation Request', label: 'Cit', show: false },
};
export const filterNotificationTypesByCategory = {
  lsr: [1, 2],
  lsStatus: [12,13,14],
  opReq: [3,4],
  clex: [5,6],
  proc: [8,9],
  cit: [10,11],
};

// comments are backend name, if different
export const lsrFieldsToIdx = {
  "Activation Requested": 0,
  "Assoc. Op Req": 1,
  "Attachment Description": 2,
  "Background Sess. 11": 3,
  "CM Notes": 4,
  "Committee": 5,
  "Confidential Comments": 6,
  "Contact Person": 7,
  "Date Received": 8,
  "Describe The Problem": 9,
  "Division": 10,
  "Existing Laws": 11,
  "First In Time": 12,
  "Initial Dup Check": 13,
  "Inspiration For LSR": 14,
  "Last Activated": 15,
  "Legislative Solution": 16,
  "LSR Number": 17,
  "LSR Type": 18,
  "Operational Requirement": 19,
  "Overlapping LSRs": 20,
  "Prev LSR #": 21,
  "Reintroduction": 22,
  "Related Discussions": 23,
  "Related LSRs": 24,
  "Sponsor": 25, // first prime
  "Staff": 26,
  "Status": 27,
  "Submitted By": 28,
  "Waived Confidentiality": 29,
}

// comments are backend name, if different
export const docFieldsToIdx = {
  "Prepared Legislation": 0, // bill
  "Bill Matrix": 1,
  "CM Attachments": 2,
  "Coversheet": 3,
  "Committee Report": 4, // hearing report
  "Hearing Summary": 5,
  "Legal Memo": 6,
  "Plain Language Summary": 7,
  "Change of Status Letter": 8, // resource letter
}

export const defaultLSRDocumentTypes = {
  'Check All': true,
  'Prepared Legislation': true,
  'Bill Matrix': true,
  'CM Attachments': true,
  'Committee Report': true,
  'Hearing Summary': true,
  'Legal Memo': true,
  'Plain Language Summary': true,
  'Change of Status Letter': true,
  'Coversheet': true,
};

export const defaultLSRFieldTypes = {
  'Check All': true,
  'Activation Requested': true,
  'Assoc. Op Req': true,
  'Attachment Description': true,
  'Background Sess. 11': true,
  'CM Notes': true,
  'Committee': true,
  'Confidential Comments': true,
  'Contact Person': true,
  'Date Received': true,
  'Describe The Problem': true,
  'Division': true,
  'Existing Laws': true,
  'First In Time': true,
  'Initial Dup Check': true,
  'Inspiration For LSR': true,
  'Last Activated': true,
  'Legislative Solution': true,
  'Legistar URL': true,
  'Local Law': true,
  'LSR Number': true,
  'LSR Type': true,
  'Operational Requirement': true,
  'Overlapping LSRs': true,
  'Prev LSR #': true,
  'Reintroduction': true,
  'Related Discussions': true,
  'Related LSRs': true,
  'Sponsor': true,
  'Staff': true,
  'Status': true,
  'Submitted By': true,
  'Waived Confidentiality': true,
};