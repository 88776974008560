import React from 'react';
import { connect } from 'react-redux';

import EditUserForm from '../user/EditUserForm';

import styles from '../../styles/user/UserProfile.module.scss';
import { EditPageIcon } from '../../services/SvgLibrary';

const ProfileInfoCard = ({ localState, userProfile, users }) => {
  const under_council_member = localState.selectedStaff?.under_council_member;
  const associated_cm = users.find(u => u.id === under_council_member);

  return (
    <div className={styles['user-profile-info-panel']}>
      {!localState.selectedStaff ? (
        <div className={styles['image-and-text-placeholder']}>
          <img src='/img/central-staff-icon.png' alt='User profile icon' />
          <h1>Staff Directory</h1>
        </div>
      ) : (
        <div>
          <div
            className='card'
            style={{ border: '0px', marginBottom: '25px' }}>
            <img
              src='/img/my-profile-cover-image.jpg'
              className={`card-img-top ${styles['staff-directory-cover-image']}`}
              aria-hidden='true'
              alt='Placeholder user profile banner'
            />
            <h1 className={styles['users-name']}>
              {localState.selectedStaff.full_name}
            </h1>
            <img
              src={`${localState.selectedStaff.profile_pic_icon
                ? localState.selectedStaff.profile_pic_icon.file
                : '/img/profile-image-placeholder.png'
                }`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/img/profile-image-placeholder.png'
              }}
              className={styles['staff-directory-profile']}
              alt='User profile icon'
            />
            {userProfile.permissions.auth.change_user ? (
              <button
                type='button'
                className={styles['edit-profile-button']}
                data-bs-toggle='modal'
                data-bs-target='#editCMUserProfile'>
                <EditPageIcon /> Edit Profile
              </button>
            ) : null}
            <div className='card-body'>
              <table className={styles['staff-directory-details']}>
                <tbody>
                  {userProfile.permissions.auth.change_user &&
                    !localState.selectedStaff.is_active && (
                      <tr>
                        <td colSpan='2'>
                          <strong>DEACTIVATED</strong>
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td>Username</td>
                    <td>{localState.selectedStaff.username}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{localState.selectedStaff.role}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <a
                        href={`mailto:${localState.selectedStaff.email}`}
                        style={{ color: '#4e46d2' }}>
                        {localState.selectedStaff.email}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{localState.selectedStaff.phone}</td>
                  </tr>
                  <tr>
                    <td>Alternative Phone</td>
                    <td>{localState.selectedStaff.alternative_phone || 'N/A'}</td>
                  </tr>
                  {localState.selectedStaff.desk_number &&
                    userProfile.desk_number && (
                      <tr>
                        <td>Desk Number</td>
                        <td>
                          {userProfile.bio && userProfile.profile_pic ? (
                            <Link
                              to='/seating'
                              state={{
                                selectedStaff: localState.selectedStaff,
                              }}>
                              {localState.selectedStaff.desk_number}
                            </Link>
                          ) : (
                            <a
                              href='#'
                              onClick={e => {
                                e.preventDefault();
                                alert(
                                  'ERROR! \n401 UNAUTHORIZED! \nUpdate your bio AND profile picture to see the floor plan!'
                                );
                              }}>
                              {localState.selectedStaff.desk_number}
                            </a>
                          )}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
          {localState.selectedStaff.bio && (
            <div className='card mb-4 border-0 rounded-3'>
              <div className='card-body'>
                <h2>Bio</h2>
                <p>{localState.selectedStaff.bio}</p>
              </div>
            </div>
          )}
          <div className={`card ${styles['staff-associations']}`}>
            <div className='card-body'>
              <table>
                <tbody>
                  {under_council_member && associated_cm && (
                    <tr>
                      <td className={styles['association-label-top']}>
                        Associated CM:
                      </td>
                      <td>
                        <ul style={{ listStyle: "none" }}>
                          <li>
                            <a href={`/staff/${associated_cm.id}`}>
                              {associated_cm.full_name}
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  )}
                  {users.filter(staff => staff.under_council_member === localState.selectedStaff.id).length > 0 &&
                    (localState.selectedStaff.role.includes('Council Member') || localState.selectedStaff.role.includes('Public Advocate')) && (
                      <tr>
                        <td className={styles['association-label-top']}>
                          Staffers:
                        </td>
                        <td>
                          <ul>
                            {!userProfile.permissions.auth.change_user &&
                              users.filter(
                                staff =>
                                  staff.under_council_member ===
                                  localState.selectedStaff.id &&
                                  staff.is_active
                              )
                                .map((staffer, idx) => (
                                  <li key={idx}>
                                    <a href={`/staff/${staffer.id}`}>
                                      {staffer.full_name}
                                    </a>
                                  </li>
                                ))}
                            {userProfile.permissions.auth.change_user &&
                              users.filter(
                                staff =>
                                  staff.under_council_member ===
                                  localState.selectedStaff.id
                              )
                                .map((staffer, idx) => (
                                  <li key={idx}>
                                    <a
                                      href={`/staff/${staffer.id}`}
                                      style={{
                                        textDecoration: !staffer.is_active
                                          ? 'line-through'
                                          : '',
                                      }}>
                                      {staffer.full_name}
                                    </a>
                                    {!staffer.is_active && (
                                      <strong>&#91;DEACTIVATED&#93;</strong>
                                    )}
                                  </li>
                                ))}
                          </ul>
                        </td>
                      </tr>
                    )}

                  {localState.selectedStaff.division && (
                    <tr>
                      <td className={styles['association-label-top']}>
                        Division/Teams:
                      </td>
                      <td>
                        <ul>
                          {localState.selectedStaff.division.map((div, idx) => (
                            <li key={idx}>{div.division_name}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  )}

                  {localState.selectedStaff.committees?.length > 0 && (
                    <tr>
                      <td className={styles['association-label-top']}>
                        Committees:
                      </td>
                      <td>
                        <ul>
                          {localState.selectedStaff.committees &&
                            localState.selectedStaff.committees.map(
                              (committee, idx) => (
                                <li key={idx}>
                                  {committee.committee_name}
                                </li>
                              )
                            )}
                        </ul>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className='modal fade'
            id='editCMUserProfile'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='editUserProfile'
            aria-hidden='true'>
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='editUserProfile'>
                    Edit profile
                  </h5>
                </div>
                <div className='modal-body' style={{ padding: '0px' }}>
                  <EditUserForm id={localState.selectedStaff.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>)
}

const mapStateToProps = state => ({
  users: state.userReducer.userProfile.permissions.auth.change_user
    ? state.userReducer.allUserList
    : state.userReducer.userList,
  userProfile: state.userReducer.userProfile,
  newUserCreationStatus: state.userReducer.newUserCreationStatus,
});

export default connect(mapStateToProps)(ProfileInfoCard);