//Action type constants
export const lsRequestActions = {
  CHECK_DUPLICATE: 'CHECK_DUPLICATE',
  CHECK_LS_IS_LOADING: 'CHECK_LS_IS_LOADING',
  CLEAR_LS_REQUEST_ARRAY: 'CLEAR_LS_REQUEST_ARRAY',
  CLICKED_LS_REQUEST: 'CLICKED_LS_REQUEST',
  CREATE_NEW_LS_REQUEST_STATUS: 'CREATE_NEW_LS_REQUEST_STATUS',
  CREATE_NEW_LS_REQUEST: 'CREATE_NEW_LS_REQUEST',
  DELETE_FILE: 'DELETE_FILE',
  EXPORT_ALL_LS_REQUESTS: 'EXPORT_ALL_LS_REQUESTS',
  EXPORTING: 'EXPORTING',
  GET_BILL_HISTORY: 'GET_BILL_HISTORY',
  GET_DUPLICATE_LS_REQUEST: 'GET_DUPLICATE_LS_REQUEST',
  GET_LS_REQUEST_HISTORY: 'GET_LS_REQUEST_HISTORY',
  GET_NEW_CREATED_LS_NUMBER: 'GET_NEW_CREATED_LS_NUMBER',
  IS_DUPLICATE_LS_LOADING: 'IS_DUPLICATE_LS_LOADING',
  LOAD_LS_REQUESTS: 'LOAD_LS_REQUESTS',
  LOAD_LS_REQUEST_STATUS_APPROVAL_LIST: 'LOAD_LS_REQUEST_STATUS_APPROVAL_LIST',
  OPEN_OR_CLOSE_LS_REQUEST_SERVICE_POPUP:
    'OPEN_OR_CLOSE_LS_REQUEST_SERVICE_POPUP',
  REQUEST_ACTIVATION: 'REQUEST_ACTIVATION',
  SAVE_ALL_HISTORY_LIST: 'SAVE_ALL_HISTORY_LIST',
  SAVE_CURRENT_LS_REQUEST: 'SAVE_CURRENT_LS_REQUEST',
  SAVE_ERROR_MESSAGE: 'SAVE_ERROR_MESSAGE',
  SAVE_LS_DOC_SEARCH_FIELDS: 'SAVE_LS_DOC_SEARCH_FIELDS',
  SAVE_LS_PROGRESS: 'SAVE_LS_PROGRESS',
  SAVE_LS_SEARCH_FIELDS: 'SAVE_LS_SEARCH_FIELDS',
  SELECT_LS_REVISION: 'SELECT_LS_REVISION',
  SHOULD_SEARCH_LEGAL_MEMOS: 'SHOULD_SEARCH_LEGAL_MEMOS',
  STORE_DUPLICATE_CHECK_RESULT: 'STORE_DUPLICATE_CHECK_RESULT',
  STORE_DUPLICATE_LS_REQUEST: 'STORE_DUPLICATE_LS_REQUEST',
  STORE_HIGHLIGHTED_LS_REQUESTS: 'STORE_HIGHLIGHTED_LS_REQUESTS',
  STORE_LS_REQUEST_HISTORY: 'STORE_LS_REQUEST_HISTORY',
  STORE_LS_REQUEST_STATUS_APPROVALS: 'STORE_LS_REQUEST_STATUS_APPROVALS',
  STORE_LS_REQUESTS: 'STORE_LS_REQUESTS',
  STORE_PAGINATION_NEXT: 'STORE_PAGINATION_NEXT',
  STORE_PAGINATION_PREV: 'STORE_PAGINATION_PREV',
  STORE_SEARCH_TERMS: 'STORE_SEARCH_TERMS',
  STORE_SEARCH_RESULT_COUNT: 'STORE_SEARCH_RESULT_COUNT',
  STORE_SINGLE_LS: 'STORE_SINGLE_LS',
  STORE_TARGET_LS_REQUEST: 'STORE_TARGET_LS_REQUEST',
  UPDATE_TARGET_LS_REQUEST_STATUS: 'UPDATE_TARGET_LS_REQUEST_STATUS',
  UPDATE_TARGET_LS_REQUEST: 'UPDATE_TARGET_LS_REQUEST',
  UPDATE_NEW_STATUS_CHANGE: 'UPDATE_NEW_STATUS_CHANGE',
};

// Action for get the ls request number from front-end form
export const checkDuplicate = requestNum => {
  return { type: lsRequestActions.CHECK_DUPLICATE, requestNum };
};

// Action for checking loading process
export const checkLsIsLoading = status => {
  return { type: lsRequestActions.CHECK_LS_IS_LOADING, status };
};

// Action for clearing the ls requests
export const clearLsRequestArray = () => {
  return { type: lsRequestActions.CLEAR_LS_REQUEST_ARRAY };
};

// Action for detecting current target(clicked) ls request
export const clickedLsRequest = (ls, actionType) => {
  return { type: lsRequestActions.CLICKED_LS_REQUEST, ls, actionType };
};

// Action for notifying that the new ls request creation has been created
export const createNewLsRequestStatus = status => {
  return { type: lsRequestActions.CREATE_NEW_LS_REQUEST_STATUS, status };
};

// Action for creating a new ls request
export const createNewLsRequest = newLs => {
  return { type: lsRequestActions.CREATE_NEW_LS_REQUEST, newLs };
};

export const deleteFile = (ls, id, field) => {
  return { type: lsRequestActions.DELETE_FILE, ls, id, field };
};

//Action for exporting all LS requests
export const exportLsRequest = () => {
  return { type: lsRequestActions.EXPORT_ALL_LS_REQUESTS };
};

export const exportingFullLS = bool => {
  return { type: lsRequestActions.EXPORTING, bool };
};

// Action for getting the bill history
export const getBillHistory = ls_number => {
  return { type: lsRequestActions.GET_BILL_HISTORY, ls_number };
};

export const getDuplicateLsRequest = ls => {
  return { type: lsRequestActions.GET_DUPLICATE_LS_REQUEST, ls };
};

export const getLsRequestHistory = ls_number => {
  return { type: lsRequestActions.GET_LS_REQUEST_HISTORY, ls_number };
};

// Action for getting the new created ls request number
export const getNewCreatedLsNumber = number => {
  return { type: lsRequestActions.GET_NEW_CREATED_LS_NUMBER, number };
};

export const isDuplicateLsLoading = status => {
  return { type: lsRequestActions.IS_DUPLICATE_LS_LOADING, status };
};

// Action triggered from View
export const loadLsRequests = (
  nextApi,
  sortedBy,
  filtered = { bool: false, url: '' }
) => {
  return {
    type: lsRequestActions.LOAD_LS_REQUESTS,
    nextApi,
    sortedBy,
    filtered,
  };
};

export const loadLsRequestStatusApprovalList = () => {
  return { type: lsRequestActions.LOAD_LS_REQUEST_STATUS_APPROVAL_LIST };
};

// Action for changing current status to new status

export const changeOldStatusToNewStatus = payloadObj => {
  return {
    type: lsRequestActions.UPDATE_NEW_STATUS_CHANGE,
    payloadObj,
  };
};

// Action for opening the ls request service popup window
export const openOrCloseLsRequestServicePopup = status => {
  return {
    type: lsRequestActions.OPEN_OR_CLOSE_LS_REQUEST_SERVICE_POPUP,
    status,
  };
};

export const activateLsRequest = ls_number => {
  return { type: lsRequestActions.REQUEST_ACTIVATION, ls_number };
};

// Action for saving the bill history
export const saveBillHistoryList = bills => {
  return { type: lsRequestActions.SAVE_ALL_HISTORY_LIST, bills };
};

// Action for saving the current clicked ls request
export const saveCurrentLsRequest = ls => {
  return { type: lsRequestActions.SAVE_CURRENT_LS_REQUEST, ls };
};

// Action for saving error message from backend after submit
export const saveErrorMessage = message => {
  return { type: lsRequestActions.SAVE_ERROR_MESSAGE, message };
};

//Save the progress of an LS
export const saveLSProgress = ls => {
  return { type: lsRequestActions.SAVE_LS_PROGRESS, ls };
};

export const selectLsRevision = (revision, index) => {
  return { type: lsRequestActions.SELECT_LS_REVISION, revision, index };
};

// Action for saving the duplicate checking result
export const storeDuplicateCheckResult = results => {
  return { type: lsRequestActions.STORE_DUPLICATE_CHECK_RESULT, results };
};

export const storeDuplicateLsRequest = request => {
  return { type: lsRequestActions.STORE_DUPLICATE_LS_REQUEST, request };
};

export const storeLsRequestHistory = (history = []) => {
  return { type: lsRequestActions.STORE_LS_REQUEST_HISTORY, history };
};

// Action triggered from Sagas
export const storeLsRequests = (
  lsRequests,
  prevApi,
  nextApi,
  fullCount,
  highlights,
  isLoading = false,
  invalidSearch = false,
) => {
  return {
    type: lsRequestActions.STORE_LS_REQUESTS,
    lsRequests,
    prevApi,
    nextApi,
    fullCount,
    highlights,
    isLoading,
    invalidSearch,
  };
};

export const storeSearchTerms = search => {
  return {
    type: lsRequestActions.STORE_SEARCH_TERMS,
    search
  }
}

export const storeHighlightedLsRequests = lsRequests => {
  return {type: lsRequestActions.STORE_HIGHLIGHTED_LS_REQUESTS, lsRequests}
}

export const storeLsRequestStatusApprovals = lsRequests => {
  return {
    type: lsRequestActions.STORE_LS_REQUEST_STATUS_APPROVALS,
    lsRequests,
  };
};

// Store nextApi and prevApi for pagination
export const storePaginationNext = nextApi => {
  return { type: lsRequestActions.STORE_PAGINATION_NEXT, nextApi };
};

export const storePaginationPrev = prevApi => {
  return { type: lsRequestActions.STORE_PAGINATION_PREV, prevApi };
};

//Action to store search result count
export const storeSearchResultCount = count => {
  return { type: lsRequestActions.STORE_SEARCH_RESULT_COUNT, count };
};

export const fetchOneLsr = (ls, callback) => {
  return { type: lsRequestActions.STORE_SINGLE_LS, ls, callback };
};

// Action for storing the target ls request filter by ls number
export const storeTargetLsRequest = target => {
  return { type: lsRequestActions.STORE_TARGET_LS_REQUEST, target };
};

// Action for checking whether update is successfully or not
export const updateTargetLsRequestStatus = status => {
  return { type: lsRequestActions.UPDATE_TARGET_LS_REQUEST_STATUS, status };
};

// Action for updating the target ls request
export const updateTargetLsRequest = ls => {
  return { type: lsRequestActions.UPDATE_TARGET_LS_REQUEST, ls };
};

// Action for saving search fields
export const saveDocSearchFields = fields => {
  return { type: lsRequestActions.SAVE_LS_DOC_SEARCH_FIELDS, fields };
};

export const saveSearchFields = fields => {
  return { type: lsRequestActions.SAVE_LS_SEARCH_FIELDS, fields };
};

export const saveLegalMemoSearch = shouldSearchLegalMemos => {
  return {type: lsRequestActions.SHOULD_SEARCH_LEGAL_MEMOS, shouldSearchLegalMemos}
}
