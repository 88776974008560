import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import File from '../library/File';
import ProcRequestTile from './ProcRequestTile';
import ProcRequestFields from '../../services/ProcRequestFields';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import * as actions from '../../actions/procRequestAction';
import { formatDate, truncateMiddle } from '../../utils/helper';
import styles from '../../styles/report-tracking/DueReportsDetails.module.scss';
import {
  CloseButtonNormalIcon,
  ArrowCircleFillLeftIcon,
  BoxAndArrowIcon,
} from '../../services/SvgLibrary';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { useParams } from 'react-router-dom';

const ProcRequestsForDay = props => {
  const [procRequestDetail, setProcRequestDetail] = useState({});

  const {
    selectedDayProcRequests,
    changeProcRequestDisplay,
    selectedProcRequest,
    allProcRequests,
    shownStatuses,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    if (selectedDayProcRequests.length === 1) {
      changeProcRequestDisplay('detail');
      setProcRequestDetail({ ...selectedProcRequest });
    }
  }, [selectedDayProcRequests, selectedProcRequest, changeProcRequestDisplay]);

  const onTileClick = procRequest => {
    props.selectProcRequest(procRequest);
    props.changeProcRequestDisplay('detail');
  };

  // Generate the procRequest details
  const displayProcRequestDetail = procRequestFields => {
    let procRequestDetail = {};
    if (props.selectedDayProcRequests.length === 0) return;

    if (props.selectedDayProcRequests.length === 1) {
      procRequestDetail = { ...props.selectedDayProcRequests[0] };
    } else {
      procRequestDetail = { ...props.selectedProcRequest };
    }

    const details =
      procRequestDetail &&
      procRequestFields.map((field, idx) => {
        // Display a list of committees
        if (field.field === 'caucuses' || field.field === 'boroughs')
          return null;
        else if (field.field === 'co_signers') {
          const cosigners = props.users
            .filter(ele => procRequestDetail.co_signers.includes(ele.id))
            .map(ele => ele.full_name)
            .join(', ');
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{cosigners.length ? cosigners : 'None'}</div>
            </div>
          );
        }
        // Display a list of assigned contacts
        else if (field.field.includes('_contact')) {
          const arr = procRequestDetail[field.field];
          let contactLength = arr.length - 1;
          let links = arr.map((contact, idx) => {
            return (
              <span key={idx}>
                <a
                  href={`/staff/${contact.id}`}
                  style={{
                    textDecoration: 'none',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '0.2rem',
                  }}>
                  {contact.full_name} <BoxAndArrowIcon width='12' height='12' />
                </a>
                {contactLength === idx ? '' : ', '}
              </span>
            );
          });
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{links}</div>
            </div>
          );
        } else if (
          field.field === 'requested_by' ||
          field.field === 'council_member'
        ) {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{procRequestDetail[field.field].full_name}</div>
            </div>
          );
        } else if (field.field === 'contact_person') {
          const arr = procRequestDetail[field.field];
          let links = arr.map((contact, idx) => {
            return (
              <a
                className={styles['proclamation-contact-person']}
                href={`${window.location.origin}/staff/${
                  contact.user_profile ? contact.user_profile : contact.id
                }`}
                key={idx}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `${window.location.origin}/staff/${
                      contact.user_profile ? contact.user_profile : contact.id
                    }`
                  );
                }}>
                {contact.full_name} <BoxAndArrowIcon width='12' height='12' />
              </a>
            );
          });
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{links}</div>
            </div>
          );
        } else if (field.type === 'file') {
          let files;
          if (procRequestDetail[field.field]) {
            files = procRequestDetail[field.field];
          } else {
            files = [];
          }

          return (
            <div key={idx}>
              <p>{field.title}</p>
              {files.map((file, index) => (
                <File
                  file={file}
                  fileName={file['file_name'] ? truncateMiddle(file['file_name'], 25) : null}
                  id={`proc_request_${field.field}-${index}`}
                  getFileAndDownload={props.getFileAndDownload}
                  downloadStatus={props.downloadStatus}
                  key={index}
                />
              ))}
              {procRequestDetail[field.field].length >= 1 ? '' : 'None'}
            </div>
          );
        } else if (typeof procRequestDetail[field.field] === 'boolean') {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{procRequestDetail[field.field] ? 'True' : 'False'}</div>
            </div>
          );
        } else if (field.type === 'date') {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>
                {procRequestDetail[field.field]
                  ? formatDate(procRequestDetail[field.field])
                  : 'None'}
              </div>
            </div>
          );
        } else if (field.field === 'notes') {
          procRequestDetail[field.field] = procRequestDetail[field.field]
            ? procRequestDetail[field.field]
            : 'None';
          if (!rolesUnderCouncilMembers.includes(props.userProfile.role)) {
            return (
              <div key={idx}>
                <p>{field.title}</p>
                <div>{procRequestDetail[field.field]}</div>
              </div>
            );
          } else {
            return null;
          }
        } else if (
          field.field === 'other' &&
          procRequestDetail[field.field] === ''
        ) {
          return null;
        } else {
          procRequestDetail[field.field] = procRequestDetail[field.field]
            ? procRequestDetail[field.field]
            : 'None';
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{procRequestDetail[field.field]}</div>
            </div>
          );
        }
      });

    return <div className={styles['report-field-column']}>{details}</div>;
  };

  // Get the target due procRequests on selected day
  // const { selectedDayProcRequests, allProcRequests, shownStatuses } = props;
  const keyMap = {
    'Approved': 'approved',
    'Completed': 'completed',
    'Delivered': 'delivered',
    'In-Progress': 'in-progress',
    'Pending': 'pending',
    'Withdrawn': 'withdrawn',
  };
  const filteredSelectedDayProcRequests = selectedDayProcRequests.filter(
    ele => shownStatuses[keyMap[ele.status]]
  );
  let procRequests = props.selectedDay
    ? allProcRequests[props.selectedDay.getDate()]
    : selectedDayProcRequests;
  const procRequestsList =
    filteredSelectedDayProcRequests &&
    procRequests
      .filter(ele => shownStatuses[keyMap[ele.status]])
      .map((procRequest, idx) => {
        return (
          <ProcRequestTile
            key={idx}
            status={props.selectedDay ? procRequest[0] : null}
            procRequest={procRequest}
            onTileClick={onTileClick}
          />
          //  {idx !== procRequests.length-1 && <hr style={{width:'90%'}}/>}
        );
      });

  let halfOfFields = ProcRequestFields.length / 2;
  let firstHalfOfFields = ProcRequestFields.slice(0, halfOfFields);
  let secondHalfOfFields = ProcRequestFields.slice(halfOfFields);

  // Generate the content in the popup window
  // By default it will be a list of proc requests with title and committees
  // Once the end users click the title, it will change to the details of that proc requests
  return (
    <>
      {props.currentDisplay === 'list' && (
        <div className={styles['modal-header']}>
          <h3 className='popup-detail-title'>Proclamation Requests List</h3>
          <CloseButtonNormalIcon
            classNameProp='popup-close-icon'
            onClickFunction={props.onCloseWindow}
            alt='close-icon'
          />
        </div>
      )}
      {props.currentDisplay === 'list' && (
        <div style={{ height: '50vh', overflow: 'auto', padding: '1rem' }}>
          {procRequestsList}
        </div>
      )}
      {props.currentDisplay === 'detail' && (
        <>
          <div className={styles['modal-header']}>
            {props.selectedDayProcRequests.length > 1 &&
              !(id && props.init) && (
                <button
                  className='back-icon'
                  onClick={() => {
                    if (props.currentMode === 'list') {
                      props.onBackToService();
                    } else {
                      props.changeProcRequestDisplay('list');
                    }
                  }}>
                  <ArrowCircleFillLeftIcon /> <span>Back</span>
                </button>
              )}
            <h3 className='popup-detail-title'>Proclamation Request Detail</h3>
            <CloseButtonNormalIcon
              classNameProp='popup-close-icon'
              onClickFunction={props.onCloseWindow}
              alt='close-icon'
            />
          </div>
          <div className={styles['report-fields']}>
            {displayProcRequestDetail(firstHalfOfFields)}
            {displayProcRequestDetail(secondHalfOfFields)}
          </div>
          {rolesUnderCouncilMembers.includes(props.userProfile.role) ? (
            // props.userProfile.permissions.procRequest.change_procRequest &&
            <div className={styles['modal-footer']}>
              <div className={styles['modal-footer-button-container']}>
                <button
                  className={`submit-button ${styles['cancel-button']}`}
                  onClick={props.onCloseWindow}>
                  Cancel
                </button>
                <button
                  className={`submit-button ${styles['footer-button']}`}
                  onClick={() => {
                    props.changeProcRequestDisplay('update');
                  }}>
                  Update Request
                </button>
              </div>
              <span>
                To upload additional documents, update the proclamation request
              </span>
            </div>
          ) : (
            <div className={styles['modal-footer']}>
              <div className={styles['modal-footer-button-container']}>
                <button
                  className={`submit-button ${styles['cancel-button']} ${
                    props.isLoading ? styles['disabled'] : ''
                  }`}
                  onClick={() => {
                    props.updateProcRequest({
                      ...props.selectedProcRequest,
                      is_archived: true,
                    });
                  }}>
                  Delete Request
                </button>
                <button
                  className={`submit-button ${styles['footer-button']}`}
                  onClick={() => {
                    props.changeProcRequestDisplay('update');
                  }}>
                  Update Request
                </button>
              </div>
              <span>
                To upload additional documents, update the proclamation request
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    allProcRequests: state.procRequestReducer.allProcRequestsForDays,
    selectedDayProcRequests: state.procRequestReducer.selectedDayProcRequests,
    selectedProcRequest: state.procRequestReducer.selectedProcRequest,
    downloadStatus: state.fileDownloadReducer.downloadStatus,
    currentDisplay: state.procRequestReducer.currentDisplay,
    userProfile: state.userReducer.userProfile,
    users: state.userReducer.userList,
    isLoading: state.procRequestReducer.isLoading,
  };
};

const mapDispatchToProps = {
  ...actions,
  getFileAndDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcRequestsForDay);
