import React, { useState, useRef, useCallback } from 'react';
import { FileUploadIcon, TrashIcon } from '../../services/SvgLibrary';
import { acceptedFileTypes } from '../../utils/helper';
import styles from '../../styles/report-tracking/Agency.module.scss';

const MiniFileUpload = ({ value, single, onChange, }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const dropRef = useRef(null);

  const handleFiles = useCallback(
    files => {
      const file = files[0]; // Only take the first file
      if (file) {
        onChange({
          target: {
            value: file.name,
            files: [file],
          },
        });
      }
    },
    [onChange]
  );

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) setIsDragging(true);
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    if (dropRef.current && !dropRef.current.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };

  const onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = e => {
    const { files } = e.target;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const handleDelete = event => {
    event.stopPropagation();
    onChange({
      target: {
        value: '',
        files: [],
      },
    });
  };

  return (
    <div className={styles['mini-upload-area']}>
      <div
        ref={dropRef}
        className={`${styles['mini-drop-zone']} ${
          isDragging ? styles['dragging'] : ''
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}>
        <FileUploadIcon width='24' height='24' />
        <div className={styles['upload-text']}>
          <span className={styles['file-name']}>
            {value.length > 0 && !isDragging && value[0].name}
            {value.length === 0 && !isDragging && 'Drag & drop or click to upload'}
            {isDragging && 'Drop file here'}
          </span>
        </div>
        {value.length > 0 && single &&(
          <button
            type='button'
            className={styles['delete-btn']}
            onClick={handleDelete}>
            <TrashIcon width='20' height='20' />
          </button>
        )}
      </div>
      <input
        ref={fileInputRef}
        type='file'
        className={styles['file-input']}
        onChange={onFileChange}
        accept={acceptedFileTypes}
      />
    </div>
  );
};

export default MiniFileUpload;
