import React, { useState, useEffect } from 'react';
import styles from '../../styles/ls-request/CreateNewLsRequest.module.scss';
import {
  createNewLsRequest,
  saveLSProgress,
} from '../../actions/lsRequestAction';
import { connect } from 'react-redux';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { acceptedFileTypes, getCMorRelated } from '../../utils/helper';
import { LsFields } from '../../services/LsFields';
import UserSelect from '../user/UserSelect';
import ToolTip from '../library/ToolTip';
import File from '../library/File';
import {
  cmNonCreateFields,
  nonLegDivisionRoles,
} from '../../services/constants';
import {
  clearLsRequestArray,
  loadLsRequests,
} from '../../actions/lsRequestAction';
import { ExclamationPointDiamondIcon } from '../../services/SvgLibrary';

const CreateNewLsRequest = props => {
  const emptyLSR = {
    attachment_descriptions: '',
    background_existing_laws: '',
    background_inspiration: '',
    bill_matrix: [],
    bill: [],
    cm_attachment: [],
    cm_notes: '',
    committee: '',
    confidential_comments: '',
    contact_person: '',
    contact_person_focus: false,
    coversheet: [],
    description_legislative_solution: '',
    description_related_discussions: '',
    description_problem: '',
    division: '',
    first_in_time: '',
    first_prime: '',
    first_prime_focus: false,
    hearing_report: [],
    hearing_summary: [],
    legal_memo: [],
    ls_number: '',
    ls_type: '',
    make_first_prime_public: false,
    matters: [],
    plain_language_summary: [],
    prev_sess_ls_number: null,
    reintroduction: '',
    resource_letter: [],
    staff: '',
    staff_focus: false,
  };

  const [ls, setLs] = useState(emptyLSR);
  const [curtPart, setCurtPart] = useState(0);
  const [errorField, setErrorField] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [inspirationType, setInspirationType] = useState('Select Type');
  const [lsCreationFields, setLsCreationFields] = useState([]);

  /**
   * useEffect hook for component mount
   * Save only fields that can be edited during the creation
   */
  useEffect(() => {
    // Leave EventListener in case number of number of pages increases
    // document.getElementsByClassName(styles["next-button"])[0].addEventListener("click", this.scrollToTheTop);
    // document.getElementsByClassName(styles["back-button"])[0].addEventListener("click", this.scrollToTheTop);
    let fields = [];
    const nonLeg = nonLegDivisionRoles.includes(props.userProfile.role);
    for (let i = 0; i < LsFields.length; i++) {
      if (
        LsFields[i].create &&
        !(
          (nonLeg && LsFields[i].viewability === 'staffOnly') ||
          cmNonCreateFields.includes(LsFields[i].field)
        )
      ) {
        if (LsFields[i].field === 'committee') {
          const committees = props.committeeList.map(
            committee => committee.committee_name
          );
          LsFields[i].options = ['Select a Committee', ...committees];
        }
        fields.push(LsFields[i]);
      }
    }
    setLsCreationFields(fields);

    if (props.savedProgress) {
      setLs(props.savedProgress);
    } else if (props.subLS && props.updateTargetLs) {
      //Reusing this for sub ls
      setLs({
        ...ls,
        ...{
          'ls_number': props.updateTargetLs.ls_number,
          'contact_person': props.updateTargetLs.contact_person,
        },
      });
    }

    // component will unmount
    return () => {
      props.saveLSProgress(ls);
    };
  }, []);

  /* function scrollToTheTop () {
    document.getElementsByClassName(styles["new-ls-form-modal-body"])[0].scrollTo(0,0);
  }

  function onRefreshTable (){
    props.clearLsRequestArray();
    props.loadLsRequests(null, null, '-ls_number');
    props.onClose();
  } */

  const displayAllErrorFields = () => {
    if (errorMessage.length > 1) {
      let quoteErrors = errorMessage.slice(0, -1);
      quoteErrors = quoteErrors.map(error => {
        return (
          <>
            "<strong>{error}</strong>",
          </>
        );
      });
      return (
        <>
          {quoteErrors}and "<strong>{errorMessage.slice(-1)}</strong>"
        </>
      );
    } else {
      return (
        <>
          "<strong>{errorMessage[0]}</strong>"
        </>
      );
    }
  };

  // Sending out http request for creating a new ls request
  // Clear up the state once the http was send out
  function onSubmit(e) {
    e.preventDefault();
    let { userList, committeeList, createNewLsRequest, onClose } = props;
    // Creates a new lsrequest object with ids istead of strings for the fks
    let first_prime = ls.first_prime
      ? userList.find(user => user.full_name === ls.first_prime)?.id
      : getCMorRelated(props.userProfile);
    let staff = ls.staff
      ? userList.find(user => user.full_name === ls.staff)?.id
      : null;
    let contact_person = ls.contact_person
      ? userList.find(user => user.full_name === ls.contact_person)?.id
      : null;
    let committee = ls.committee
      ? committeeList.find(
          committee => committee.committee_name === ls.committee
        )?.id
      : null;
    let background_inspiration = `${inspirationType}: ${ls.background_inspiration}`;
    let lsrequest = Object.assign({}, ls, {
      first_prime: first_prime,
      staff: staff,
      contact_person: contact_person,
      committee: committee,
      background_inspiration: background_inspiration,
      cm_attachment: ls.cm_attachment,
    });

    let correctInput = checkValidation(lsCreationFields, {}, true);

    if (correctInput) createNewLsRequest(lsrequest);
  }

  function checkValidation(lsArr, errors, correctInput) {
    const users = props.userList.map(user =>
      user.full_name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
    );
    let errorArray = [];
    for (let i = 0; i < lsArr.length; i++) {
      let value = ls[lsArr[i].field];
      if (
        lsArr[i].required &&
        (value === '' || (Array.isArray(value) && value.length === 0))
      ) {
        correctInput = false;
        errorArray.push(lsArr[i].title);
        errors[lsArr[i].field] = `Required fields (*) cannot be empty!`;
      } else if (
        lsArr[i].required &&
        ['first_prime', 'staff'].includes(lsArr[i].field) && //If the current field is in the array
        !users.includes(value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')) //If the sanitized value is not in the sanitized user list
      ) {
        correctInput = false;
        errorArray.push(lsArr[i].title);
        errors[lsArr[i].field] = `Not an accepted user!`;
      } else {
        if (errorField[lsArr[i].field]) {
          errors[lsArr[i].field] = '';
        }
      }
    }
    setErrorField(errors);
    setErrorMessage(errorArray);
    return correctInput;
  }

  function onDeleteNewAttachment(e, field, id) {
    setLs({ ...ls, [field]: ls[field].filter((f, index) => index !== id) });
    e.stopPropagation();
  }

  const generateForm = fields => {
    return fields
      .filter(lsReq => lsReq.create)
      .map((lsReq, idx) => {
        const title = lsReq.required ? (
          <>
            {lsReq.title} <span style={{ color: '#DC6C6C' }}>*</span>
          </>
        ) : (
          lsReq.title
        );
        if (lsReq.type === 'checkbox') {
          // Only the Confidentiality Waiver field
          return (
            <div className='form-check' key={idx}>
              <input
                className={`form-check-input ${styles['confirm']}`}
                type='checkbox'
                value=''
                id='formCheckbox'
                key={lsReq.title}
                checked={ls[lsReq.field]}
                onChange={e => {
                  setLs({ ...ls, [lsReq.field]: !ls[lsReq.field] });
                }}
              />
              <label className='form-check-label' htmlFor='formCheckbox'>
                {title}
                {lsReq.field === 'make_first_prime_public' && (
                  <>
                    <br />
                    <small>
                      <sup>*</sup> Checking the box permits sharing your
                      sponsorship and LSR information with other Council Members
                      who have a similar LSR for the purpose of facilitating
                      co-sponsorship.
                    </small>
                  </>
                )}
              </label>
              {errorField[lsReq.field] && (
                <p className={styles['warning-message']}>
                  {errorField[lsReq.field]}
                </p>
              )}
            </div>
          );
        } else if (lsReq.type !== 'file' && lsReq.type !== 'custom') {
          // The Description and Background fields, LSR Type, Op Req
          return (
            <React.Fragment key={idx}>
              {lsReq.field === 'description_problem' && <h5>Description</h5>}
              {lsReq.field === 'background_inspiration' && <h5>Background</h5>}
              <div className='mb-3' style={{ position: 'relative' }}>
                <label className={`form-label ${styles['input-form-title']}`}>
                  {title}
                </label>
                {lsReq.help &&
                  !(
                    lsReq.field === 'description_problem' ||
                    lsReq.field === 'description_legislative_solution' ||
                    lsReq.field === 'background_inspiration' ||
                    lsReq.field === 'description_related_discussions'
                  ) && (
                    <ToolTip
                      id={lsReq.field}
                      target={lsReq.field}
                      text={
                        lsReq.help && typeof lsReq.help === 'object'
                          ? lsReq.help[inspirationType]
                          : lsReq.help
                      }
                    />
                  )}
                {lsReq.field === 'background_inspiration' && (
                  <select
                    className='form-select field-size'
                    value={inspirationType}
                    onChange={e => {
                      setInspirationType(e.target.value);
                    }}>
                    <option value='Select Type' hidden>
                      Select Type
                    </option>
                    <option value='News or law review article'>
                      A news or law review article
                    </option>
                    <option value='Hearing'>A Hearing</option>
                    <option value='Constituent complaint'>
                      Constituent Complaint
                    </option>
                    <option value='Other'>Other</option>
                  </select>
                )}
                {lsReq.type === 'select' && (
                  <select
                    className='form-control form-control-lg field-size'
                    id={`select-${lsReq.title}`}
                    value={ls[lsReq.field]}
                    onChange={e => {
                      if (lsReq.field === 'reintroduction') {
                        setLs({
                          ...ls,
                          [lsReq.field]:
                            e.target.value === 'true'
                              ? true
                              : e.target.value === 'false'
                              ? false
                              : e.target.value,
                        });
                      } else {
                        setLs({ ...ls, [lsReq.field]: e.target.value });
                      }
                    }}>
                    {lsReq.options &&
                      lsReq.options.map((op, idx1) => {
                        if (
                          [
                            'Select a Committee',
                            'Select a Division',
                            'Select an LSR Type',
                            'Select a Status',
                            'Select one',
                          ].includes(op)
                        ) {
                          return (
                            <option key={idx1} value={null} hidden>
                              {op}
                            </option>
                          );
                        }
                        if (lsReq.field === 'reintroduction') {
                          return (
                            <option
                              key={idx1}
                              value={
                                op === 'N/A'
                                  ? 'None'
                                  : op === 'Yes'
                                  ? true
                                  : false
                              }>
                              {op}
                            </option>
                          );
                        }
                        return <option key={idx1}>{op}</option>;
                      })}
                  </select>
                )}
                {lsReq.type === 'textarea' && (
                  <textarea
                    className='form-control form-control-lg field-size'
                    rows='6'
                    value={ls[lsReq.field]}
                    onChange={e => {
                      setLs({ ...ls, [lsReq.field]: e.target.value });
                    }}
                    disabled={
                      lsReq.field === 'background_inspiration' &&
                      inspirationType === 'Select Type'
                    }
                    placeholder={
                      typeof lsReq.help === 'object'
                        ? lsReq.help[inspirationType]
                        : lsReq.help
                    }
                  />
                )}
                {lsReq.type !== 'select' && lsReq.type !== 'textarea' && (
                  <input
                    className='form-control form-control-lg field-size'
                    type={lsReq.type}
                    value={ls[lsReq.field]}
                    onChange={e => {
                      setLs({ ...ls, [lsReq.field]: e.target.value });
                    }}
                    placeholder={
                      typeof lsReq.help === 'object'
                        ? lsReq.help[inspirationType]
                        : lsReq.help
                    }
                  />
                )}
                {errorField[lsReq.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[lsReq.field]}
                  </p>
                )}
              </div>
            </React.Fragment>
          );
        } else if (lsReq.type === 'custom') {
          // Only the First Prime/Sponsor field
          return (
            <div className='mb-3' key={idx} style={{ position: 'relative' }}>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}
              </label>
              <input
                type='text'
                className='form-control form-control-lg field-size'
                value={ls[lsReq.field]}
                key={lsReq.title}
                onChange={(e) => {setLs({...ls, [lsReq.field]: e.target.value})}}
                onFocus={() => {
                  setLs({ ...ls, [`${lsReq.field}_focus`]: true });
                }} 
                onBlur={(e) => { e.target.value = '' }}
                placeholder='Type and search in dropdown list'
              />
              <UserSelect
                search={ls[lsReq.field]}
                typeOfUsers={
                  lsReq.field === 'contact_person'
                    ? 'cm_staff'
                    : 'council members'
                }
                handleClick={e => {
                  if (lsReq.field === 'first_prime') {
                    setLs({
                      ...ls,
                      [lsReq.field]: e.target.innerText,
                      contact_person: '',
                      [`${lsReq.field}_focus`]: false,
                    });
                  } else {
                    setLs({
                      ...ls,
                      [lsReq.field]: e.target.innerText,
                      [`${lsReq.field}_focus`]: false,
                    });
                  }
                }}
                sponsor={
                  ls['first_prime'] ||
                  (props.userProfile?.under_council_member &&
                    props.userList?.find(
                      user => user.id === props.userProfile.under_council_member
                    ).full_name) ||
                  ((props.userProfile?.role.includes('Council Member') ||
                    props.userProfile?.role === 'Public Advocate') &&
                    props.userProfile?.full_name)
                }
                focused={ls[`${lsReq.field}_focus`]}
              />
              {errorField[lsReq.field] && (
                <p className={styles['warning-message']}>
                  {errorField[lsReq.field]}
                </p>
              )}
            </div>
          );
        } else if (lsReq.type === 'file') {
          // Only the CM Attachment field
          return (
            <div className='mb-3' key={idx} style={{ position: 'relative' }}>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}
              </label>
              <div className={styles['file-input-area']}>
                <input
                  className={`form-control form-control-lg field-size ${styles['file-input-box']}`}
                  type='file'
                  accept={acceptedFileTypes}
                  key={lsReq.title}
                  onChange={e => {
                    let error_messages = [];

                    for (let i = 0; i < e.target.files.length; i++) {
                      // If the file size is more than 100MB do not input
                      if (e.target.files[i].size > 100000000) {
                        error_messages.push(
                          e.target.files[i].name + ' is too large'
                        );
                      }
                    }
                    if (error_messages.length) {
                      e.target.value = null;
                    } else {
                      let files = e.target.files;
                      // let error = [];
                      for (let i = 0; i < files.length; i++) {
                        files[i].file_name = files[i].name;
                        // error.push(files[i].name + 'is too large'); // does nothing
                        let updatedField = [...ls[lsReq.field], files[i]];
                        setLs({ ...ls, [lsReq.field]: updatedField });
                      }
                    }
                  }}
                  multiple
                />
              </div>
              <br />
              {ls[lsReq.field] &&
                ls[lsReq.field].map((file, index) => (
                  <File
                    id={`lsr_${lsReq.field}-${index}`}
                    file={{ file: file, ...file }}
                    getFileAndDownload={e => getFileAndDownload(e)}
                    key={index}
                    onDelete={e => onDeleteNewAttachment(e, lsReq.field, index)}
                    deletePermission={true}
                  />
                ))}
              {errorField[lsReq.field] && (
                <p className={styles['warning-message']}>
                  {errorField[lsReq.field]}
                </p>
              )}
            </div>
          );
        }
        return null;
      });
  };

  const numOfFieldsPerPart = lsCreationFields.length;
  const { newCreateLsStatus, newCreateLsNumber } = props;
  const lsCreation = lsCreationFields.slice(
    curtPart * numOfFieldsPerPart,
    curtPart * numOfFieldsPerPart + numOfFieldsPerPart
  );
  const firstColumn = lsCreation.slice(0, Math.floor(numOfFieldsPerPart / 2));
  const secondColumn = lsCreation.slice(Math.floor(numOfFieldsPerPart / 2));

  return (
    <>
      {/* CREATE LSR MODAL */}
      <div
        className={`d-flex justify-content-between align-items-center p-3 border-bottom fs-5 fw-bolder 
        ${styles['new-ls-request-header']} ${styles['success']}`}>
        <span>{`Create ${props.subLS ? 'Sub' : ''} LS Request`}</span>
        <button
          type='button'
          className={`btn-close ${props.subLS ? 'p-2 rounded-3' : ''}`}
          onClick={props.onClose}
          aria-label='Close'></button>
      </div>
      <div
        className={`d-flex flex-column align-items-center overflow-auto p-4
          ${
            props.newReportStatus === 'fail' || errorMessage.length > 0
              ? `${styles['new-ls-form-modal-body']} ${styles['error-message-body']}`
              : styles['new-ls-form-modal-body']
          }`}>
        {/* FAIL MESSAGE */}
        {props.newReportStatus === 'fail' && (
          <div className={styles['error-message-container']}>
            <div
              className={`alert alert-danger ${styles['error-message']}`}
              role='alert'>
              <div>
                <ExclamationPointDiamondIcon />
              </div>
              <div>LS Request Creation Failed</div>
            </div>
          </div>
        )}
        {/* END FAIL MESSAGE */}
        {/* ERROR MESSAGE */}
        {errorMessage.length > 0 && (
          <div className={styles['error-message-container']}>
            <div
              className={`alert alert-danger ${styles['error-message']}`}
              role='alert'>
              <div>
                <ExclamationPointDiamondIcon />
              </div>
              <div>
                <div>
                  {errorMessage.length < 4 && (
                    <>
                      There was an error in the following field(s):
                      {displayAllErrorFields()}.
                    </>
                  )}
                  {errorMessage.length >= 4 && (
                    <>
                      Errors were found in
                      <strong style={{ letterSpacing: '1px' }}>
                        {' '}
                        {errorMessage.length}{' '}
                      </strong>
                      fields.
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* END ERROR MESSAGE */}
        {/* CREATE LSR FORM */}
        <form
          className={
            props.newReportStatus === 'fail' || errorMessage.length > 0
              ? `row w-100 ${styles['new-ls-request-form']} ${styles['error-message-body']}`
              : `row w-100 ${styles['new-ls-request-form']}`
          }>
          <div className='col'>{generateForm(firstColumn)}</div>
          <div className='col'>{generateForm(secondColumn)}</div>
        </form>
        {/* END CREATE LSR FORM */}
      </div>
      <div className='d-flex align-items-center justify-content-end border-top p-3'>
        <button
          type='button'
          className='cancel-button-text'
          onClick={props.onClose}>
          Cancel
        </button>
        <button
          type='button'
          className='submit-button'
          disabled={props.isLoading || newCreateLsStatus}
          onClick={e => onSubmit(e)}>
          {props.isLoading && (
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'></span>
          )}{' '}
          Submit
        </button>
      </div>
    </>
  );
};
{
  /* END CREATE LSR MODAL */
}
{
  /* END LSR SUCCESS */
}

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    committeeList: state.userReducer.committees,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    newCreateLsStatus: state.lsRequestsReducer.newCreateLsStatus,
    newCreateLsNumber: state.lsRequestsReducer.newCreateLsNumber,
    savedProgress: state.lsRequestsReducer.savedProgress,
    isLoading: state.lsRequestsReducer.isLoading,
  };
};

const mapDispatchToProps = {
  createNewLsRequest,
  saveLSProgress,
  clearLsRequestArray,
  loadLsRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLsRequest);
