import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { url } from '../config';
import { eventActions, saveAllEvents } from '../actions/eventAction';

function getAllEvents() {
  return axios.get(url + '/api/event/event/');
}

function* getAllEventsResponse(action) {
  if (action !== undefined) {
    try {
      // Get the response of certain month and year
      const response = yield call(getAllEvents);
      const { data } = response;
      yield put(saveAllEvents(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function createEvent(formData) {
  return axios.post(url + '/api/event/event/', formData);
}

function* createEventResponse(action) {
  if (action !== undefined) {
    try {
      // Get the response of certain month and year
      const response = yield call(createEvent, action.formData);

      // Check if the request was successful
      if (response.status >= 200 && response.status < 300) {
        alert('Event has been created successfully');
        window.location.assign('/');
      } else {
        // Handle client and server errors
        alert(
          `Event has not been created. Status code: ${response.status}. Please check that you provided all the necessary data and try again`
        );
      }
      // yield put.resolve(saveAllEvents(data));
    } catch (e) {
      // Handle network errors or other unexpected errors
      console.log(e);
      alert('An error occurred while creating the event. Please try again.');
    }
  }
}

function* eventAPI() {
  yield takeEvery(eventActions.GET_ALL_EVENTS, getAllEventsResponse);
  yield takeEvery(eventActions.CREATE_EVENT, createEventResponse);
}
export default eventAPI;
