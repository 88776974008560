import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTargetFiles,
  getNextResults,
  delay,
  addToastInfo,
} from '../utils/helper';
import axios from 'axios';
import { url } from '../config';
import {
  reportTrackingActions,
  saveReportsDueCurrentMonthAndYear,
  saveTargetDueReports,
  changeReportTrackingPopupWindow,
  creatingNewReportDeadlineStatus,
  isLoadingStatus,
  getReportUpdateStatus,
  saveAllReports,
  saveAllAgencies,
  saveAllAgencyUsers,
  addMoreReports,
  saveProgress,
  saveNextAPI,
  setMessage,
} from '../actions/reportTrackingAction';

// HTTP.GET to get reports due selected month and year
// Will return a list of objects
// Assume for each month we will have 31 days
function sendGetDueReportsRequest(month, year) {
  return axios.get(url + '/api/reports/reportsByDate/', {
    params: { month: month, year: year },
  });
}
function uploadReportRequest(reportID, attachment, dueDate) {
  return axios.patch(
    `${url}/api/reports/report/${reportID}/${
      dueDate ? '?dueDate=' + dueDate : ''
    }`,
    attachment
  );
}

// function for capitalizing the first character
function capitalizeFirstChar(str) {
  return str.toString().charAt(0).toUpperCase() + str.toString().slice(1);
}

// function for saving the static form data for report
function saveReportStaticData(report) {
  const formData = new FormData();

  formData.append('title', report.title.trim());
  formData.append('tracker_type', report.tracker_type.toLowerCase());
  formData.append(
    'submitting_agency',
    report.submitting_agency.map(a => a.name).join(',')
  );
  formData.append('division', report.division);
  formData.append('committees', report.committees.map(c => c.id).join());
  formData.append(
    'assigned_contact',
    report.assigned_contact.map(c => c.id).join()
  );
  formData.append(
    'agency_contact',
    report.agency_contact.map(c => c.id).join()
  );
  formData.append(
    'local_law',
    report.local_law.length === 0
      ? []
      : report.local_law.map(law => JSON.stringify(law)).join(' , ')
  );
  formData.append(
    'legacy_local_law',
    report.legacy_local_law.length === 0
      ? []
      : report.legacy_local_law.map(law => JSON.stringify(law)).join(' , ')
  );
  formData.append('notes_on_due_date', report.notes_on_due_date);
  formData.append('start_date', report.start_date || '');
  formData.append('general_notes', report.general_notes);
  formData.append(
    'ls_requests',
    report.ls_requests.map(c => parseInt(c.ls_number)).join() || []
  );
  if (report.tracker_type.toLowerCase() === 'report') {
    formData.append('frequency_quantity', report.frequency_quantity);
    formData.append(
      'frequency_unit',
      report.frequency_unit.toLowerCase().replace(' ', '_')
    );
    formData.append('end_date', report.end_date || '');
    formData.append('next_due', report.next_due || '');
    formData.append('last_received', report.last_received || '');
    formData.append('errors', report.errors);
    if (report.current_report_past_due !== undefined) {
      formData.append(
        'current_report_past_due',
        capitalizeFirstChar(report.current_report_past_due)
      );
    }
  } else if (report.tracker_type.toLowerCase() === 'milestone') {
    if (report.completed !== undefined) {
      formData.append('completed', capitalizeFirstChar(report.completed));
    }
    formData.append('compliance_notes', report.compliance_notes);
  }

  return formData;
}

function saveMilestoneConfirmStaticData(confirm) {
  const formData = new FormData();

  formData.append('milestone', confirm.milestone);
  formData.append('confirm_name', confirm.confirmName);
  formData.append('confirm_title', confirm.confirmTitle);
  formData.append('confirm_agency', confirm.confirmAgency);
  formData.append('confirm_email', confirm.confirmEmail);
  formData.append('confirm_date', confirm.confirmDate);
  formData.append('confirm_notes', confirm.confirmNotes);

  return formData;
}

// function for finish the promise of zipping the file
function* saveFileData(report, formData) {
  const report_files = yield call(getTargetFiles, report.attachment);
  if (report_files) {
    report_files.forEach((file, idx) => {
      if (idx) {
        formData.append(`attachment-${idx}`, file);
      } else {
        formData.append('attachment', file);
      }
    });
  } else {
    formData.append('attachment', '');
  }
}

// function for finish the promise of zipping the file
function* saveMilestoneFileData(report, formData) {
  const report_files = yield call(getTargetFiles, report.milestone_attachment);
  if (report_files) {
    report_files.forEach((file, idx) => {
      if (idx) {
        formData.append(`milestone_attachment-${idx}`, file);
      } else {
        formData.append('milestone_attachment', file);
      }
    });
  } else {
    formData.append('milestone_attachment', '');
  }
}

// HTTP.POST for creating a new report
function sendCreateNewReportRequest(report) {
  return axios.post(url + '/api/reports/report/', report);
}

// HTTP.PUT for updating an existing report
function sendUpdateReportRequest(report, id, dueDate) {
  return axios.put(
    `${url}/api/reports/report/${id.toString()}/${
      dueDate ? '?dueDate=' + dueDate : ''
    }`,
    report
  );
}

// HTTP.GET for getting all the reports
function getAllReportsRequest(tasks) {
  let isInTasks = {};
  isInTasks['tasks'] = tasks;
  return axios.get(url + '/api/reports/report/', { params: isInTasks });
}
function getSingleReport(reportID) {
  return axios.get(`${url}/api/reports/report/${reportID}/`);
}
function deleteAttachmentRequest(reportID) {
  return axios.delete(`${url}/api/reports/deleteAttachment/${reportID}/`);
}

function deleteMilestoneAttachmentRequest(reportID) {
  return axios.delete(
    `${url}/api/reports/deleteMilestoneAttachment/${reportID}/`
  );
}

function createMilestoneConfirmationRequest(data) {
  return axios.post(`${url}/api/reports/milestoneConfirm/`, data)
}

// Creates Milestone Confirmations
function* createMilestoneConfirmation(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const formData = yield call(saveMilestoneConfirmStaticData, action.confirm);
      if (
        Array.isArray(action.confirm.milestone_attachment) &&
        action.confirm.milestone_attachment[0] !== undefined
      ) {
        yield call(saveMilestoneFileData, action.confirm, formData);
      }
      yield call(
        createMilestoneConfirmationRequest,
        formData,
      );
      yield put(isLoadingStatus(false));
      yield put(
        setMessage('Milestone successfully completed. Page will refresh shortly.')
      );
      window.location.reload()
    } catch (e) {
      yield put(isLoadingStatus(false));
      yield put(
        setMessage('Error: ' + e.response.data)
      );
      console.log(e);
    }
  }
}

// HTTP.GET for getting search reports
function getSearchReportsRequest(
  sortBy,
  searchCommittee,
  searchTitle,
  searchDivision,
  searchAgency,
  searchLaw,
  searchAll,
  filter = {},
  tasks,
  paginated
) {
  let param = {};
  if (searchCommittee.length > 0)   param['committee'] = searchCommittee;
  if (searchTitle.length > 0)       param['title'] = searchTitle;
  if (searchAgency.length > 0)      param['agency'] = searchAgency;
  if (searchDivision.length > 0)    param['division'] = searchDivision;
  if (searchLaw.length > 0)         param['local_law'] = searchLaw;
  if (searchAll.length > 0)         param['keyword'] = searchAll;
  if (sortBy.length > 0)            param['sortBy'] = sortBy;
  if (tasks)                        param['tasks'] = tasks;
  param['filter'] = JSON.stringify(filter);
  param['paginated'] = paginated
  return axios.get(url + '/api/reports/report/', { params: param });
}

// HTTP.GET for getting all agencies
function getAllAgenciesRequest() {
  return axios.get(url + '/api/reports/agencies/');
}

// HTTP.GET for getting all agency user
function getAllAgencyUsers() {
  return axios.get(url + '/api/reports/agencyUser/');
}

function agencyNewReportRequest(data) {
  return axios.post(url + '/api/reports/requestedReports/', data);
}

// Get the due reports and save them in the redux store
function* getDueReportsResponse(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      // Get the response of certain month and year
      const response = yield call(
        sendGetDueReportsRequest,
        action.month,
        action.year
      );
      const { data } = response;
      yield put(saveReportsDueCurrentMonthAndYear(data));
      yield put(isLoadingStatus(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Save the due reports on selected date
// Save the data into the redux store before open the service winodw
function* saveSelectedDueReports(action) {
  if (action !== undefined) {
    try {
      yield put(changeReportTrackingPopupWindow(false));
      yield put(saveTargetDueReports(action.reports));
      yield put(changeReportTrackingPopupWindow(true));
    } catch (e) {
      yield put(changeReportTrackingPopupWindow(false));
      yield put(isLoadingStatus(false));
      console.log(e);
    }
  }
}

// Create a new report deadline
// Provides an object with all the parameter
function* createNewReportDdl(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const formData = yield call(saveReportStaticData, action.report);
      yield call(sendCreateNewReportRequest, formData);
      addToastInfo(
        'Operational Requirement Created',
        `Operational Requirement for ${action.report.title} has been created`,
        '/img/Navbar/report-tracking.png',
        'Operational Requirement icon'
      );
      yield put(saveProgress(null));
      yield put(creatingNewReportDeadlineStatus('success'));
      yield put(isLoadingStatus(false));
      yield call(delay);
      window.location.assign('/opReqs');
    } catch (e) {
      yield put(creatingNewReportDeadlineStatus('fail'));
      yield put(isLoadingStatus(false));
      console.log(e);
    }
  }
}

// Update an existing report
function* updateExistReport(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const formData = yield call(saveReportStaticData, action.report);

      if (
        Array.isArray(action.report.attachment) &&
        action.report.attachment[0] !== undefined
      ) {
        yield call(saveFileData, action.report, formData);
      }
      if (
        Array.isArray(action.report.milestone_attachment) &&
        action.report.milestone_attachment[0] !== undefined
      ) {
        yield call(saveMilestoneFileData, action.report, formData);
      }
      yield call(
        sendUpdateReportRequest,
        formData,
        action.report.id,
        action.dueDate
      );
      addToastInfo(
        'Operational Requirement Updated',
        `Operational Requirement for ${action.report.title} has been updated`,
        '/img/Navbar/report-tracking.png',
        'Operational Requirement icon'
      );
      yield put(getReportUpdateStatus('success'));
      yield put(isLoadingStatus(false));
      yield call(delay);
      window.location.assign('/opReqs');
    } catch (e) {
      yield put(getReportUpdateStatus('fail'));
      yield put(isLoadingStatus(false));
      console.log(e);
      console.log(e.message);
    }
  }
}

// Get all the reports
function* getAllReportsList(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const response = yield call(getAllReportsRequest, action.tasks);
      yield put(saveAllReports(response.data.results)); //Todo: load the other reports in the list view
      yield put(saveNextAPI(response.data.next));
      const response1 = yield call(getAllAgenciesRequest);
      yield put(saveAllAgencies(response1.data));
      const response2 = yield call(getAllAgencyUsers);
      yield put(saveAllAgencyUsers(response2.data));
      yield put(isLoadingStatus(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Get the reports based on input search committee or search keyword
function* getSearchReportList(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const response = yield call(
        getSearchReportsRequest,
        action.sortBy,
        action.searchCommittee,
        action.searchTitle,
        action.searchDivision,
        action.searchAgency,
        action.searchLaw,
        action.searchAll,
        action.filter,
        action.tasks,
        action.paginated
      );
      yield put(saveAllReports(response.data.results));
      yield put(saveNextAPI(response.data.next));
      yield put(isLoadingStatus(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* openReportForNotification(action) {
  if (action !== undefined) {
    try {
      yield put(changeReportTrackingPopupWindow(false));
      // Put report tracking loading status
      yield put(isLoadingStatus(true));
      // Save the selected report in notifications list
      const response = yield call(getSingleReport, action.reportID);
      const report = response.data;
      yield put(saveTargetDueReports([report]));
      // Open the window
      yield put(changeReportTrackingPopupWindow(true));
      // Finish loading process
      yield put(isLoadingStatus(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Agency Report Upload
function* submitAgencyReport(action) {
  if (action !== undefined) {
    try {
      const formData = new FormData();
      yield put(isLoadingStatus(true));
      yield call(saveFileData, { attachment: action.attachment }, formData);
      yield call(
        uploadReportRequest,
        action.reportID,
        formData,
        action.dueDate
      );
      yield put(isLoadingStatus(false));
      window.location.reload()
    } catch (e) {
      setMessage('Error: ' + e.response.data)
      yield put(isLoadingStatus(false));
      console.log(e);
    }
  }
}

function* requestNewReport(action) {
  if (action !== undefined) {
    try {
      yield call(agencyNewReportRequest, action.data);
      yield put(setMessage('Report request have been successfully submitted'));
    } catch (e) {
      yield put(setMessage(e.message));
      console.log(e);
    }
  }
}
function* loadNextReports(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingStatus(true));
      const response = yield call(getNextResults, action.url);
      yield put(addMoreReports(response.data.results));
      yield put(saveNextAPI(response.data.next));
      yield put(isLoadingStatus(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* deleteAttachment(action) {
  if (action !== undefined) {
    const response = yield call(deleteAttachmentRequest, action.id);
  }
}

function* deleteMilestoneAttachment(action) {
  if (action !== undefined) {
    const response = yield call(deleteMilestoneAttachmentRequest, action.id);
  }
}

function* reportTrackingAPI() {
  yield takeEvery(
    reportTrackingActions.GET_REPORTS_DUE_CURRENT_MONTH_AND_YEAR,
    getDueReportsResponse
  );
  yield takeEvery(
    reportTrackingActions.SELECT_TARGET_DAY_FOR_REPORTS,
    saveSelectedDueReports
  );
  yield takeEvery(
    reportTrackingActions.CREATING_NEW_REPORT_DEADLINE,
    createNewReportDdl
  );
  yield takeEvery(
    reportTrackingActions.CREATING_MILESTONE_CONFIRM,
    createMilestoneConfirmation
  );
  yield takeEvery(
    reportTrackingActions.UPDATING_REPORT_DEADLINE,
    updateExistReport
  );
  yield takeEvery(reportTrackingActions.GET_ALL_REPORTS, getAllReportsList);
  yield takeEvery(reportTrackingActions.SEARCH_REPORTS, getSearchReportList);
  yield takeEvery(
    reportTrackingActions.OPEN_NOTIFICATION_DETAIL_REPORT,
    openReportForNotification
  );
  yield takeEvery(
    reportTrackingActions.SUBMIT_AGENCY_REPORT,
    submitAgencyReport
  );
  yield takeEvery(reportTrackingActions.REQUEST_NEW_REPORT, requestNewReport);
  yield takeEvery(reportTrackingActions.LOAD_MORE_REPORTS, loadNextReports);
  yield takeEvery(reportTrackingActions.DELETE_ATTACHMENT, deleteAttachment);
  yield takeEvery(
    reportTrackingActions.DELETE_MILESTONE_ATTACHMENT,
    deleteMilestoneAttachment
  );
}
export default reportTrackingAPI;
