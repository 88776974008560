import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import styles from '../../styles/report-tracking/ReportsTable.module.scss';
import { connect } from 'react-redux';
import * as actions from '../../actions/reportTrackingAction';
import ReportServicePopup from './ReportServicePopup';
import { formatDate } from '../../utils/helper';
import ReportFields from '../../services/ReportFields';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import ToolTip from '../library/ToolTip';
import {
  SearchIcon,
  CloseButtonCircleFillIcon,
  InfoCircleFilledIcon,
  CheckmarkIcon,
  HalfCirlcleIcon,
  FlagFilledIcon,
  ClockIcon,
  CheckIcon,
  ChevronUpDownArrowsIcon,
  ChevronRightIcon,
} from '../../services/SvgLibrary';

const ReportsTable = props => {
  const tableContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [state, setState] = useState({
    currentSearch: 'searchAll',
    currentSearchTitle: 'Text Search', // set initial value according to the initial 'currentSearch'
    filterCombination: {
      completeFilter: true,
      displayMilestones: true,
      displayReports: true,
      fromDate : '',
      pastDueFilter: true,
      receivedFilter: true,
      toDate: '',
      upcomingFilter: true, 
    },
    isDropdownOpen: false,
    screenWidth: window.innerWidth,
    searchAgency: '',
    searchAll: '',
    searchCommittee: '',
    searchDivision: '',
    searchLaw: '',
    searchResultsDisplay: false,
    searchTitle: '',
    selectedSortBy: 'Last Received',
    selectedSortOrder: 'Ascending',
    sortBy: 'last_received',
    sortFields: [
      {
        title: 'Division',
        field: 'division',
      },
      {
        title: 'Errors',
        field: 'errors',
      },
      {
        title: 'Last Received',
        field: 'last_received',
      },
      {
        title: 'Next Due',
        field: 'next_due',
      },
      {
        title: 'Op Req Title',
        field: 'title',
      },
    ],
    toggleFilter: false,
  });

  useEffect(() => {
    // Get all the reports
    const urlReportFilter = queryString.parse(window.location.search);
    if (props.allReports.length === 0) {
      if (
        window.location.href.includes('/myTasks/myOpReqs') &&
        urlReportFilter.filter
      ) {
        let filterCombo = {
          displayReports: true,
          displayMilestones: true,
          pastDueFilter: urlReportFilter.filter === 'past' ? true : false,
          completeFilter: false,
          upcomingFilter: urlReportFilter.filter === 'upcoming' ? true : false,
          receivedFilter: false,
          fromDate : '',
          toDate : '',
        }
        onGetOpReqSearchResult(null, filterCombo);
        setState(prevState => ({
          ...prevState,
          filterCombination: filterCombo
        }))
      } else {
        props.getAllReports(window.location.href.includes('/myOpReqs'));
      }
    }
  }, [])

  useEffect(() => {
    const onScroll = (e) => {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop;
      if (scrollPosition < 1000 && props.nextAPI && !props.isLoading) {
        props.loadMoreReports(props.nextAPI);
      }
    };

    const tableContainerNode = tableContainerRef.current;
    if(tableContainerNode) {
      tableContainerNode
        .addEventListener('scroll', onScroll, false);
    }
    window.addEventListener('resize', () => {
      setState(prevState => ({ ...prevState, screenWidth: window.innerWidth}));
    });

    // designed to respond to clicks outside of a certain element. If a click
    // event occurs outside of the dropdown it will call closeDropdown to close the dropdown.
    function handleClickOutside(e) {
      let sortMenuButton = document.getElementById('sortMenuDropdown');
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        if (sortMenuButton && !sortMenuButton.contains(e.target)) {
          setState(prevState => ({ ...prevState, isDropdownOpen: false }));
        }
      }
    };
    window.addEventListener('mousedown', handleFilterClick);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      if(tableContainerNode) {
        tableContainerRef.current?.removeEventListener('scroll', onScroll);
      }
      document.removeEventListener('mousedown', handleFilterClick);
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [props.allReports, props.nextAPI, props.isLoading])

  const handleFilterClick = e => {
    let dropdown = document.getElementById('filter-dropdown');
    if (dropdown) {
      let isInDropdown = dropdown.contains(e.target);
      if (!isInDropdown && state.toggleFilter) {
        setState(prevState => ({  ...prevState, toggleFilter: false }));
      }
    }
  };

  // determins the current sort direction and field, then toggling the sort direction,
  // updating the sortBy field, and finally updating the component state and triggering
  // a refresh of the data with the new sort parameters
  const onColumnClick = (column, title) => {
    let selectedSortOrder = '';
    let sortBy = '';
    let selectedSortBy = '';
    if (title !== state.selectedSortBy) {
      selectedSortOrder = 'Ascending';
      sortBy = column;
      selectedSortBy = title;
    } else {
      const direction =
        state.sortBy[0] === '-' ? 'Descending' : 'Ascending';
      const field =
        state.sortBy[0] === '-'
          ? state.sortBy.substring(1)
          : state.sortBy;
      selectedSortOrder =
        direction === 'Descending' ? 'Ascending' : 'Descending';
      sortBy = direction === 'Descending' ? field : `-${field}`;
      selectedSortBy = title;
    }

    setState(prevState =>
      ({
        ...prevState,
        sortBy,
        selectedSortBy,
        selectedSortOrder,
        searchResultsDisplay: false,
      })
    );
    onGetOpReqSearchResult();
  };

  // toggles the isDropdownOpen state, which presumably controls whether
  // a dropdown menu is open or no
  const toggleDropdown = (value) => {
    setState(prevState => ({ ...prevState, isDropdownOpen: value }));
  };

  // This function is counting the number of filters that are currently activated.
  const countChecked = () => {
    const filterKeys = [
      'completeFilter',
      'receivedFilter',
      'upcomingFilter',
      'pastDueFilter',
      'toDate',
      'fromDate'
    ];
    let count = 0;
    filterKeys.forEach(key => {
      if (state.filterCombination[key]) {
        count++;
      }
    });
    return count;
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onGetOpReqSearchResult(e.target.value);
    }
  };

  // Trigger the action to search based on committee or keyword
  const onGetOpReqSearchResult = ( value = null, filter = null) => {
    let {
      sortBy,
      searchCommittee,
      searchTitle,
      searchDivision,
      searchAgency,
      searchLaw,
      searchAll,
      filterCombination,
    } = state;

    if(filter){
      filterCombination = filter
    }

    if(['committee', 'division', 'agency'].includes(state.currentSearch) && value) {
      if(state.currentSearch === 'committee') {
        searchCommittee = value
        setState(prevState => ({ ...prevState, searchCommittee: value, searchResultsDisplay: true}));
      } else if(state.currentSearch === 'division') {
        setState(prevState => ({ ...prevState, searchDivision: value, searchResultsDisplay: true}));
        searchDivision = value
      } else if(state.currentSearch === 'agency') {
        setState(prevState => ({ ...prevState, searchAgency: value, searchResultsDisplay: true}));
        searchAgency = value
      }
    } else {
      setState(prevState => ({ ...prevState, searchResultsDisplay: true}));
    }
    props.searchReports(
      sortBy,
      searchCommittee,
      searchTitle,
      searchDivision,
      searchAgency,
      searchLaw,
      searchAll,
      filterCombination,
      window.location.href.includes('/myOpReqs'),
      true
    );
  };

  const onClearFilter = () => {
    setState(prevState => ({
      ...prevState,
      searchCommittee: '',
      searchAgency: '',
      searchDivision: '',
      searchTitle: '',
      searchLaw: '',
      searchAll: '',
      sortBy: 'last_received',
      searchResultsDisplay: false,
    }));
    props.getAllReports(window.location.href.includes('/myOpReqs'));
  };

  const generateSearchBox = () => {
    const { agencies, committees, isLoading, userProfile } = props;
    let user_committee_ids = userProfile.committees.map(com => com.id);
    let searchCommittees = committees;
    if (window.location.href.includes('/myOpReqs')) {
      searchCommittees = committees.filter(committee =>
        user_committee_ids.includes(committee.id)
      );
    }

    const divisions = ReportFields.find(
      item => item.field === 'division'
    ).options;

    const searchFields = [
      {
        title: 'Text Search',
        field: 'searchAll',
      },
      {
        title: 'Agency',
        field: 'agency',
      },
      {
        title: 'Committee',
        field: 'committee',
      },
      {
        title: 'Division',
        field: 'division',
      },
      {
        title: 'Op Req Title',
        field: 'title',
      },
    ];

    return (
      <div className={styles['report-search']}>
        <div className={styles['search-by']}>
          <span className='badge'>Search by</span>
          <div className='dropdown'>
            <button
              className={`dropdown-toggle ${styles['report-select']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {state.currentSearchTitle}
            </button>
            <ul
              className={`dropdown-menu ${styles['search-dropdown-menu']}`}
              aria-labelledby='dropdownMenuButton1'>
              {searchFields.map((c, idx) => (
                <li key={idx}>
                  <a
                    className={`dropdown-item ${styles['search-dropdown-menu-item']}`}
                    onClick={e => {
                      e.preventDefault();
                      setState(prevState => ({
                        ...prevState,
                        currentSearch: c.field,
                        currentSearchTitle: c.title,
                        searchCommittee: '',
                        searchAgency: '',
                        searchDivision: '',
                        searchTitle: '',
                        searchLaw: '',
                        searchAll: '',
                      }));
                    }}
                    href='#'>
                    {c.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles['search-box']}>
          <SearchIcon
            width='24'
            height='24'
            onClickFunction={()=>onGetOpReqSearchResult()}
            classNameProp={styles['search-button']}
          />
          {state.currentSearch === 'committee' && (
            <select
              className='form-select'
              aria-label='Select a committee form the dropdown'
              onChange={e => {
                onGetOpReqSearchResult(e.target.value);
              }}
              value={state.searchCommittee}
              >
              {searchCommittees.map((c, idx) => (
                <option value={c.id} key={'c-'+ idx}>
                  {c.committee_name}
                </option>
              ))}
            </select>
          )}
          {state.currentSearch === 'agency' && (
            <select
              className='form-select'
              aria-label='Select an agency from the dropdown'
              onChange={e => {
                onGetOpReqSearchResult(e.target.value);
              }}
              value={state.searchAgency}
            >
              {agencies.map((agency, id) => (
                <option value={agency.id} key={'a-'+ id}>
                  {agency.name}
                </option>
              ))}
            </select>
          )}
          {state.currentSearch === 'title' && (
            <input
              className='form-control'
              type='text'
              value={state.searchTitle}
              placeholder='Search Op Req Titles'
              onChange={e => setState(prevState => ({  ...prevState, searchTitle: e.target.value}))}
              onKeyUp={onKeyPress}
            />
          )}
          {state.currentSearch === 'division' && (
            <select
              className='form-select'
              aria-label='Select a division from the dropdown'
              onChange={e => {
                onGetOpReqSearchResult(e.target.value);
              }}
              value={state.searchDivision}
            >
              <option>Select a Division</option>
              {divisions.map((division, id) => (
                <option value={division} key={'d-'+ id}>
                  {division}
                </option>
              ))}
            </select>
          )}
          {state.currentSearch === 'searchAll' && (
            <input
              className='form-control'
              type='text'
              value={state.searchAll}
              placeholder='Search by Text'
              onChange={e => {
                setState(prevState => ({ ...prevState, searchAll: e.target.value}))
              }}
              onKeyUp={onKeyPress}
            />
          )}
          {isLoading && (
            <span className={styles['search-loading']}>
              <div className='spinner-border text-secondary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </span>
          )}
          {state.searchResultsDisplay && !isLoading && (
            <CloseButtonCircleFillIcon
              width='16'
              height='16'
              fill='currentColor'
              onClickFunction={onClearFilter}
              classNameProp={`${styles['search-button']}`}
            />
          )}
        </div>
      </div>
    );
  };
const { allReports, isLoading, selectTargetDayForReports, userProfile } =
  props;
const isCMStaff =
  userProfile.role.includes('Council Member') ||
  userProfile.under_council_member;

if (isCMStaff) {
  state.sortFields = state.sortFields.filter(
    field => field.field != 'errors'
  );
}

const opReqsDisplay =
  allReports &&
  allReports.map((opReq, idx) => {
    let committees = opReq.committees.map((committee, idx) => (
      <p key={idx} className={styles['report-committee']}>
        {committee.committee_name}
      </p>
    ));
    let agencies = opReq.submitting_agency
      .map(agency => agency.name)
      .join(', ');
    let opReqTitle = opReq.title;

    if (state.screenWidth > 768) {
      opReqTitle = opReq.title;
      if (state.screenWidth < 992 && opReqTitle.length > 115) {
        opReqTitle = opReqTitle.substring(0, 115) + '...';
      } else if (state.screenWidth < 1200 && opReqTitle.length > 175) {
        opReqTitle = opReqTitle.substring(0, 175) + '...';
      }
    }

    let info = opReqTitle !== opReq.title;
    let localNextDue = formatDate(opReq.next_due);
    let nextDue = new Date(localNextDue).toDateString();
    let tileClass;
    switch (opReq.status.toLowerCase()) {
      case 'upcoming':
        tileClass = styles['upcoming'];
        break;
      case 'past_due':
        tileClass = styles['past-due'];
        break;
      case 'completed':
        tileClass = styles['completed'];
        break;
      case 'received':
        tileClass = styles['received'];
        break;
      default:
        tileClass = '';
        break;
    }
    let statusText = opReq.status.replace(/_/g, ' ');

    return (
      <div
        key={idx}
        className={styles['report-block']}
        onClick={() => {
          selectTargetDayForReports([opReq]);
          document.body.style.overflow = 'hidden';
        }}>
        {state.screenWidth > 768 && (
          <>
            <div className={styles['report-first-block']}>
              <div
                className={`${styles['report-sidelabel-container']} ${tileClass}`}>
                <p className={`${styles['report-sidelabel-text']}`}>
                  {statusText}
                </p>
              </div>
              <div>
                <div className={styles['report-sub-title']}>
                  Next Due Date
                </div>
                <div className={styles['report-last-date']}>
                  {opReq.next_due ? (
                    <>
                      <div>{nextDue.substr(4, 6).toUpperCase()}</div>
                      <div className={styles['report-last-date-year']}>
                        {nextDue.substr(11)}
                      </div>
                    </>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
            <div className={styles['report-middle-block']}>
              <div className={styles['report-title']}>
                {opReqTitle}
                {info ? (
                  <ToolTip
                    id={`report-${opReq.id}`}
                    target={`report-${opReq.id}`}
                    text={opReq.title}
                  />
                ) : (
                  ''
                )}
              </div>
              <div>
                {opReq.all_local_laws && (
                  <>
                    <span>{opReq.all_local_laws}</span>
                    <br />
                  </>
                )}
                <span className={styles['report-sub-title']}>Type: </span>
                <span className='capitalize'>{opReq.tracker_type}</span>
                <br />
                <span className={styles['report-sub-title']}>Agency: </span>
                <span>{agencies}</span>
              </div>
            </div>
            <div className={styles['report-end-block']}>
              <div className={styles['report-date-padding']}>
                Last Received {opReq.last_received
                  ? formatDate(opReq.last_received)
                  : 'N/A'}
              </div>
              <div className={styles['report-committees']}>
                <span>Committees: </span>
                {committees}
              </div>
            </div>
          </>
        )}
        {state.screenWidth <= 768 && (
          <>
            <div
              className={`${styles['report-sidelabel-container']} ${tileClass}`}>
              <p className={`${styles['report-sidelabel-text']}`}>
                {statusText}
              </p>
            </div>
            <div style={{ display: 'block', float: 'left' }}>
              <div>
                <div className={styles['report-sub-title-mobile']}>
                  Op Req Title:
                </div>
                <div className={styles['report-sub-field-mobile']}>
                  {opReqTitle}
                  {info ? (
                    <ToolTip
                      id={`report-${opReq.id}`}
                      target={`report-${opReq.id}`}
                      text={opReq.title}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div>
                <div className={styles['report-sub-title-mobile']}>
                  Type:
                </div>
                <div className={styles['report-sub-field-mobile']}>
                  <span className='capitalize'>{opReq.tracker_type}</span>
                </div>
              </div>
              {opReq.all_local_laws && (
                <div>
                  <div className={styles['report-sub-title-mobile']}>
                    Local Law:
                  </div>
                  <div className={styles['report-sub-field-mobile']}>
                    {opReq.all_local_laws}
                  </div>
                </div>
              )}
              <div>
                <div className={styles['report-sub-title-mobile']}>
                  Agency:
                </div>
                <div className={styles['report-sub-field-mobile']}>
                  {agencies}
                </div>
              </div>
              <div>
                <div
                  className={styles['report-sub-title-mobile-committee']}>
                  Committee:
                </div>
                <div className={styles['report-sub-field-mobile']}>
                  {committees}
                </div>
              </div>
              <div>
                <div className={styles['mobile-date']}>
                  Last Received
                  <span>
                    {opReq.last_received
                      ? formatDate(opReq.last_received)
                      : 'N/A'}
                  </span>
                </div>
                <div className={styles['mobile-date']}>
                  Next Due Date
                  <span>
                    {opReq.next_due ? formatDate(opReq.next_due) : 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        <ChevronRightIcon
          width='20'
          height='20'
          classNameProp={styles['icon']}
        />
      </div>
    );
  });
  // display the search area
  return (
    <div ref={tableContainerRef} className='main-container' id='main-container'>
      <div className={styles['reports-search-container']}>
        {generateSearchBox()}
        <div className={styles['report-sort-and-filter-container']}>
          <span style={{ display: 'flex' }}>
            Tracker List&nbsp;
            <InfoCircleFilledIcon
              classNameProp={styles['user-guide-field-icon']}
              idProp='tracker'
            />
            <UncontrolledPopover
              placement='left'
              target={'tracker'}
              fade={false}
              trigger='hover'>
              <PopoverBody>
                A tracker monitors reports, or milestones, over all due dates
                mandated by a law or part of a law.
              </PopoverBody>
            </UncontrolledPopover>
          </span>
          <span className={styles['sort-filter-flex']}>
            <span className={styles['filter-label']}>Sort by</span>
            <div
              className={`dropdown ${
                state.isDropdownOpen ? 'show' : ''
              }`}
              ref={dropdownRef}>
              <button
                className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
                type='button'
                id='dropdownMenuButton1'
                onClick={()=>{
                  toggleDropdown(true);
                }}
                aria-haspopup='true'
                aria-expanded={state.isDropdownOpen}>
                {state.selectedSortBy}
                <ChevronUpDownArrowsIcon
                  width='15'
                  height='15'
                  classNameProps={styles['chevron-icon']}
                />
              </button>
              <ul
                className={`dropdown-menu ${
                  state.isDropdownOpen ? 'show' : ''
                } ${styles['dropdown-menu']} ${styles['filter']}`}
                id='sortMenuDropdown'
                aria-labelledby='dropdownMenuButton1'>
                {state.sortFields.map((c, idx) => (
                  <li key={idx}>
                    <a
                      className={`dropdown-item ${styles['status-dropdown-item']}`}
                      href='#'
                      onClick={() => {
                        if (state.selectedSortBy !== c.title) {
                          onColumnClick(c.field, c.title);
                        }
                        toggleDropdown(false);
                      }}>
                      {c.title}
                      {state.selectedSortBy === c.title && (
                        <CheckIcon
                          classNameProp={styles['dropdown-checkmark']}
                        />
                      )}
                    </a>
                  </li>
                ))}
                <li>
                  <hr className='dropdown-divider' />
                </li>
                <li>
                  <a
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={e => {
                      onColumnClick(
                        state.sortBy,
                        state.selectedSortBy
                      );
                    }}>
                    Ascending
                    {state.selectedSortOrder === 'Ascending' && (
                      <CheckIcon
                        classNameProp={styles['dropdown-checkmark']}
                      />
                    )}
                  </a>
                </li>
                <li>
                  <a
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={e => {
                      onColumnClick(
                        state.sortBy,
                        state.selectedSortBy
                      );
                    }}>
                    Descending
                    {state.selectedSortOrder === 'Descending' && (
                      <CheckIcon
                        classNameProp={styles['dropdown-checkmark']}
                      />
                    )}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <button
                className={`btn dropdown-toggle ${styles['filter-label']}`}
                id='report-list-status'
                type='button'>
                Filter
                <span
                  className='badge'
                  style={{ color: '#002164', backgroundColor: '#c0d1f3' }}>
                  {countChecked()}
                </span>
              </button>
              <UncontrolledPopover
                innerClassName={styles['popover-container']}
                className={styles['popover-outer-container']}
                trigger='legacy'
                placement='bottom'
                target='report-list-status'>
                <PopoverBody
                  className={styles['list-filter-dropdown-wrapper']}>
                  <div className={styles['filter-flexbox']}>
                    <div>
                      <h6 className='dropdown-header pb-0'>Type</h6>
                      <label
                        className={`${styles['color-filter-container']} ${styles['completed']}`}>
                        Report
                        <input
                          type='checkbox'
                          checked={state.filterCombination.displayReports}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['displayReports'] =
                                !prevState.filterCombination['displayReports'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='report'
                        />
                      </label>

                      <label
                        className={`${styles['color-filter-container']} ${styles['completed']}`}>
                        Milestone
                        <input
                          type='checkbox'
                          checked={state.filterCombination.displayMilestones}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['displayMilestones'] =
                                !prevState.filterCombination['displayMilestones'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='milestone'
                        />
                      </label>

                      <UncontrolledPopover
                        fade={false}
                        trigger='hover'
                        placement='bottom'
                        target='report'>
                        <PopoverBody>
                          <div>
                            <b>Reports:</b> Reports are usually files mandated
                            often on a periodic basis by local law.
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>

                      <UncontrolledPopover
                        fade={false}
                        trigger='hover'
                        placement='bottom'
                        target='milestone'>
                        <PopoverBody>
                          <div>
                            <b>Milestones:</b> Milestones are one time goals that
                            are to be met.
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                      <hr className='dropdown-divider' />
                      <h6 className='dropdown-header pb-0'>Progress</h6>
                      <label
                        className={`${styles['color-filter-container']} ${styles['completed']}`}>
                        <CheckmarkIcon
                          width='30px'
                          height='20px'
                          classNameProp={styles['status-icons']}
                        />
                        Completed
                        <input
                          type='checkbox'
                          checked={state.filterCombination.completeFilter}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['completeFilter'] =
                                !prevState.filterCombination['completeFilter'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='completed'
                        />
                      </label>

                      <label
                        className={`${styles['color-filter-container']} ${styles['received']}`}>
                        <HalfCirlcleIcon
                          width='30px'
                          height='20px'
                          classNameProp={styles['status-icons']}
                        />
                        Received
                        <input
                          type='checkbox'
                          checked={state.filterCombination.receivedFilter}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['receivedFilter'] =
                                !prevState.filterCombination['receivedFilter'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['received']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='received'
                        />
                      </label>

                      <label
                        className={`${styles['color-filter-container']} ${styles['upcoming']}`}>
                        <FlagFilledIcon
                          width='30px'
                          height='20px'
                          classNameProp={styles['status-icons']}
                        />
                        Upcoming
                        <input
                          type='checkbox'
                          checked={state.filterCombination.upcomingFilter}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['upcomingFilter'] =
                                !prevState.filterCombination['upcomingFilter'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['upcoming']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='upcoming'
                        />
                      </label>

                      <label
                        className={`${styles['color-filter-container']} ${styles['past-due']}`}>
                        <ClockIcon
                          width='30px'
                          height='20px'
                          classNameProp={styles['status-icons']}
                        />
                        Past Due
                        <input
                          type='checkbox'
                          checked={state.filterCombination.pastDueFilter}
                          onChange={() => {
                            setState(prevState => {
                              prevState.filterCombination['pastDueFilter'] =
                                !prevState.filterCombination['pastDueFilter'];
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                        <span
                          className={`${styles['color-filter-checkmark']} ${styles['past-due']}`}></span>
                        <InfoCircleFilledIcon
                          classNameProp={styles['filter-popover-user-guide']}
                          idProp='past-due'
                        />
                      </label>
                    </div>
                    <div>
                      <h6 className='dropdown-header pb-0'>Due Date Range</h6>
                      <label className={styles['date-padding']}>
                        From
                        <input
                          className={`form-control ${styles['input-text-area']}`}
                          type='date'
                          value={state.filterCombination['fromDate']}
                          onChange={(e) => {
                            setState(prevState => {
                              prevState.filterCombination['fromDate'] = e.target.value;
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                      </label>
                      <label className={styles['date-padding']}>
                        To
                        <input
                          className={`form-control ${styles['input-text-area']}`}
                          type='date'
                          value={state.filterCombination['toDate']}
                          onChange={(e) => {
                              setState(prevState => {
                              prevState.filterCombination['toDate'] = e.target.value;
                              return {
                                ...prevState,
                                filterCombination: prevState.filterCombination,
                              };
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <button
                    className={styles['apply-button-filter']}
                    onClick={()=>onGetOpReqSearchResult()}>
                    Apply Filter
                  </button>
                  <UncontrolledPopover
                    fade={false}
                    trigger='hover'
                    placement='bottom'
                    target='completed'>
                    <PopoverBody>
                      <div>
                        <b>Completed:</b> A Operational Requirement whose
                        final report has been submitted with no further Op Req
                        due in the future, or a completed milestone.
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>

                  <UncontrolledPopover
                    fade={false}
                    trigger='hover'
                    placement='bottom'
                    target='received'>
                    <PopoverBody>
                      <div>
                        <b>Received:</b> A Operational Requirement with a
                        report due in the future which has already been
                        received.
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>

                  <UncontrolledPopover
                    fade={false}
                    trigger='hover'
                    placement='bottom'
                    target='upcoming'>
                    <PopoverBody>
                      <div>
                        <b>Upcoming: </b> A Operational Requirement whose most
                        recently due (past) report was received, with a future
                        report that has yet to be submitted, or a milestone
                        not yet due.
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>

                  <UncontrolledPopover
                    fade={false}
                    trigger='hover'
                    placement='bottom'
                    target='past-due'>
                    <PopoverBody>
                      <div>
                        <b>Past due:</b> A Operational Requirement whose most
                        recently due (past) report has not been submitted with
                        future Op Req not submitted, or an incomplete
                        milestone past its due date.
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          </span>
        </div>
      </div>
      <div className={styles['report-list-container']}>
        {allReports.length > 0 && opReqsDisplay}
      </div>
      {isLoading && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {allReports.length === 0 && (
        <div className={styles['no-valid-result-container']}>
          <img
            src='/../img/no-results-found.svg'
            alt='No valid results'></img>
          <p>No valid result</p>
        </div>
      )}
      {(!props.mode || props.mode === 'list') && (
        <ReportServicePopup match={props.match} currentMode='list' />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  //Deep copy the arrays from the state
  const committees = state.userReducer.committees
    .slice(0)
    .filter(com => com.committee_name !== 'Unassigned');
  const agencies = state.reportTrackingReducer.allAgencies.slice(0);
  committees.unshift({ id: 0, committee_name: 'Select Committee' });
  agencies.unshift({ id: 0, name: 'Select Agency' });
  return {
    userProfile: state.userReducer.userProfile,
    isLoading: state.reportTrackingReducer.isLoading,
    nextAPI: state.reportTrackingReducer.nextAPI,
    allReports: state.reportTrackingReducer.allReports,
    committees: committees,
    agencies: agencies,
  };
};

export default connect(mapStateToProps, actions)(ReportsTable);
