import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/ComboBox.module.scss';

/**
 * You will need to pass in the function that set's the selected value to the state
 * Props: focused, field, search, list, handleClick, clickOutside
 */
const ComboBox = props => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        if (props.menu && !props.menu.contains(e.target)) {
          props.clickOutside();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.menu, props.clickOutside]);

  //If the is a value or the value full matches a user in the user list then there is no need to show the list
  if (
    !props.focused ||
    props.list.find(value => value[props.field] === props.search)
  ) {
    return <div />;
  }

  const filteredList = props.list.filter(value => {
    let searchText = value[props.field];
    if (props.secondField) {
      searchText = `${value[props.field]} - ${value[props.secondField]}`;
    }
    return searchText.toLowerCase().includes(props.search.toLowerCase());
  });

  return (
    <ul
      className={`dropdown-menu show ${styles['ComboBox']}`}
      style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1000 }}
      ref={dropdownRef}>
      {filteredList.length === 0 && (
        <li className='dropdown-item text-muted' style={{'padding': '0.5rem 1rem'}}>No results found</li>
      )}
      {filteredList.map((value, id) => (
        <li key={id}>
          <button
            type='button'
            className='dropdown-item'
            onClick={props.handleClick}>
            {value[props.field]}
            {props.secondField && ` - ${value[props.secondField]}`}
          </button>
        </li>
      ))}
    </ul>
  );
};
ComboBox.propTypes = {
  list: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
export default ComboBox;
