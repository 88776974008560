import React from 'react';

import styles from '../../styles/user/UserProfile.module.scss';

const ListAndHeader = ({ header, list, showHeader, listFormatter }) => list.length ? (
  <table className={`col-12 ${styles['user-table']}`}>
    {showHeader && (
      <thead>
        <tr className={styles['user-row']}>
          <td className={styles['user-view']} colSpan='2'>
            <p className={styles['user-table-header']}>{header}</p>
          </td>
        </tr>
      </thead>
    )}
    <tbody>{listFormatter(list)}</tbody>
  </table>
) : null

export default ListAndHeader