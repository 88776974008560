import React from 'react';
import ComboBox from '../library/ComboBox';
import { SearchIcon } from '../../services/SvgLibrary';
import { format } from 'date-fns';
import styles from '../../styles/report-tracking/Agency.module.scss';

/**
 * You will need to pass in the function that set's the selected value to the state
 * Props:
 * id: Set an id that will be used do this dropdown selection
 * name: Set the name of the input field
 * list: The full list of the dropdown
 * field: The field value being displayed in the dropdown
 * placeholder: Set the placeholder text of the text field
 * focused: The value determining if the dropdown is in focus
 * setFocus: The function setting to focus of the dropdown
 * search: The filtered search of the dropdown
 * setSearch: The function that sets the search list
 * handleClick: Handle what happens when you click a dropdown
 * clickOutside: Handle what happens when you click outside the dropdown
 */
const TypeDropdown = props => {
  const {
    id,
    name,
    placeholder,
    search,
    setSearch,
    setFocus,
    focused,
    list,
    field,
    handleClick,
    clickOutside,
    onDeleteSelected,
    hasShadow,
    numbered,
    className,
    isSelected,
  } = props;

  return (
    <div id={id} className={` ${className || ''}`}>
      <div
        className={`${styles['search-container']} ${
          isSelected ? styles['selected'] : ''
        } gap-3`}>
        {numbered && <div className={styles['step-number']}>1</div>}
        <div
          className={`input-group input-group-lg rounded-3 ${
            hasShadow ? 'shadow-sm' : ''
          } border bg-white ${styles['search-input']}`}>
          {!search && (
            <span className='input-group-text border-0 bg-white rounded-start-3'>
              <SearchIcon />
            </span>
          )}
          <input
            name={name}
            type='text'
            placeholder={placeholder}
            autoComplete='off'
            className='form-control border-0 shadow-none p-3 rounded fs-6'
            value={search}
            onFocus={e => {
              setFocus(true);
              e.target.select();
            }}
            onChange={e => {
              const val = e.target.value;
              setSearch(val);

              if (!val) {
                onDeleteSelected()
              }
            }}
          />
          {search && (
            <button
              className='btn border-0'
              type='button'
              onClick={() => {
                setSearch('');
                onDeleteSelected();
              }}>
              &times;
            </button>
          )}

          <ComboBox
            search={search}
            list={list}
            menu={document.getElementById(id)}
            field={field}
            secondField={props.secondField}
            handleClick={handleClick}
            clickOutside={clickOutside}
            focused={focused}
          />
        </div>
      </div>

      
    </div>
  );
};

export default TypeDropdown;
