import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/user/UserProfile.module.scss';
import {
  ShortLeftArrowIcon,
  UserProfileCircleIcon,
} from '../../../services/SvgLibrary';

import { rolesUnderCouncilMembers } from '../../../services/constants';
import { useNavigate } from 'react-router-dom';
import SearchBarRadio from '../../directory/SearchBarRadio';

import { SEARCH_CATEGORIES } from '../../directory/utils';
import { createRoot } from 'react-dom/client';
import ListAndHeader from '../../directory/ListAndHeader';
import { includesNormalizedSubstring } from '../../../utils/helper';
import ProfileInfoCard from '../../directory/ProfileInfoCard';

const listsRef = {
  [SEARCH_CATEGORIES.COMMITTEE]: 'committees',
  [SEARCH_CATEGORIES.DIVISION]: 'division'
}

const filterByOrg = (users, category, selectedOrg) => users.reduce((acc, user) => {
  const titles = user[listsRef[category]].map(org => org[`${category.toLowerCase()}_name`]);
  if (titles.includes(selectedOrg)) {
    if (rolesUnderCouncilMembers.includes(user.role)) acc.filteredNonStaff.push(user)
    else acc.filteredStaff.push(user)
  }
  return acc;
}, {
  filteredStaff: [],
  filteredNonStaff: [],
})

const CentralStaff = ({
  users,
  userProfile,
  orgLists,
}) => {
  const navigate = useNavigate();

  const [staffData, setStaffData] = useState({
    filteredStaff: [...users],
    filteredNonStaff: [],
    selectedStaff: null,
  })

  const [searchFilters, setSearchFilters] = useState({
    category: SEARCH_CATEGORIES.STAFF,
    selectedOrg: '',
    query: ''
  })

  const handleRadioChange = (e) => {
    setStaffData({
      filteredStaff: users,
      filteredNonStaff: [],
      selectedStaff: null
    });

    setSearchFilters({
      category: e.target.value,
      selectedOrg: '',
      query: '',
    });
  };

  const handleOrgChange = useCallback(selectedOrg => {
    const { filteredStaff, filteredNonStaff } = filterByOrg(users, searchFilters.category, selectedOrg)
    setStaffData(prevState => ({
      ...prevState,
      filteredStaff,
      filteredNonStaff,
    }));
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      selectedOrg
    }))
  }, [users, searchFilters.category])

  const handleInputChange = e => {
    const query = e.target.value;

    let filteredStaff = users
    let filteredNonStaff = []

    if (searchFilters.category !== SEARCH_CATEGORIES.STAFF) {
      ({ filteredStaff, filteredNonStaff } = filterByOrg(users, searchFilters.category, searchFilters.selectedOrg))
    }

    setStaffData(prevData => ({
      ...prevData,
      filteredStaff: filteredStaff.filter(user => includesNormalizedSubstring(user.full_name, query)),
      filteredNonStaff: filteredNonStaff.filter(user => includesNormalizedSubstring(user.full_name, query)),
    }));
    setSearchFilters(prevSearchFilters => ({ ...prevSearchFilters, query }))
  };

  const handleUserClick = useCallback(
    (user) => {
      const isSelected = staffData.selectedStaff?.id === user.id;
      setStaffData((prevState) => {
        return {
          ...prevState,
          selectedStaff: isSelected ? null : user,
        };
      });
      navigate(isSelected ? "/staff" : `/staff/${user.id}`);
    },
    [setStaffData, staffData.selectedStaff]
  );

  const handleImageError = (e) => {
    e.target.onerror = null;
    createRoot(e.target.parentNode).render(<UserProfileCircleIcon />);
  };

  const displayStaffList = (list) => list.map(user => (
    <tr key={user.id} className={styles['user-row']}>
      <td className={styles['user-view']}>
        <a
          style={{ color: '#FFF' }}
          href={'mailto:' + user.email}
          title={'Email ' + user.full_name}>
          {user.profile_pic_icon ? (
            <img
              alt='My Profile Icon'
              src={user.profile_pic_icon.file}
              onError={handleImageError} />
          ) : (
            <UserProfileCircleIcon />
          )}
        </a>
      </td>
      <td className={styles['user-name-email']}>
        <button
          key={user.id}
          className={'pe-3 d-flex align-items-center justify-content-between'}
          onClick={() => handleUserClick(user)}>
          {user.full_name}
          <span style={{ fontSize: '.6em' }}>
            <em>
              {userProfile.permissions.auth.change_user &&
                !user.is_active &&
                '(Deactivated)'}
            </em>
          </span>
        </button>
      </td>
    </tr>
  ));

  const displayOrgsList = (orgs) => orgs.map(org => (
    <tr key={org.id} className={styles['user-row']}>
      <td className={styles['user-view']} style={{ color: '#FFF' }}>
        <UserProfileCircleIcon />
      </td>
      <td className={styles['user-name-email']}>
        <button
          className={'pe-3 d-flex align-items-center justify-content-between'}
          onClick={() => handleOrgChange(org[`${searchFilters.category.toLowerCase()}_name`])}>
          {org[`${searchFilters.category.toLowerCase()}_name`]}
          <span style={{ fontSize: '.6em' }}>
            <em>
              {userProfile.permissions.auth.change_user &&
                !org.active &&
                '(Deactivated)'}
            </em>
          </span>
        </button>
      </td>
    </tr>))

  return (
    <div className={styles['user-profile-container']}>
      <div className={styles['user-profile-main']}>
        <div className='p-3 position-sticky top-0'>
          <div
            className={`ps-1 pe-2 py-1 w-100 d-flex align-items-center justify-content-between flex-column ${styles['user-search-wrapper']}`}>
            <SearchBarRadio {...{ currentRadio: searchFilters.category, handleRadioChange }} />
            {(searchFilters.category === SEARCH_CATEGORIES.STAFF || searchFilters.selectedOrg.length > 0) && (
              <input
                type='text'
                className={`form-control form-control-sm ${styles['user-search-input']} mw-100`}
                id='searchCouncilStaff'
                placeholder='Search Council Staff'
                value={searchFilters.query}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
        {(searchFilters.category !== SEARCH_CATEGORIES.STAFF && searchFilters.selectedOrg.length > 0) && (
          <button
            style={{ backgroundColor: '#3d4761' }}
            className="ms-2 border-0 text-white d-flex align-items-center"
            onClick={() => handleRadioChange({ target: { value: searchFilters.category } })}>
            <ShortLeftArrowIcon />
            Back
          </button>
        )}
        {(searchFilters.category === SEARCH_CATEGORIES.STAFF || searchFilters.selectedOrg.length > 0) ? (
          <>
            <ListAndHeader {...{
              header: `(${searchFilters.selectedOrg}) Central Staff`,
              showHeader: searchFilters.selectedOrg.length > 0,
              list: staffData.filteredStaff,
              listFormatter: displayStaffList,
            }} />
            {searchFilters.selectedOrg.length > 0 && (
              <hr style={{ backgroundColor: 'white' }} />
            )}
            <ListAndHeader {...{
              header: `(${searchFilters.selectedOrg}) District Offices`,
              showHeader: searchFilters.selectedOrg.length > 0,
              list: staffData.filteredNonStaff,
              listFormatter: displayStaffList,
            }} />
          </>
        ) : (
          <ListAndHeader {...{
            header: searchFilters.selectedOrg,
            showHeader: searchFilters.category === SEARCH_CATEGORIES.STAFF,
            list: orgLists[searchFilters.category],
            listFormatter: displayOrgsList,
          }} />
        )}
      </div>
      <ProfileInfoCard {...{ localState: { selectedStaff: staffData.selectedStaff } }} />
    </div>
  );
};

const mapStateToProps = state => ({
  users: state.userReducer.userProfile.permissions.auth.change_user
    ? state.userReducer.allUserList
    : state.userReducer.userList,
  userProfile: state.userReducer.userProfile,
  orgLists: {
    [SEARCH_CATEGORIES.COMMITTEE]: state.userReducer.committees,
    [SEARCH_CATEGORIES.DIVISION]: state.userReducer.division,
  },
});

export default connect(mapStateToProps)(CentralStaff);