import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class FeedbackForm extends Component {
  state = {
    isFeedbackOpen: this.props.isFeedbackOpen,
    feedbackFormSender: this.props.sender,
    feedbackFormSubject: '',
    feedbackFormCategory: '1',
    feedbackFormDescription: '',
    feedbackFormLabels: [],
    feedbackFormURL: '',
  };
  handleFeedbackSubmit = e => {
    e.preventDefault();
    let date = new Date();
    date.setDate(date.getDate() + 7);
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let name = 'name=';
    if (this.state.feedbackFormCategory === '1') {
      name += 'Bug';
      this.setState({
        feedbackFormLabels: this.state.feedbackFormLabels.push(
          '5b450d8f9c16fb124a2b04c4'
        ),
      });
    } else if (this.state.feedbackFormCategory === '2') {
      name += 'Feature';
      this.setState({
        feedbackFormLabels: this.state.feedbackFormLabels.push(
          '5baa65d46650b10303d62799'
        ),
      });
    } else if (this.state.feedbackFormCategory === '3') {
      name += 'Feedback';
    } else if (this.state.feedbackFormCategory === '4') {
      name += 'Other';
    }
    name += ` - ${this.state.feedbackFormSubject.trim()}`;
    const params = [
      `key=${process.env.REACT_APP_TRELLO_KEY}`,
      `token=${process.env.REACT_APP_TRELLO_TOKEN}`,
      `idList=${process.env.REACT_APP_TRELLO_ID_LIST}`,
      `idMembers=${process.env.REACT_APP_TRELLO_MEMBERS}`,
      `pos=top`,
      name,
      `due=${date.getFullYear()}-${month}-${day}T22:00:00.000Z`,
      `desc=Submitted by %2A%2A${this.state.feedbackFormSender.trim()}%2A%2A%0A%0A${encodeURI(
        this.state.feedbackFormDescription
      )
        .split('\n')
        .join('%0A')
        .trim()}`,
    ];
    if (this.state.feedbackFormURL) {
      params.push(`urlSource=${this.state.feedbackFormURL.trim()}`);
    }
    if (this.state.feedbackFormLabels.length > 0) {
      params.push(`idLabels=${this.state.feedbackFormLabels.join(',')}`);
    }
    axios({
      method: 'post',
      url: `https://api.trello.com/1/cards?${params.join('&')}`,
      headers: { 'content-type': 'multipart/form-data' },
    })
      .then(() => {
        // this.setState({submitted:true},()=>{setTimeout(()=>this.props.toggleModal(), 1500)});
        this.props.feedbackHandler();
        setTimeout(() => {
          this.props.toggleModal();
          this.props.feedbackHandler();
        }, 1500);
      })
      .catch(error => {
        console.log(error);
      });
  };
  handleChange = e => {
    if (e.target.id === 'feedbackFormLabels') {
      const options = e.target.options;
      const selectedValues = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected && options[i].value) {
          selectedValues.push(options[i].value);
        }
      }
      this.setState({ [e.target.id]: selectedValues });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  render() {
    if (!this.props.feedbackSent) {
      return (
        <form onSubmit={this.handleFeedbackSubmit}>
          <div className='p-3'>
            <div className='row mb-3'>
              <div className='col-12 col-md-6'>
                <div className='form-floating mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='feedbackFormSubject'
                    placeholder='Subject (required)'
                    onChange={this.handleChange}
                    required
                  />
                  <label htmlFor='feedbackFormSubject'>Subject *</label>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='form-floating'>
                  <select
                    style={{ paddingBottom: '.425rem' }}
                    className='form-select'
                    defaultValue='1'
                    id='feedbackFormCategory'
                    aria-label='Feedback Form Category'
                    onChange={this.handleChange}
                    required>
                    <option value='1'>Report a bug</option>
                    <option value='2'>Request a feature</option>
                    <option value='3'>General feedback</option>
                    <option value='4'>Other</option>
                  </select>
                  <label htmlFor='feedbackFormCategory'>Feedback Type *</label>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-12'>
                <label
                  htmlFor='feedbackFormLabels'
                  className='form-label'
                  style={{ fontSize: '.9rem' }}>
                  What part of IWOMM is this regarding? *
                </label>
                <select
                  className='form-select'
                  id='feedbackFormLabels'
                  multiple
                  aria-label='multiple select'
                  onChange={this.handleChange}
                  required>
                  {this.props.userProfile.permissions.lsRequest_api
                    .view_lsrequest && (
                    <option value='5e5455b1b0ed6280f282af21'>LS Request</option>
                  )}
                  {this.props.userProfile.permissions.reportTracking
                    .view_report && (
                    <option value='5e5455c8f196780e997b8950'>
                      Operational Requirements
                    </option>
                  )}
                  {this.props.userProfile.permissions.proclamationRequest
                    .view_proclamationrequest && (
                    <option value='650cabab69b4c89e4e74e85a'>
                      Proclamation Requests
                    </option>
                  )}
                  {this.props.userProfile.permissions.citationRequest
                    .view_citationrequest && (
                    <option value='650cabbc37529748ccdfe7ae'>
                      Citation Requests
                    </option>
                  )}
                  <option value='5e5456152a9d8c431f21e29e'>
                    User / User Profiles
                  </option>
                  {this.props.userProfile.permissions.wiki.view_page && (
                    <option value='5e5455d39663d386df0aedd9'>
                      Council Legal Exchange (CLEX)
                    </option>
                  )}
                  <option value=''>Other</option>
                </select>
                <p style={{ fontSize: '.7rem' }}>
                  Use Ctrl and/or Shift + Click to select multiple areas
                </p>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-12'>
                <div className='form-floating'>
                  <textarea
                    className='form-control'
                    placeholder='Please include as much detail as possible. The more information we have, the better we can assist you.'
                    id='feedbackFormDescription'
                    style={{ height: '100px' }}
                    onChange={this.handleChange}
                    required></textarea>
                  <label htmlFor='feedbackFormDescription'>Description *</label>
                </div>
                <p style={{ fontSize: '.7rem' }}>
                  Please include as much detail as possible. The more
                  information we have, the better we can assist you.
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='form-floating'>
                  <input
                    type='url'
                    className='form-control'
                    id='feedbackFormURL'
                    placeholder='Include http:// or https://'
                    onChange={this.handleChange}
                  />
                  <label htmlFor='feedbackFormURL'>
                    URL - include http:// or https://
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#ECECEC',
              padding: '5px',
              position: 'sticky',
              bottom: '-1px',
              borderRadius: '0px 0px 8px 8px',
              textAlign: 'center',
            }}>
            <input type='submit' className='submit-button' value='Send'></input>
          </div>
        </form>
      );
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <h4>Thank you for your feedback!</h4>
          <h5>Your message has been sent.</h5>
          <h5>We will reach out to you at our earliest convenience.</h5>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(FeedbackForm);
