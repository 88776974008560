import { statuses } from './constants';
let notViewable =
  '<br/><strong><em>(Not viewable by Council Member Offices)</em></strong>';
const allDateFields = [
  {
    title: 'Date Received',
    field: 'date_received',
    notification_field_title: 'Date Received',
    create: false,
    show_update: true,
    show: false,
    type: 'date',
    changable: false,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    description: 'The date IWOMM received the LSR',
    table_order: 36,
  },
  {
    title: 'Last Activated',
    field: 'last_activated',
    notification_field_title: 'Last Activated',
    create: false,
    show_update: true,
    show: false,
    type: 'date',
    changable: false,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description:
      'The date the LSR became active and the 60-day clock started running',
    table_order: 2,
  },
];

export const allFileFields = [
  {
    title: 'Coversheet',
    field: 'coversheet',
    notification_field_title: 'Coversheet',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'The pdf of the signed coversheet transmitted to the CM.' + notViewable,
    table_order: 29,
  },
  {
    title: 'Legal Memo',
    field: 'legal_memo',
    notification_field_title: 'Legal Memo',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description: 'The confidential legal memo for the LSR.' + notViewable,
    table_order: 30,
  },
  {
    title: 'Plain Language Summary',
    field: 'plain_language_summary',
    notification_field_title: 'Plain Language Summary',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'The plain language summary transmitted to the CM by Central Staff.' +
      notViewable,
    table_order: 31,
  },
  {
    title: 'Prepared Legislation',
    field: 'bill',
    notification_field_title: 'Prepared Legislation',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'The initially drafted legislation transmitted to the CM by Central Staff.' +
      notViewable,
    table_order: 28,
  },
  {
    title: 'Change of Status Letter',
    field: 'resource_letter',
    notification_field_title: 'Change of Status Letter',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'A change of status letter requesting specific additional information, extending time to complete the LSR, or recommending withdrawal.' +
      notViewable,
    table_order: 32,
  },
  {
    title: 'Committee Report',
    field: 'hearing_report',
    notification_field_title: 'Committee Report',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'none',
    duplicate_check: false,
    description:
      'The Committee Report or Briefing Paper on the introduced legislation',
    table_order: 33,
  },
  {
    title: 'Hearing Summary',
    field: 'hearing_summary',
    notification_field_title: 'Hearing Summary',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'A summary of the bill’s hearing prepared by committee staff.' +
      notViewable,
    table_order: 34,
  },
  {
    title: 'Bill Matrix',
    field: 'bill_matrix',
    notification_field_title: 'Bill Matrix',
    create: false,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'The bill’s issue matrix prepared by committee staff after a hearing.' +
      notViewable,
    table_order: 35,
  },
  {
    title: 'CM Attachment',
    field: 'cm_attachment',
    notification_field_title: 'CM Attachment',
    create: true,
    show_update: true,
    show: false,
    type: 'file',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description: 'Any attachment files submitted by the CM',
    table_order: 13,
  }
].sort((a, b) => (a.field > b.field ? 1 : -1));

export const backgroundRelatedFields = [
  {
    title: 'Background Sess. 11',
    field: 'background',
    notification_field_title: 'Background Sess. 11',
    create: false,
    show_update: false,
    show: false,
    type: 'textarea',
    required: false,
    changable: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: true,
    description:
      'Background information provided by the CM submitting the LSR (Session 11 LS only)',
    table_order: 10,
  },
  {
    title: 'Inspiration For LSR',
    field: 'background_inspiration',
    notification_field_title: 'Inspiration For LSR',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: true,
    changable: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    help: {
      'Select Type':
        'Central staff uses this information to better understand the intentions behind the request.',
      'News or law review article':
        'Please provide a link to relevant article(s)',
      'Hearing':
        'Please identify the hearing which inspired the LSR (with date) and any particular remarks made.',
      'Constituent complaint':
        'Please describe the complaint along with the date of the complaint.',
      'Other': 'Please elaborate on what this other inspiration was.',
    },
    description: 'Background information provided by the CM submitting the LSR',
    table_order: 11,
  },
  {
    title: 'Existing Laws',
    field: 'background_existing_laws',
    notification_field_title: 'Existing Laws',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: true,
    changable: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    help: 'Are there any existing laws, regulations or rules related to the requested legislation? For example, would the requested legislation make a change to an existing legal requirement? If so, please identify the related law(s), regulations or rules.',
    description:
      'Existing laws, regulations or rules relevant to the LSR would be listed here',
    table_order: 12,
  },
];

const descriptionRelatedFields = [
  {
    title: 'Describe The Problem',
    field: 'description_problem',
    notification_field_title: 'Problem Description',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: true,
    changable: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    help: 'Describe the problem you would like to address',
    description: 'CM’s description of the problem to be solved by the LSR',
    table_order: 7,
  },
  {
    title: 'Legislative Solution',
    field: 'description_legislative_solution',
    notification_field_title: 'Legislative Solution',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: true,
    changable: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    help: 'How would legislation address the problem described above?',
    description:
      'The CM’s description for how legislation will address the problem',
    table_order: 8,
  },
  {
    title: 'Related Discussions',
    field: 'description_related_discussions',
    notification_field_title: 'Related Discussions',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: false,
    changable: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    help: 'Are there any parties with whom you authorize the drafter to have conversations about this LSR and with whom you are willing to waive the confidentiality of this LSR (for example, an advocate)? If yes please give their name(s) and contact information',
    description:"Parties with whom the CM has authorized the drafter to have conversations about this LSR and with whom the CM has waived the confidentiality of this LSR.",
    table_order: 9,
  },
];

export const LsFields = [
  {
    title: 'LSR #',
    field: 'ls_number',
    notification_field_title: 'LSR #',
    create: false,
    show_update: true,
    show: true,
    type: 'number',
    changable: false,
    required: false,
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description:
      'The serial number assigned to the Legislative Service Request by IWOMM',
    table_order: 1,
  },
  {
    title: 'Sponsor',
    field: 'first_prime',
    notification_field_title: 'Sponsor',
    create: true,
    show_update: true,
    show: true,
    type: 'custom',
    required: true,
    changable: true,
    searchable: true,
    viewability: 'staffOnly',
    duplicate_check: true,
    description: 'The CM who submitted this LSR',
    table_order: 3,
  },
  {
    title: 'Activation Requested',
    field: 'activation_requested',
    notification_field_title: 'Activation Requested',
    create: false,
    show_update: false,
    show: true,
    type: 'checkbox',
    changable: false,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description: 'Whether a request has been sent to activate an LSR',
    table_order: 39,
  },
  {
    title: 'Division',
    field: 'division',
    notification_field_title: 'Division',
    create: false,
    show_update: true,
    show: false,
    type: 'select',
    changable: true,
    required: false,
    options: [
      'Select a Division',
      'BIL',
      'COMP',
      'FIN',
      'GOV',
      'HUM',
      'INF',
      'LU',
      'OGC',
    ],
    searchable: true,
    viewability: 'all',
    duplicate_check: false,
    description:
      'This designation may change upon introduction of the legislation',
    table_order: 20,
  },
  {
    title: 'Committee',
    field: 'committee',
    notification_field_title: 'Committee',
    create: false,
    show_update: true,
    show: true,
    type: 'select',
    changable: true,
    required: false,
    searchable: true,
    viewability: 'staffOnly',
    duplicate_check: true,
    description:
      'This designation may change upon introduction of the legislation.' +
      notViewable,
    table_order: 21,
  },
  {
    title: 'LSR Type',
    field: 'ls_type',
    notification_field_title: 'LSR Type',
    create: true,
    show_update: true,
    show: false,
    type: 'select',
    changable: true,
    required: true,
    options: ['Select an LSR Type', 'Local Law', 'Resolution', 'Co-Naming'],
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description: 'Whether the CM requests a local law, resolution or co-naming',
    table_order: 5,
  },
  {
    title: 'Legistar Info',
    field: 'matters',
    notification_field_title: 'Legistar Info',
    create: false,
    show_update: true,
    show: true,
    type: 'multi-select',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    help: 'Add URL from Legistar (i.e. https://legistar.council.nyc.gov/LegislationDetail.aspx?...)',
    description: 'Legistar URL to associated bill',
    table_order: 40,
  },
  // {
  //   title: 'Operational Requirement',
  //   field: 'operational_requirement',
  //   notification_field_title: 'Operational Requirement',
  //   create: true,
  //   show_update: true,
  //   show: false,
  //   type: 'select',
  //   required: true,
  //   changable: true,
  //   options: ['Select one', 'Yes', 'No'],
  //   searchable: false,
  //   viewability: 'all',
  //   duplicate_check: false,
  //   help: 'Operational requirement indicates that this bill will require the submission of a report (even if the reporting requirement is not the primary focus of the bill), or that a milestone be achieved (i.e. creation of a new office, or completion of a particular task)',
  //   description:
  //     'Whether the LSR will result in the submission of a report or milestone.',
  //   table_order: 6,
  // },
  ...descriptionRelatedFields,
  {
    title: 'First In Time',
    field: 'first_in_time',
    notification_field_title: 'First In Time',
    create: false,
    show_update: true,
    show: false,
    type: 'select',
    changable: true,
    required: false,
    options: ['Select one', 'Yes', 'No', 'TBD', 'Partially'],
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description:
      'Whether this LSR is the earliest-in-time LSR submitted for this proposed legislation',
    table_order: 24,
  },
  {
    title: 'Staff',
    field: 'staff',
    notification_field_title: 'Staff',
    create: false,
    show_update: true,
    show: false,
    type: 'custom',
    required: false,
    changable: true,
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description: 'The member of Central Staff assigned to the LSR.',
    table_order: 19,
  },
  {
    title: 'Status',
    field: 'status',
    notification_field_title: 'Status',
    create: false,
    show_update: true,
    show: false,
    type: 'select',
    changable: true,
    required: false,
    options: ['Select a Status', ...statuses],
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description: 'The LSR’s current status',
    table_order: 22,
  },
  ...backgroundRelatedFields,
  {
    title: 'Attachment Description',
    field: 'attachment_descriptions',
    notification_field_title: 'Attachment Description',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: false,
    changable: true,
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    description: 'A description of attachments the CM submitted, if any',
    table_order: 14,
  },
  {
    title: 'Contact Person',
    field: 'contact_person',
    notification_field_title: 'Contact Person',
    create: true,
    show_update: true,
    show: false,
    type: 'custom',
    required: true,
    changable: true,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description:'The CM staff member to contact with questions and updates about the LSR.',
    table_order: 17,
  },
  {
    title: 'CM Notes',
    field: 'cm_notes',
    notification_field_title: 'CM Notes',
    create: true,
    show_update: true,
    show: false,
    type: 'textarea',
    required: false,
    changable: true,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description: 'Notes for the CM from Central Staff',
    table_order: 18,
  },
  {
    title: 'Submitted By',
    field: 'submitted_by',
    notification_field_title: 'Submitted By',
    create: false,
    show_update: true,
    show: false,
    type: 'text',
    required: true,
    changable: false,
    searchable: true,
    viewability: 'all',
    duplicate_check: true,
    description: 'The IWOMM user account that submitted the LSR',
    table_order: 37,
  },
  {
    title: 'Confidential Comments',
    field: 'confidential_comments',
    notification_field_title: 'Confidential Comments',
    create: false,
    show_update: true,
    show: false,
    type: 'textarea',
    required: false,
    changable: true,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: true,
    description: 'Internal notes for Central Staff only.' + notViewable,
    table_order: 27,
  },
  {
    title: 'Reintroduction',
    field: 'reintroduction',
    notification_field_title: 'Reintroduction',
    create: true,
    show_update: true,
    show: true,
    type: 'select',
    required: true,
    changable: true,
    options: ['Select one', 'Yes', 'No', 'N/A'],
    searchable: false,
    viewability: 'all',
    duplicate_check: true,
    description:
      'Whether the LSR is a resubmission of an LSR or introduction from a prior session',
    table_order: 15,
  },
  {
    title: 'Prev LSR #',
    field: 'prev_sess_ls_number',
    notification_field_title: 'Prev LSR #',
    create: false,
    show_update: true,
    show: true,
    type: 'number',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: true,
    description:
      'The LS Request number from the previous session, if applicable',
    table_order: 16,
  },
  ...allFileFields,
  ...allDateFields,
  {
    title:
      'By checking this checkbox you are agreeing to waive confidentiality of this LSR for the limited purpose of initiating co-sponsorship discussions in the event that a duplicate LSR has been submitted by another Council Member.',
    field: 'make_first_prime_public',
    notification_field_title: 'Confidentiality Waiver',
    create: true,
    show_update: true,
    show: false,
    type: 'checkbox',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description: 'Whether the CM has waived confidentiality for this LSR',
    table_order: 4,
  },
  {
    title: 'Overlapping LSRs',
    field: 'overlapping_ls',
    notification_field_title: 'Overlapping LSRs',
    create: false,
    show_update: false,
    show: false,
    type: 'custom',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description: 'Other LSRs overlapping with this LSR.' + notViewable,
    table_order: 25,
  },
  {
    title: 'Related LSRs',
    field: 'related_ls',
    notification_field_title: 'Related LSRs',
    create: false,
    show_update: false,
    show: false,
    type: 'custom',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'Other LSRs related to this LSR but not overlapping.' + notViewable,
    table_order: 26,
  },
  {
    title: 'Initial Dup Check',
    field: 'duplicate_check',
    notification_field_title: 'Initial Dup Check',
    create: false,
    show_update: false,
    show: false,
    type: 'checkbox',
    changable: true,
    required: false,
    searchable: false,
    viewability: 'all',
    duplicate_check: false,
    description:
      'Whether Central Staff has searched the log to check for duplicate LSRs',
    table_order: 23,
  },
  {
    title: 'Assoc. Op Req',
    field: 'associated_report_tracking',
    notification_field_title: 'Assoc. Operational Requirement',
    create: false,
    show_update: false,
    show: true,
    type: 'select',
    changable: false,
    required: false,
    searchable: false,
    viewability: 'staffOnly',
    duplicate_check: false,
    description:
      'Operational Requirements associated with the LSR.' + notViewable,
    table_order: 38,
  },
  {
    title: "New Status",
    field: "new_status",
    notification_field_title: "New Status",
    create: false,
    show_update: false,
    show: false,
    type: "text",
    changable: false,
    required: false,
    searchable: false,
    viewability: "none",
    duplicate_check: false,
    description: "New Status pending Approval",
    table_order: 41
  },
  {
    title: "New Status Requestor",
    field: "new_status_requestor",
    notification_field_title: "New Status Requestor",
    create: false,
    show_update: false,
    show: false,
    type: "custom",
    changable: false,
    required: false,
    searchable: false,
    viewability: "none",
    duplicate_check: false,
    description: "Requestor Submitting New Status pending Approval",
    table_order: 42
  },
];

export const csvHeaderCM = LsFields.filter(
  field => field.viewability === 'all'
).map(lsField => {
  return {
    label: lsField.notification_field_title,
    key: lsField.field,
  };
});

export const csvHeaderNonCM = LsFields.map(lsField => {
  return {
    label: lsField.notification_field_title,
    key: lsField.field,
  };
}).filter(lsField => lsField !== undefined);
