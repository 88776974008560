import React, { useState, useEffect } from 'react';
import styles from '../../styles/ls-request/UpdateLsRequestPopup.module.scss';
import stylesModal from '../../styles/ls-request/LsRequestsList.module.scss';
import { connect } from 'react-redux';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import {
  deleteFile,
  updateTargetLsRequest,
} from '../../actions/lsRequestAction';
import Modal from 'react-modal';
import Prompt from '../library/Prompt';
import { LsFields } from '../../services/LsFields';
import UserSelect from '../user/UserSelect';
import File from '../library/File';
import ToolTip from '../library/ToolTip';
import {
  formatDate,
  formatDateTime,
  acceptedFileTypes,
} from '../../utils/helper';
import {
  rolesUnderCouncilMembers,
  nonLegDivisionRoles,
  cmNonEditFields,
  staffEditFields,
  cmEditFields
} from '../../services/constants';
import { ExclamationPointDiamondIcon } from '../../services/SvgLibrary';

const numOfFieldsPerColumn = 7;
const numOfFieldsPerPart = numOfFieldsPerColumn * 2;

const UpdateLsRequestPopup = props => {
  /*  States  */
  const [curtLs, setCurtLs] = useState({
    attachment_descriptions: '',
    background_existing_laws: '',
    background_inspiration: '',
    bill_matrix: [],
    bill: [],
    cm_attachment: [],
    cm_notes: '',
    committee: '',
    confidential_comments: '',
    contact_person: '',
    contact_person_focus: false,
    coversheet: [],
    created_at: '',
    date_received: '',
    description_legislative_solution: '',
    description_problem: '',
    description_related_discussion: '',
    division: '',
    first_in_time: '',
    first_prime: '',
    first_prime_focus: false,
    hearing_report: [],
    hearing_summary: [],
    last_activated: '',
    legal_memo: [],
    matters: [],
    ls_number: '',
    ls_type: '',
    make_first_prime_public: false,
    plain_language_summary: [],
    reintroduction: false,
    resource_letter: [],
    staff: '',
    staff_focus: false,
    status: '',
    submitted_by: '',
  });
  //  eslint-disable-next-line no-unused-vars
  const [curtPart, setCurtPart] = useState(0);
  const [errorField, setErrorField] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [inspirationType, setInspirationType] = useState('Select Type');
  const [promptOpen, setPromptOpen] = useState(false);
  const [isSponsorFocused, setIsSponsorFocused] = useState(false);
  const lsFieldList = JSON.parse(
    JSON.stringify(
      LsFields.filter(field => field.changable).filter(
        field =>
          !(
            nonLegDivisionRoles.includes(props.userProfile.role) &&
            field.viewability === 'staffOnly'
          ) && field.show_update
      )
    )
  );
  const [newFields, setNewFields] = useState({
    bill_matrix: [],
    bill: [],
    cm_attachment: [],
    coversheet: [],
    hearing_report: [],
    hearing_summary: [],
    legal_memo: [],
    plain_language_summary: [],
    resource_letter: [],
  });
  /* End states */
  const permision_required_statuses = [
    'Recommending Withdrawal',
    'Specific Additional Information Requested',
    'Staff Hold',
    'Resource Letter',
    'Low-Priority Hold',
  ];
  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    Modal.setAppElement('body');
    const { updateTargetLs } = props;
    let inspiration = updateTargetLs.background_inspiration;
    if (inspiration) {
      if (
        inspiration.includes('Hearing') ||
        inspiration.includes('Constituent complaint') ||
        inspiration.includes('News or law review article') ||
        inspiration.includes('Other')
      ) {
        let split = inspiration.split(':')[0];
        split = split.substring(split.lastIndexOf(' ') + 1);
        const backend_inspiration_field = LsFields.find(
          item => item.field === 'background_inspiration'
        );
        const inspirationTypes = Object.keys(backend_inspiration_field.help);
        setInspirationType(inspirationTypes.find(key => key.includes(split)));
      } else {
        setInspirationType('');
      }
    }
    let currentLS = {
      attachment_descriptions: updateTargetLs.attachment_descriptions,
      background_existing_laws: updateTargetLs.background_existing_laws,
      background_inspiration: updateTargetLs.background_inspiration,
      bill_matrix: updateTargetLs.bill_matrix,
      bill: updateTargetLs.bill,
      cm_attachment: updateTargetLs.cm_attachment,
      cm_notes: updateTargetLs.cm_notes,
      committee: updateTargetLs.committee
        ? updateTargetLs.committee.committee_name
        : '',
      confidential_comments: updateTargetLs.confidential_comments,
      contact_person: updateTargetLs.contact_person
        ? updateTargetLs.contact_person.full_name
        : '',
      contact_person_focus: false,
      coversheet: updateTargetLs.coversheet,
      created_at: updateTargetLs.created_at,
      date_received: updateTargetLs.date_received,
      description_legislative_solution:
        updateTargetLs.description_legislative_solution,
      description_problem: updateTargetLs.description_problem,
      description_related_discussions:
        updateTargetLs.description_related_discussions,
      division: updateTargetLs.division,
      first_in_time: updateTargetLs.first_in_time,
      first_prime: updateTargetLs.first_prime
        ? updateTargetLs.first_prime.full_name
        : '',
      first_prime_focus: false,
      hearing_report: updateTargetLs.hearing_report,
      hearing_summary: updateTargetLs.hearing_summary,
      last_activated: updateTargetLs.last_activated,
      legal_memo: updateTargetLs.legal_memo,
      matters: updateTargetLs.matters,
      ls_number: updateTargetLs.ls_number,
      ls_type: updateTargetLs.ls_type,
      make_first_prime_public: updateTargetLs.make_first_prime_public,
      plain_language_summary: updateTargetLs.plain_language_summary,
      prev_sess_ls_number:
        updateTargetLs.prev_sess_ls_number === null
          ? ''
          : updateTargetLs.prev_sess_ls_number,
      reintroduction:
        updateTargetLs.reintroduction === '' ||
        updateTargetLs.reintroduction === null
          ? 'None'
          : updateTargetLs.reintroduction,
      resource_letter: updateTargetLs.resource_letter,
      staff: updateTargetLs.staff ? updateTargetLs.staff.full_name : '',
      staff_focus: false,
      status: updateTargetLs.status,
      submitted_by: updateTargetLs.submitted_by
        ? updateTargetLs.submitted_by.full_name
        : '',
    };
    setCurtLs(currentLS);
  }, []);
  /** End component mount useEffect hook */

  // Add the selected Local Law
  const onAddSelectedLocalLaw = selectedLocalLaw => {
    const localLaws = curtLs['matters'];
    let hasDuplicate = false;
    for (let i = 0; i < localLaws.length; i++) {
      let check = false;
      if (localLaws[i]['link']) {
        check = selectedLocalLaw['intro'].includes(localLaws[i]['link']);
      } else {
        check =
          localLaws[i]['intro'].includes(selectedLocalLaw['intro']) ||
          selectedLocalLaw['intro'].includes(localLaws[i]['intro']);
      }
      if (check) {
        hasDuplicate = true;
        return false;
      }
    }
    if (!hasDuplicate) {
      localLaws.push(selectedLocalLaw);
    }
    setCurtLs({ ...curtLs, matters: localLaws });
    return true;
  };
  
  // Delete the selected Local Law
  const onDeleteTargetLocalLaw = targetIdx => {
    const localLaw = curtLs['matters'];
    let newLocalLaws = [];
    for (let i = 0; i < localLaw.length; i++) {
      if (i !== targetIdx) {
        newLocalLaws.push(localLaw[i]);
      }
    }
    setCurtLs({ ...curtLs, matters: newLocalLaws });
  };

  const legistarLinkValidation = link => {
    let valid = true;
    if (
      /^(http|https):\/\/legistar\.council\.nyc\.gov\/LegislationDetail\.aspx\?/im.test(
        link
      )
    ) {
      let legistar_link = new URL(link);
      if (
        !legistar_link.searchParams.get('ID') ||
        !legistar_link.searchParams.get('GUID')
      ) {
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  }

  const checkValidation = () => {
    let correctInput = true;
    let lsArr = lsFieldList;
    let errors = {};
    let errorArray = [];

    for (let i = 0; i < lsArr.length; i++) {
      if (lsArr[i].changable) {
        let value = curtLs[lsArr[i].field];
        if (
          lsArr[i].required &&
          (value === '' || (Array.isArray(value) && value.length === 0))
        ) {
          correctInput = false;
          errorArray.push(lsArr[i].title);
          errors[lsArr[i].field] = 'Required fields (*) cannot be empty!';
        } else if (lsArr[i].field === 'matters') {
          value.forEach(ll=>{
            if(ll['link']) {
              let valid = legistarLinkValidation(ll['link'])
              if(!valid) {
                errorArray.push(lsArr[i].title);
                correctInput = false;
                errors[lsArr[i].field] = "URL must be the full legislation link from Legistar (e.g., 'https://legistar.council.nyc.gov/LegislationDetail.aspx?…')";
              }
            }
          })
        } else if (lsArr[i].field === 'status') {
          let oldLsStatus = props.updateTargetLs['status'];
          let matters= curtLs['matters'];
          if (
            props.updateTargetLs['new_status'] &&
            oldLsStatus !== curtLs['status']
          ) {
            errorArray.push(lsArr[i].title);
            correctInput = false;
            errors[lsArr[i].field] =
              'There is already a request for changing the status pending approval';
          } else if (
            oldLsStatus !== 'Enacted' &&
            value === 'Enacted' &&
            matters.length > 0
          ) {
            errorArray.push(lsArr[i].title);
            correctInput = false;
            errors[lsArr[i].field] =
              'To change status to Enacted a legistar URL is required';
          } else if (
            oldLsStatus !== curtLs['status'] &&
            permision_required_statuses.includes(curtLs['status'])
          ) {
            // Check to see that if the status was changed to one that requires approval
            let justUnassigned = false;
            for (let i = 0; props.userProfile.division.length > i; i++) {
              if (
                props.userProfile.division[i]['division_name'] !== 'Unassigned'
              ) {
                justUnassigned = true;
              }
            }
            if (!justUnassigned) {
              errorArray.push(lsArr[i].title);
              correctInput = false;
              errors[lsArr[i].field] =
                'To change to this status you cannot be an Unassigned Division';
            } else {
              if (
                !props.userProfile.permissions.lsRequest_api
                  .approve_status_change
              ) {
                if (!promptOpen) {
                  correctInput = false;
                  setPromptOpen(true);
                }
              }
            }
          }
        } else {
          if (errorField[lsArr[i].field]) {
            errors[lsArr[i].field] = '';
          }
        }
      }
    }
    setErrorField(errors);
    setErrorMessage(errorArray);
    return correctInput;
  };

  const displayAllErrorFields = () => {
    if (errorMessage.length > 1) {
      let quoteErrors = errorMessage.slice(0, -1);
      quoteErrors = quoteErrors.map(error => {
        return (
          <>
            "<strong>{error}</strong>",
          </>
        );
      });
      return (
        <>
          {quoteErrors}and "<strong>{errorMessage.slice(-1)}</strong>"
        </>
      );
    } else {
      return (
        <>
          "<strong>{errorMessage[0]}</strong>"
        </>
      );
    }
  };

  const onDeleteAttachment = (e, ls, field, id) => {
    props.deleteFile(ls, id, field);
    setCurtLs({
      ...curtLs,
      [field]: curtLs[field].filter(item => item.id !== id),
      uploadDeleted: true,
    });
    e.stopPropagation();
  };

  const onDeleteNewAttachment = (e, field, id) => {
    setNewFields({
      ...newFields,
      [field]: newFields[field].filter((f, index) => index !== id),
    });
    e.stopPropagation();
  };

  // Send out HTTP request to update the info
  const onUpdate = () => {
    let validation = checkValidation();
    if (validation) {
      let { allUserList, committeeList, updateTargetLsRequest } = props;
      let lsRequest = Object.assign({}, curtLs, {
        background_inspiration: inspirationType,
        bill_matrix: newFields['bill_matrix'],
        bill: newFields['bill'],
        cm_attachment: newFields['cm_attachment'],
        committee: curtLs.committee
          ? committeeList.find(
              committee => committee.committee_name === curtLs.committee
            ).id
          : null,
        contact_person: allUserList.find(
          cmStaffer => cmStaffer.full_name === curtLs.contact_person
        ).id,
        coversheet: newFields['coversheet'],
        first_prime: allUserList.find(
          user => user.full_name === curtLs.first_prime
        ).id,
        hearing_report: newFields['hearing_report'],
        hearing_summary: newFields['hearing_summary'],
        legal_memo: newFields['legal_memo'],
        plain_language_summary: newFields['plain_language_summary'],
        resource_letter: newFields['resource_letter'],
        staff: allUserList.find(staff => staff.full_name === curtLs.staff).id,
      });
      setErrorMessage([]);
      updateTargetLsRequest(lsRequest);
    }
  };

  const generateForm = lsField => {
    const isCMRelated = rolesUnderCouncilMembers.includes(
      props.userProfile.role
    );
    let userType;
    if (lsField.field === 'contact_person') {
      userType = 'cm_staff';
    } else if (lsField.field === 'first_prime') {
      userType = 'council members';
    } else {
      userType = 'staff';
    }

    let cmCantEdit = cmNonEditFields.includes(lsField.field) && isCMRelated;
    let formattedDate = '';
    if (lsField.type === 'date' && curtLs[lsField.field]) {
      formattedDate = curtLs[lsField.field].match(
        '^[0-9]{4}-[0-9]{2}-[0-9]{2}$'
      )
        ? formatDate(curtLs[lsField.field])
        : formatDateTime(curtLs[lsField.field]);
    }
    if (typeof curtLs[lsField.field] === "string") curtLs[lsField.field] = curtLs[lsField.field].replace(/<\s*strong[^>]*\s*>|<\s*\/\s*strong\s*>/gi, '')
    //Overwrite the committee options with the data from the backend
    if (lsField.field === 'committee')
      lsField.options = [
        'Select a Committee',
        ...props.committeeList.map(committee => committee.committee_name),
      ];
    const title = lsField.required ? `${lsField.title}*` : lsField.title;
    return (
      <div className='mb-3' style={{ position: 'relative' }}>
        <Prompt
          message='Are you sure you want to request changing to this status? The change will be applied once it is approved.'
          isOpen={promptOpen}
          setIsOpen={setPromptOpen}
          onConfirmation={e => {
            onUpdate();
            setPromptOpen(false);
          }}
        />
        {![
          'file',
          'custom',
          'textarea',
          'date',
          'checkbox',
          'hideUpdate',
          'multi-select',
          'select',
        ].includes(lsField.type) &&
          curtLs[lsField.field] !== undefined &&
          lsField.field !== 'prev_sess_ls_number' && (
            <>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}{' '}
                {lsField.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    (Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              {lsField.help && (
                <ToolTip
                  id={lsField.field}
                  target={lsField.field}
                  text={
                    lsField.help && typeof lsField.help === 'object'
                      ? lsField.help[inspirationType]
                      : lsField.help
                  }
                />
              )}
              <input
                className='form-control field-size'
                type={lsField.type}
                value={curtLs[lsField.field]}
                key={lsField.title}
                onChange={e => {
                  setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
                }}
                placeholder={
                  typeof lsField.help === 'object'
                    ? lsField.help[inspirationType]
                    : lsField.help
                }
                disabled={!lsField.changable || cmCantEdit}>
                {lsField.options &&
                  lsField.options.map((op, idx1) => (
                    <option key={idx1}>{op}</option>
                  ))}
              </input>
              {errorField[lsField.field] && (
                <p className={styles['warning-message']}>
                  {errorField[lsField.field]}
                </p>
              )}
            </>
          )}
        {lsField.field === 'prev_sess_ls_number' && (
          <>
            <label className={`form-label ${styles['input-form-title']}`}>
              {title}{' '}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  (Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            {lsField.help && (
              <ToolTip
                id={lsField.field}
                target={lsField.field}
                text={
                  lsField.help && typeof lsField.help === 'object'
                    ? lsField.help[inspirationType]
                    : lsField.help
                }
              />
            )}
            <input
              className='form-control field-size'
              type={lsField.type}
              value={curtLs[lsField.field]}
              key={lsField.title}
              onChange={e => {
                setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
              }}
              placeholder={
                typeof lsField.help === 'object'
                  ? lsField.help[inspirationType]
                  : lsField.help
              }
              disabled={!lsField.changable || cmCantEdit}>
              {lsField.options &&
                lsField.options.map((op, idx1) => (
                  <option key={idx1}>{op}</option>
                ))}
            </input>
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </>
        )}
        {lsField.field === 'prev_sess_ls_number' && (
          <>
            <label className={`form-label ${styles['input-form-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            {lsField.help && (
              <ToolTip
                id={lsField.field}
                target={lsField.field}
                text={
                  lsField.help && typeof lsField.help === 'object'
                    ? lsField.help[inspirationType]
                    : lsField.help
                }
              />
            )}
            <input
              className='form-control field-size'
              type={lsField.type}
              value={curtLs[lsField.field]}
              key={lsField.title}
              onChange={e => {
                setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
              }}
              placeholder={
                typeof lsField.help === 'object'
                  ? lsField.help[inspirationType]
                  : lsField.help
              }
              disabled={!lsField.changable || cmCantEdit}
            />
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </>
        )}
        {lsField.type === 'textarea' && curtLs[lsField.field] !== undefined && (
          <>
            <label className={`form-label ${styles['input-form-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            <textarea
              className='form-control field-size'
              rows='8'
              value={curtLs[lsField.field]}
              onChange={e => {
                setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
              }}
              placeholder={
                typeof lsField.help === 'object'
                  ? lsField.help[inspirationType]
                  : lsField.help
              }
              disabled={!lsField.changable || cmCantEdit}
            />
          </>
        )}
        {lsField.type === 'select' && curtLs[lsField.field] !== undefined && (
          <>
            <label className={`form-label ${styles['input-form-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            {lsField.help && (
              <ToolTip
                id={lsField.field}
                target={lsField.field}
                text={
                  lsField.help && typeof lsField.help === 'object'
                    ? lsField.help[inspirationType]
                    : lsField.help
                }
              />
            )}
            <select
              className='form-control field-size'
              type={lsField.type}
              value={curtLs[lsField.field]}
              key={lsField.title}
              placeholder={
                typeof lsField.help === 'object'
                  ? lsField.help[inspirationType]
                  : lsField.help
              }
              disabled={!lsField.changable || cmCantEdit}
              onChange={e => {
                if (lsField.field === 'reintroduction') {
                  setCurtLs({
                    ...curtLs,
                    [lsField.field]:
                      e.target.value === 'true'
                        ? true
                        : e.target.value === 'false'
                        ? false
                        : e.target.value,
                  });
                } else {
                  setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
                }
              }}>
              {lsField.options &&
                lsField.options.map((op, idx1) => {
                  if (
                    [
                      'Select a Committee',
                      'Select a Division',
                      'Select an LSR Type',
                      'Select a Status',
                      'Select one',
                    ].includes(op)
                  ) {
                    return (
                      <option key={idx1} value={null} hidden>
                        {op}
                      </option>
                    );
                  }
                  if (lsField.field === 'reintroduction') {
                    return (
                      <option
                        key={idx1}
                        value={
                          op === 'N/A' ? 'None' : op === 'Yes' ? true : false
                        }>
                        {op}
                      </option>
                    );
                  }
                  return <option key={idx1}>{op}</option>;
                })}
            </select>
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </>
        )}
        {/* Only for "Matters" */}
        {lsField.type === 'multi-select' && curtLs[lsField.field] !== undefined && (
          <div className={styles['checkbox-display']}>
            <label className={`form-label ${styles['input-form-title']}`}>
              {title}
            </label>
            {lsField.help && (
              <ToolTip id={lsField.field} target={lsField.field} text={lsField.help} />
            )}
            {
              lsField.field === 'matters' && (
                <TagsDisplay
                    reference={React.createRef()}
                    currentList={curtLs[lsField.field]}
                    warningMessage='No matters have been selected.'
                    param={'intro'}
                    dropdownList={[]}
                    disabled={!lsField.changable || cmCantEdit}
                    placeholder={lsField.help}
                    noList={true}
                    onAddTarget={selected=>onAddSelectedLocalLaw(selected)}
                    onDeleteTarget={selected=>onDeleteTargetLocalLaw(selected)}
                  />
              )
            }
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </div>
        )}
        {lsField.type === 'checkbox' && curtLs[lsField.field] !== undefined && (
          <div className='form-check'>
            <input
              type='checkbox'
              className={`form-check-input ${styles['checkbox']}`}
              checked={curtLs[lsField.field]}
              key={lsField.title}
              onChange={e => {
                setCurtLs({
                  ...curtLs,
                  [lsField.field]: !curtLs[lsField.field],
                });
              }}
              disabled={
                lsField.field === 'make_first_prime_public'
                  ? isCMRelated && props.updateTargetLs[lsField.field]
                  : !lsField.changable || cmCantEdit
              }
            />
            <label className={`form-confirm-label ${styles['ls-title']}`}>
              {title}&nbsp;
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </div>
        )}

        {lsField.type === 'date' && curtLs[lsField.field] !== undefined && (
          <>
            <label className={`form-label ${styles['ls-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            <input
              className='form-control field-size'
              type='text'
              value={formattedDate}
              key={lsField.title}
              onChange={e => {
                setCurtLs({ ...curtLs, [lsField.field]: e.target.value });
              }}
              disabled={!lsField.changable || cmCantEdit}>
              {lsField.options &&
                lsField.options.map((op, idx1) => (
                  <option key={idx1}>{op}</option>
                ))}
            </input>
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </>
        )}
        {lsField.type === 'custom' && curtLs[lsField.field] !== undefined && (
          <>
            <label className={`form-label ${styles['ls-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            <input
              className='form-control field-size'
              type='text'
              value={curtLs[lsField.field]}
              key={lsField.title}
              onChange={(e) => {
                setCurtLs({...curtLs, [lsField.field]: e.target.value })}}
              onFocus={() => {
                setCurtLs({ ...curtLs, [`${lsField.field}_focus`]: true });
              }}
              disabled={!lsField.changable || cmCantEdit}
              required
            />
            <UserSelect
              search={curtLs[lsField.field]}
              typeOfUsers={userType}
              handleClick={e => {
                if (lsField.field === 'first_prime') {
                  setIsSponsorFocused(true)
                  setCurtLs({
                    ...curtLs,
                    [lsField.field]: e.target.innerText,
                    contact_person: '',
                    [`${lsField.field}_focus`]: false,
                  });
                } else {
                  setCurtLs({
                    ...curtLs,
                    [lsField.field]: e.target.innerText,
                    [`${lsField.field}_focus`]: false,
                  });
                }
              }}
              sponsor={
                props.allUserList.find(
                  user => user.full_name === curtLs['first_prime']
                )?.full_name
              }
              focused={curtLs[`${lsField.field}_focus`]}
            />
            {/* Render the contact person field if the first prime is edited in the single cell edit modal */}
            {lsField.field === 'first_prime' && isSponsorFocused && (
              <>
               <label className={`form-label ${styles['ls-title']}`}>
                {isSponsorFocused ? 'Select A New Contact Person' : "Contact Person"}
               </label>
                <input
                  className='form-control field-size'
                  type='text'
                  value={curtLs['contact_person']}
                  key={`${lsField.title}-new-contact`}
                  onChange={(e) => {setCurtLs({...curtLs, 'contact_person': e.target.value })}}
                  onFocus={() => {
                    setCurtLs({ ...curtLs, 'contact_person_focus': true });
                  }}
                  disabled={!lsField.changable || cmCantEdit}
                  required
                />
                <UserSelect
                  search={curtLs['contact_person']}
                  typeOfUsers={'cm_staff'}
                  handleClick={e => {
                    setCurtLs({
                      ...curtLs,
                      'contact_person': e.target.innerText,
                      'contact_person_focus': false,
                    })
                  }}
                  sponsor={
                    props.allUserList.find(
                      user => user.full_name === curtLs[lsField.field]
                    )?.full_name
                  }
                  focused={curtLs['contact_person_focus']}
                />
              </>
            )}
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
          </>
        )}
        {lsField.type === 'file' && curtLs[lsField.field] !== undefined && (
          <>
            <label className={`form-label ${styles['ls-title']}`}>
              {title}
              {lsField.viewability === 'staffOnly' ? (
                <span className={styles['not-visible-to-cm']}>
                  &nbsp;(Not visible to CMs)
                </span>
              ) : (
                ''
              )}
            </label>
            <input
              className='form-control field-size'
              type='file'
              accept={acceptedFileTypes}
              key={lsField.title}
              onChange={e => {
                let error_messages = [];
                for (let i = 0; i < e.target.files.length; i++) {
                  // If the file size is more than 100MB do not input
                  if (e.target.files[i].size > 100000000) {
                    error_messages.push(
                      e.target.files[i].name + ' is too large'
                    );
                  }
                }
                if (error_messages.length) {
                  e.target.value = null;
                  // setFile_limit_exceeded(lsField.field);
                  // setFile_error_message(error_messages.join(', '));
                } else {
                  let files = e.target.files;

                  // let error = [];
                  for (let i = 0; i < files.length; i++) {
                    files[i].file_name = files[i].name;
                    // error.push( files[i].name + 'is too large');
                    let updatedField = [...newFields[lsField.field], files[i]];
                    setNewFields({
                      ...newFields,
                      [lsField.field]: updatedField,
                    });
                  }
                }
              }}
              multiple
              disabled={!lsField.changable || cmCantEdit}></input>
            <br />
            {curtLs[lsField.field].map((file, index) => (
              <File
                id={`lsr_old_${lsField.field}-${index}`}
                file={file}
                getFileAndDownload={props.getFileAndDownload}
                key={index}
                onDelete={e =>
                  onDeleteAttachment(e, curtLs, lsField.field, file.id)
                }
                deletePermission={
                  props.userProfile.permissions.lsRequest_api
                    .change_lsrequest && !isCMRelated
                }
              />
            ))}
            {newFields[lsField.field] &&
              newFields[lsField.field].map((file, index) => (
                <File
                  id={`lsr_new_${lsField.field}-${index}`}
                  newUpload={true}
                  file={{ file: file, ...file }}
                  getFileAndDownload={props.getFileAndDownload}
                  key={index}
                  onDelete={e => onDeleteNewAttachment(e, lsField.field, index)}
                  deletePermission={true}
                />
              ))}
            {errorField[lsField.field] && (
              <p className={styles['warning-message']}>
                {errorField[lsField.field]}
              </p>
            )}
            <br />
          </>
        )}
      </div>
    );
  };

  let filteredFields = nonLegDivisionRoles.includes(props.userProfile.role)
    ? cmEditFields
    : staffEditFields;
  filteredFields = filteredFields.filter(
    lsr =>
      !['overlapping_ls', 'related_ls', 'duplicate_check'].includes(lsr.field)
  );

  let fieldName = filteredFields.find(lsr => lsr === props.cellType);
  let field = LsFields.find(lsr => fieldName === lsr.field);
  if (!field) return null;


  return (
    <div className={stylesModal['modal-expansion']} id='cell-edit-modal'>
      {/* UPDATE LSR HEADER */}
      <div>
        <div className={styles['update-ls-request-header-container']}>
          <div
            className={`popup-detail-title ${stylesModal['modal-title']}`}>
            Update LS Request
          </div>
        </div>
      </div>
      {/* END UPDATE LSR HEADER */}

      {/* UPDATE LSR FORM BODY */}
      <div id='ls-update-error-success-msg'>
        {/* FAIL MESSAGE */}
        {props.updateTargetLsStatus === 'fail' && (
          <div
            className={`alert alert-danger ${styles['success-create-message']}`}
            role='alert'>
            <p>{props.errorMessage}</p>
          </div>
        )}
        {/* END FAIL MESSAGE */}

        {/* ERROR MESSAGE */}
        {errorMessage.length > 0 && (
          <div
            className={`alert alert-danger ${styles['error-message']}`}
            role='alert'>
            <div>
              <ExclamationPointDiamondIcon />
            </div>
            <div>
              {errorMessage.length < 4 && (
                <>
                  There was an error in the following field(s):
                  {displayAllErrorFields()}.
                </>
              )}
              {errorMessage.length >= 4 && (
                <>
                  Errors were found in
                  <strong style={{ letterSpacing: '1px' }}>
                    {errorMessage.length}
                  </strong>
                  fields.
                </>
              )}
            </div>
          </div>
        )}
        {/* END ERROR MESSAGE */}

        {/* UPDATE LSR SUCCESS MESSAGE */}
        {props.updateTargetLsStatus === 'success' && (
          <div className={styles['success-message-container']}>
            <div
              className={`alert alert-success ${styles['success-create-message']}`}
              role='alert'>
              <p>
                LSR #<b>{curtLs.ls_number}</b> has been updated!
              </p>
              <p>&nbsp;Page will refresh shortly.</p>
            </div>
          </div>
        )}
        {/* END UPDATE LSR SUCCESS MESSAGE */}

        <form className='row'>
          <div className='col'>{generateForm(field)}</div>
        </form>
      </div>
      {/* END UPDATE LSR FORM BODY */}
      {/* UPDATE LSR FORM FOOTER */}

      <div className={styles['update-ls-request-footer']}>
        {props.updateTargetLsStatus !== 'success' && (
          <div className={styles['update-pop-up-container']}></div>
        )}
        {props.updateTargetLsStatus !== 'success' && props.isFile && (
          <button className='cancel-button-text' onClick={props.closeModal}>
            Close
          </button>
        )}
        {props.updateTargetLsStatus !== 'success' && !props.isFile && (
          <button className='cancel-button-text' onClick={props.closeModal}>
            Cancel
          </button>
        )}
        <button
          className='submit-button'
          disabled={
            props.updateTargetLsStatus === 'working...' ||
            props.updateTargetLsStatus === 'success'
          }
          onClick={onUpdate}>
          {props.updateTargetLsStatus === 'working...' && (
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'></span>
          )}
          Save
        </button>
      </div>
      {/* END UPDATE LSR FORM FOOTER */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    allUserList: state.userReducer.allUserList,
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    committeeList: state.userReducer.committees,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    updateTargetLsStatus: state.lsRequestsReducer.updateTargetLsStatus,
    errorMessage: state.lsRequestsReducer.errorMessage,
  };
};

const mapDispatchToProps = {
  updateTargetLsRequest,
  getFileAndDownload,
  deleteFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateLsRequestPopup);
