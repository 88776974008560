import axios from 'axios';
import { format } from 'date-fns';
import { url } from '../config';
import {
  rolesUnderCouncilMembers,
  agencyRoles,
  superAdminRoles,
  defaultLSRDocumentTypes,
  defaultLSRFieldTypes
} from '../services/constants';

export const acceptedFileTypes =
  '.docx, .csv, .xls, .xlsx, .xml, .html, .ppt, .pptx, image/*, application/msword, application/vnd.ms-excel, application/pdf, application/zip, application/vnd.ms-excel.addin.macroenabled.12, application/vnd.ms-excel.sheet.binary.macroenabled.12, application/vnd.ms-excel.template.macroenabled.12, application/vnd.ms-excel.sheet.macroenabled.12';

export const saveToken = (token, type) => {
  const obj = { token: token, type: type };
  sessionStorage.setItem('token', JSON.stringify(obj));
};
export const logout = () => {
  axios.defaults.headers.common['Clear-Site-Data'] = '*'; //other options are cache, cookies, storage, or execution Contexts
  axios
    .post(url + '/api/users/logout/')
    .then(res => { })
    .catch(e => {
      //Logout endpoint returns a 401 for some reason. Will look into
      console.log(e.message);
    })
    .finally(() => {
      sessionStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      delete axios.defaults.headers.common['Clear-Site-Data'];
      window.location = '/';
    });
};
// Add authorization header
export const setAuthorizationToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};
export function getNextResults(url) {
  return axios.get(url);
}
// Delay duration
// Give more time for finishing api request and data process
export const delay = (seconds = 1) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};

export const formatDateTime = dateTime => {
  return format(new Date(dateTime), 'M/d/yyyy h:mma');
};
export const formatDate = date => {
  if (date) {
    let splitDate = date.split('-');
    return format(
      new Date(splitDate[0], splitDate[1] - 1, splitDate[2]),
      'M/d/yyyy'
    );
  } else {
    return '';
  }
};
export const stringToDate = str => {
  const date = str.split('-');
  return new Date(date[0], date[1] - 1, date[2]);
};
export const extractFileName = fileURL => {
  if (fileURL) {
    return fileURL.substring(
      fileURL.lastIndexOf('/') + 1,
      fileURL.indexOf('?')
    );
  } else {
    return '';
  }
};

export function zipTargetFiles(files) {
  if (!files || files.length === 0) {
    return null;
  }
  //Making sure that the file has a file size to make sure this is a file uploaded and not data from the backend
  if (!files[0].size) {
    return null;
  }

  if (files.length <= 1) {
    return files[0];
  } else {
    const zip = require('jszip')();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i]);
    }
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 1 },
      mimeType: 'application/zip',
    });
  }
}

//Get an array of the uploaded files without zipping them
export function getTargetFiles(files) {
  if (!files || files.length === 0) {
    return null;
  }
  if (files.length === 1) {
    if (!files[0].size) {
      return null;
    } else {
      return [files[0]];
    }
  } else {
    let fileArray = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size) {
        fileArray.push(files[i]);
      }
    }
    if (fileArray.length === 0) {
      return null;
    } else {
      return fileArray;
    }
  }
}

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1);
export const capitalizeWords = words => {
  let splitWords = words.split(' ');
  let wordsCapitalize = splitWords.map(word => {
    return capitalize(word);
  });
  return wordsCapitalize.join(' ');
};

//Filters the user list to not include council members
export const staff = users =>
  users.filter(
    user =>
      ![
        ...rolesUnderCouncilMembers,
        ...agencyRoles,
        ...superAdminRoles,
      ].includes(user.role)
  );

//Filters the user list to only have council members
export const councilMembers = users =>
  users.filter(
    user =>
      user.role.toLowerCase().includes('council member') ||
      user.role.toLowerCase().includes('public advocate')
  );

export const cmStaffers = (users, cm, currentUser) => {
  let staffers = users.filter(
    user => user.under_council_member === cm.id || user.id === cm.id
  );
  if (cm.role === 'Speaker/Council Member') {
    staffers.push(currentUser);
  }
  let uniqueStafferIds = new Set()
  staffers = staffers.filter(user_obj => {
    if (uniqueStafferIds.has(user_obj.id)) {
      return false;
    }
    uniqueStafferIds.add(user_obj.id);
    return true;
  })
  return staffers;
};

//Everyone but Admin and Admin Helper
export const filterUsers = users =>
  users.filter(user => !superAdminRoles.includes(user.role));

export const getCMorRelated = userProfile => {
  if (rolesUnderCouncilMembers.includes(userProfile.role)) {
    if (
      ['Speaker/Council Member', 'Council Member'].includes(userProfile.role)
    ) {
      return userProfile.id;
    } else {
      return userProfile.under_council_member;
    }
  }
  return '';
};

export const refreshPage = () => {
  window.location.reload();
};

export const getHumanTime = timestamp => {
  let timeDiff = new Date(timestamp).getTime() - new Date().getTime();
  let time = Math.abs(timeDiff);

  // Define humanTime and units
  let humanTime, units;
  if (time > 1000 * 60 * 60 * 24 * 365) {
    // If there are years
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
    units = humanTime > 1 ? 'years' : 'year';
  } else if (time > 1000 * 60 * 60 * 24 * 30) {
    // If there are months
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
    units = humanTime > 1 ? 'months' : 'month';
  } else if (time > 1000 * 60 * 60 * 24 * 7) {
    // If there are weeks
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
    units = humanTime > 1 ? 'weeks' : 'week';
  } else if (time > 1000 * 60 * 60 * 24) {
    // If there are days
    humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
    units = humanTime > 1 ? 'days' : 'day';
  } else if (time > 1000 * 60 * 60) {
    // If there are hours
    humanTime = parseInt(time / (1000 * 60 * 60), 10);
    units = humanTime > 1 ? 'hours' : 'hour';
  } else if (time > 1000 * 60) {
    // If there are minutes
    humanTime = parseInt(time / (1000 * 60), 10);
    units = humanTime > 1 ? 'minutes' : 'minute';
  } else {
    // Otherwise, use seconds
    humanTime = parseInt(time / 1000, 10);
    units = humanTime > 1 ? 'seconds' : 'second';
  }

  return humanTime + ' ' + units;
};


export function flattenObject(obj, parentKey = '', result = {}) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Skip 'visible' keys
      if (key === 'visible') continue;

      let propName = parentKey ? parentKey + '.' + key : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the object has a 'value' key, add only this to the result
        if (obj[key].hasOwnProperty('value')) {
          result[propName] = obj[key].value;
        } else {
          // Otherwise, continue flattening
          flattenObject(obj[key], propName, result);
        }
        if (key === 'send_tracker_reminders') {
          flattenObject(obj[key], propName, result);
        }
      }
    }
  }
  return result;
}

// Function to truncate the middle of the file name.
export function truncateMiddle(str, beginning = 15, ending = 8) {
  if (str.length <= beginning + ending) return str;
  const start = str.substring(0, beginning);
  const end = str.substring(str.length - ending);
  return `${start}...${end}`;
};

export const isConfidential = (
  activeUserProfile,
  targetedLsWaiverStatus,
  lsUserProfile = { 'full_name': '' }
) => {
  // Returns true if: the activeUserProfile (user who's logged in now) is a CM or CM-related user
  // AND the activeUserProfile is NOT listed as the Prime or Co-Sponsor of a spefic LS (targetedLsWaiverStatus)
  // AND the LSR in question has not waived their confidentiality (make_first_prime_public)
  return (
    rolesUnderCouncilMembers.includes(activeUserProfile.role) &&
    activeUserProfile.full_name !== lsUserProfile.full_name &&
    !targetedLsWaiverStatus
  );
};

export const addToastInfo = (toastTitle, toastBody, toastApp, toastAppAlt) => {
  let toastInfo = {
    showToast: true,
    toastTitle,
    toastBody,
    toastApp,
    toastAppAlt,
  };
  toastInfo = JSON.stringify(toastInfo);
  localStorage.setItem('toastInfo', toastInfo);
};

export const generateSafeDateFromMonthYear = mmmYYYYDate => {
  const dateParts = mmmYYYYDate.split(' ');

  if (dateParts.length == 2) {
    const year = dateParts[1];
    const month = dateParts[0];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthIndex = monthNames.indexOf(month);
    if (monthIndex != -1) {
      const formattedDate = new Date(year, monthIndex);
      return formattedDate;
    } else {
      console.log('Invalid month name');
    }
  } else {
    console.log('Invalid date format');
  }
};

export const getFilteredDefaultLSRDocumentTypes = (userRole, LsFields) => {
  const isUserUnderCouncilMember = rolesUnderCouncilMembers.includes(userRole);
  const filteredDefaults = { ...defaultLSRDocumentTypes };
  for (const key in filteredDefaults) {
    if (key === 'Check All') continue;
    const field = LsFields.find((f) => f.title === key);
    if (field) {
      const { viewability } = field;
      if (
        viewability === 'none' ||
        (viewability === 'staffOnly' && isUserUnderCouncilMember)
      ) {
        delete filteredDefaults[key];
      }
    }
  }
  return filteredDefaults;
};

export const getFilteredDefaultLSRFieldTypes = (userRole, LsFields) => {
  const isUserUnderCouncilMember = rolesUnderCouncilMembers.includes(userRole);
  const filteredDefaults = { ...defaultLSRFieldTypes };
  for (const key in filteredDefaults) {
    if (key === 'Check All') continue;
    const field = LsFields.find((f) => f.title === key);
    if (field) {
      const { viewability } = field;
      if (
        viewability === 'none' ||
        (viewability === 'staffOnly' && isUserUnderCouncilMember)
      ) {
        delete filteredDefaults[key];
      }
    }
  }
  return filteredDefaults;
};

export const includesNormalizedSubstring = (str, substring) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .includes(
      substring
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    )