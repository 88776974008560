import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filterUsers,
  staff,
  councilMembers,
  cmStaffers,
} from '../../utils/helper';
import styles from '../../styles/UserSelect.module.scss';

/**
 * You will need to pass in the function that set's the selected value to the state
 */
const UserSelect = props => {
  let users;
  let cm = props.users.filter(user => user.full_name === props.sponsor);
  switch (props.typeOfUsers) {
    case 'staff':
      users = staff(props.users);
      break;
    case 'council members':
      users = councilMembers(props.users);
      break;
    case 'cm_staff':
      users = cm.length === 1 ? cmStaffers(props.users, cm[0], props.userProfile) : [];
      break;
    default:
      users = filterUsers(props.users);
  }
  const filteredUsers = users?.sort((a, b) => {
    // Convert names to lowercase for case-insensitive sorting
    let nameA = a.full_name.toLowerCase();
    let nameB = b.full_name.toLowerCase();

    // Compare names
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // Names are equal
    return 0;
  }).filter(user =>
      user.full_name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, '').includes(props.search.toLowerCase())
  );
  if (!props.focused ) {
    return null
  } else if (props.focused && props.search.length !== props.sponsor?.length) {
    return (
      <ul className={styles['UserSelect']}>
        {
          filteredUsers?.length === 0 || users.length === 0 ?
          <li style={{color:"rgb(221, 68, 47)"}}>----- No Matches -----</li> :
          filteredUsers?.map((user, id) => {
              return (
                <li onClick={props.handleClick} key={id}>
                  {user.full_name}
                </li>
              )
          })
        }
      </ul>
    )
  }
};
const mapStateToProps = state => {
  return {
    users: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};
UserSelect.propTypes = {
  search: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  typeOfUsers: PropTypes.string,
};
export default connect(mapStateToProps)(UserSelect);
