const ReportFields = [
  {
    title: 'Tracker Created By',
    field: 'submitted_by',
    tracker: 'Both',
    type: 'select',
    create: false,
    editable: false,
    required: false,
    multiple: false,
  },
  {
    title: 'Tracker Type',
    field: 'tracker_type',
    tracker: 'Both',
    type: 'select',
    options: ['Report', 'Milestone'],
    create: true,
    editable: false,
    required: true,
  },
  {
    title: 'Title',
    field: 'title',
    tracker: 'Both',
    type: 'text',
    create: true,
    editable: true,
    required: true,
  },
  {
    title: 'Agency',
    field: 'submitting_agency',
    tracker: 'Both',
    type: 'text',
    create: true,
    editable: true,
    required: true,
  },
  {
    title: 'Division',
    field: 'division',
    tracker: 'Both',
    type: 'select',
    options: ['BIL', 'COMP', 'FIN', 'GOV', 'HUM', 'INF', 'LU', 'OGC'],
    create: true,
    editable: true,
    required: true,
    multiple: false,
  },
  {
    title: 'Committee',
    field: 'committees',
    tracker: 'Both',
    type: 'select',
    create: true,
    editable: true,
    required: true,
    multiple: true,
  },
  {
    title: 'General Notes',
    field: 'general_notes',
    tracker: 'Both',
    type: 'textarea',
    create: true,
    editable: true,
    required: false,
  },
  {
    title: 'Compliance Notes',
    field: 'compliance_notes',
    tracker: 'Milestone',
    type: 'textarea',
    create: true,
    editable: true,
    required: false,
  },
  {
    title: 'Errors',
    field: 'errors',
    tracker: 'Report',
    type: 'textarea',
    create: false,
    editable: true,
    required: false,
  },
  {
    title: 'Administration Contact',
    field: 'agency_contact',
    tracker: 'Both',
    type: 'select',
    create: true,
    editable: 'Both',
    required: false,
    multiple: true,
  },
  {
    title: 'Committee Staff',
    field: 'assigned_contact',
    tracker: 'Both',
    type: 'select',
    create: true,
    editable: 'Both',
    required: true,
    multiple: true,
  },
  {
    title: 'Local Laws',
    field: 'local_law',
    tracker: 'Both',
    type: 'text',
    create: true,
    editable: true,
    required: false,
    help: '(e.g., https://legistar.council.nyc.gov/LegislationDetail.aspx?...)',
    multiple: true,
  },
  {
    title: 'Legacy Local Laws(pre-1998)',
    field: 'legacy_local_law',
    tracker: 'Both',
    type: 'text',
    create: true,
    editable: true,
    required: false,
    help: '(eg. LL 44/2002)',
    multiple: true,
  },
  {
    title: 'LS Requests',
    field: 'ls_requests',
    tracker: 'Both',
    type: 'select',
    create: true,
    editable: true,
    required: false,
    multiple: true,
  },
  {
    title: 'Unit',
    field: 'frequency_unit',
    tracker: 'Report',
    type: 'select',
    options: ['Days', 'Weeks', 'Months', 'Years', 'Once', 'As Needed'],
    required: true,
    create: true,
    editable: true,
    multiple: false,
  },
  {
    title: 'Period',
    field: 'frequency_quantity',
    tracker: 'Report',
    type: 'number',
    required: true,
    create: true,
    editable: true,
    multiple: false,
  },
  {
    title: 'First Due Date',
    field: 'start_date',
    tracker: 'Both',
    type: 'date',
    create: true,
    editable: false,
    required: true,
  },
  {
    title: 'End Date',
    field: 'end_date',
    tracker: 'Report',
    type: 'date',
    create: true,
    editable: true,
    required: false,
  },
  {
    title: 'Next Due',
    field: 'next_due',
    tracker: 'Report',
    type: 'date',
    create: true,
    editable: true,
    required: true,
  },
  {
    title: 'Last Received',
    field: 'last_received',
    tracker: 'Report',
    type: 'date',
    create: false,
    editable: false,
    required: false,
  },
  {
    title: 'Completed',
    field: 'completed',
    tracker: 'Milestone',
    type: 'checkbox',
    options: [true, false], //No longer needed
    create: false,
    editable: true,
    required: true,
    multiple: false,
  },
  {
    title: 'Notes on Due Date',
    field: 'notes_on_due_date',
    tracker: 'Both',
    type: 'text',
    create: true,
    editable: true,
    required: false,
  },
  {
    title: 'Select Report',
    field: 'attachment',
    tracker: 'Report',
    type: 'file',
    create: false,
    editable: true,
    required: false,
  },
  {
    title: 'Milestone Attachments',
    field: 'milestone_attachment',
    tracker: 'Milestone',
    type: 'file',
    create: false,
    editable: true,
    required: false,
  },
  {
    title: 'Current Report Past Due',
    field: 'current_report_past_due',
    tracker: 'Report',
    type: 'checkbox',
    options: [false, true],
    create: false,
    editable: false,
    required: false,
    multiple: false,
  },
  {
    title: 'Milestone Confirm',
    field: 'confirmation',
    tracker: 'Milestone',
    type: 'custom',
    create: false,
    editable: false,
    required: false,
    multiple: false,
  }
];

export default ReportFields;
