import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import { LsFields } from '../../services/LsFields';
import {
  nonLegDivisionRoles,
  rolesUnderCouncilMembers,
} from '../../services/constants';
import FilterInput from './FilterInput';

const FilterList = ({
  ls,
  idx,
  allUserOptions,
  staffOptions,
  cmOptions,
  committeeOptions,
  filterValues,
  menuRef,
  userMap,
  committeeMap,
  onAddFilter,
  onRemoveFilter,
}) => {
  let type = 'text';
  let options = [];
  let category = '';
  let selectedOptions = filterValues[ls.field].value;
  if (ls.field !== 'ls_number') {
    type = 'dropdown';
    if (ls.field === 'staff') {
      options = staffOptions;
      category = 'users';
    } else if (ls.field === 'submitted_by') {
      options = allUserOptions;
      category = 'users';
    } else if (ls.field === 'first_prime') {
      options = cmOptions;
      category = 'users';
    } else if (ls.field === 'committee') {
      options = committeeOptions;
      category = 'committee';
    } else {
      options = ls.options.slice(1).map(op => ({ value: op, name: op }));
      category = 'other';
    }
  } else {
    type = 'text';
    selectedOptions =
      selectedOptions.length > 0 ? selectedOptions.split(',') : [];
    category = 'ls_number';
  }

  return (
    <div key={idx} className={styles['filter-option-row']}>
      <input
        className={styles['filter-option-hidden-check']}
        type='checkbox'
        onChange={() => {}}
        checked={filterValues[ls.field].check}
      />
      <div className={styles['filter-option-dropdown']}>
        <FilterInput
          type={type}
          field={ls.field}
          menuRef={menuRef}
          selectedOptions={selectedOptions}
          dropdownOptions={options}
          inputTitle={ls.title}
          disabled={filterValues[ls.field].locked}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          userMap={userMap}
          committeeMap={committeeMap}
          category={category}
          placeholder={
            filterValues[ls.field].locked
              ? userMap.get(parseInt(filterValues[ls.field].value[0]))
              : ''
          }
        />
      </div>
    </div>
  );
};

/**
 * FilterMenu component
 * @param {*} props: all properties of this component passed down from parent component
 * @returns main component for filter menu
 */
const FilterMenu = props => {
  const {
    filterValues,
    filterPreset,
    changeFilterPreset,
    toggleFilterMenu,
    onClearFilter,
    onAddFilter,
    onRemoveFilter,
    applyFilters,
    setOptionsChanged
  } = props;
  const outsideClickRef = useRef(null);
  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    function handleOutsideClick(e) {
      setOptionsChanged(true)
      let filterMenuButton = document.getElementById('filterMenuToggle');
      if (
        outsideClickRef.current &&
        !outsideClickRef.current.contains(e.target)
      ) {
        if (
          filterMenuButton &&
          !filterMenuButton.contains(e.target)
          // && !e.target.closest('.filter-options-container') &&
          // !e.target.closest('.filter-option-row')
        ) {
          toggleFilterMenu();
        }

      }
    }
    // Click event to detect clicks outside of the open filter menu to close it
    document.addEventListener('mouseup', handleOutsideClick, false);

    /* component unmount */
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  // Sort all users and committees alphabetically
  const sorted_names = props.userList.sort((a, b) => {
    return a.full_name.localeCompare(b.full_name);
  });
  const sorted_committees = props.committeeList.sort((a, b) => {
    return a.committee_name.localeCompare(b.committee_name);
  });

  // maps for storing id to name for quick access as only value is stored
  const userMap = new Map(sorted_names.map(user => [user.id, user.full_name]));
  const committeeMap = new Map(
    sorted_committees.map(com => [com.id, com.committee_name])
  );

  const allUserOptions = sorted_names.map(user => ({
    value: user.id,
    name: user.full_name,
  }));
  const staffOptions = sorted_names
    .filter(op => !nonLegDivisionRoles.includes(op.role))
    .map(user => ({ value: user.id, name: user.full_name }));
  const cmOptions = sorted_names
    .filter(
      op =>
        op.role.includes('Council Member') ||
        op.role.includes('Public Advocate') ||
        op.role.includes('Borough President')
    )
    .map(user => ({ value: user.id, name: user.full_name }));
  const committeeOptions = sorted_committees.map(com => ({
    value: com.id,
    name: com.committee_name,
  }));

  let alphaLsFields = Array.from(LsFields);
  alphaLsFields.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  let filterFields = alphaLsFields.filter(ls => {
    switch (ls.field) {
      case 'committee':
        if (!rolesUnderCouncilMembers.includes(props.userProfile.role)) {
          return ls;
        }
        break;
      default:
        if (ls.searchable) {
          return ls;
        }
    }
    return null;
  });

  /**
   * presetsOptions
   * @returns a component for selecting filter presets
   */
  const presetOptions = [
    props.userProfile.username,
    `${props.userProfile.username}-preset-1`,
    `${props.userProfile.username}-preset-2`,
    window.location.search.includes('lsr_batch')
      ? 'lsr_batch'
      : `${props.userProfile.username}-preset-3`,
  ].map((value, idx) => {
    return (
      <div
        key={`filter_${idx}_${value}`}
        className={`${styles['column-checkbox-container']} col-6 col-sm-3`}>
        <input
          className={styles['column-checkbox']}
          id={
            value === props.userProfile.username
              ? 'filter-no-preset'
              : `filter-${value}`
          }
          type='radio'
          name='filter-presets'
          value={`filter-${value}`}
          checked={filterPreset === `filter-${value}`}
          readOnly
        />
        <label
          className={styles['column-checkbox-label']}
          htmlFor={
            value === props.userProfile.username
              ? 'filter-no-preset'
              : `filter-${value}`
          }
          onClick={() => {
            changeFilterPreset(`filter-${value}`);
          }}
        />
        <label
          className={styles['column-checkbox-text-label']}
          htmlFor={
            value === props.userProfile.username
              ? 'filter-no-preset'
              : `filter-${value}`
          }
          onClick={() => {
            changeFilterPreset(`filter-${value}`);
          }}>
          {value === 'lsr_batch'
            ? 'LSR BATCH'
            : value === props.userProfile.username
            ? 'DEFAULT'
            : value.replace(`${props.userProfile.username}-preset-`, 'FILTER ')}
        </label>
      </div>
    );
  });

  return (
    <div
      ref={outsideClickRef}
      id='filter'
      className={styles['filter-menu-container']}>
      <div className='row gx-0 pb-2 text-center border-bottom'>
        {presetOptions}
      </div>
      <div className={styles['filter-options-container']}>
        {filterFields.map((ls, idx) => (
          <FilterList
            key={idx}
            ls={ls}
            idx={idx}
            allUserOptions={allUserOptions}
            staffOptions={staffOptions}
            cmOptions={cmOptions}
            committeeOptions={committeeOptions}
            filterValues={filterValues}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            menuRef={outsideClickRef}
            userMap={userMap}
            committeeMap={committeeMap}
          />
        ))}
      </div>
      <div
        className={
          'd-flex justify-content-end align-items-center p-2 g-5 border-top'
        }>
        <button
          className={'cancel-button-text'}
          onClick={() => {
            onClearFilter();
            toggleFilterMenu();
          }}>
          Clear All
        </button>
        <button
          className={'submit-button'}
          onClick={e => {
            e.preventDefault();
            applyFilters();
            toggleFilterMenu();
          }}>
          Apply Filter(s)
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    committeeList: state.userReducer.committees,
  };
};

export default connect(mapStateToProps)(FilterMenu);
