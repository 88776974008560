import React from 'react';
import styles from '../../styles/library/file.module.scss';

const Prompt = ({ message, isOpen, setIsOpen, onConfirmation }) => {
  if (!isOpen) return null;

  return (
    <div className={styles['prompt-overlay']} onClick={() => setIsOpen(false)}>
      <div
        className={styles['prompt-container']}
        onClick={e => e.stopPropagation()}>
        <div className={styles['prompt-message']}>{message}</div>
        <div className={styles['prompt-buttons']}>
          <button className={styles['delete']} onClick={onConfirmation}>
            Yes
          </button>
          <button className={styles['cancel']} onClick={() => setIsOpen(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
