import React, { useState, useEffect } from 'react';
import version from '../../../package.json';
import UploadReport from './UploadReport';
import { logout } from '../../utils/helper';
import styles from '../../styles/report-tracking/Agency.module.scss';
import { LogOutIcon, PatchQuestionFillIcon, MyAccountIcon, MyProfileIcon } from '../../services/SvgLibrary';
import FeedbackForm from '../FeedbackForm';
import { connect } from 'react-redux';
import { ProfileWidget } from '../user/myProfile/profileWidgets';

const AgencyView = (props) => {
  const [trackerTypeValue, setTrackerTypeValue] = useState('');
  const [animate, setAnimate] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  let {userProfile} = props
  const toggleFeedbackModal = () => {
    setFeedbackModalOpen(!feedbackModalOpen);
  };

  const submitFeedbackHandler = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
  };

  useEffect(() => {
    setAnimate(true);
  }, []);

  const onLogout = e => {
    logout();
  };
  return (
    <div className={styles['agency-page']}>
      {/* Fixed background image */}
      <img
        src='/img/skyscrapers-agency-page.svg'
        alt='Agency placeholder'
        width='100%'
        className={`position-fixed start-50 translate-middle-x ${
          styles['skyscrapers-background']
        } ${animate ? styles['slide-up'] : ''} ${
          trackerTypeValue ? styles['skyscrapers-blur'] : ''
        }`}
        style={{ zIndex: 1, bottom: '50px' }}
      />
      <div
        className={`modal ${styles['navbar-modal']}`}
        id='feedbackModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='feedbackFormLabel'
        aria-hidden='false'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className={`modal-header ${styles['form-header-wrapper']}`}>
              <h5 className='modal-title' id='feedbackFormLabel'>
                Submit Your Feedback
              </h5>
              {!feedbackSubmitted && (
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='modal'
                  aria-label='Close'></button>
              )}
            </div>
            <div className='modal-body p-0'>
              <FeedbackForm
                feedbackSent={feedbackSubmitted}
                feedbackHandler={submitFeedbackHandler}
                sender={userProfile.full_name}
                toggleModal={toggleFeedbackModal}
                isFeedbackOpen={feedbackModalOpen}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${styles['navbar-modal']}`}
        id='profileModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='profileFormLabel'
        aria-hidden='false'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className={`modal-header ${styles['form-header-wrapper']}`}>
              <h5 className='modal-title' id='profileFormLabel'>
                My Profile
              </h5>
              {!feedbackSubmitted && (
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='modal'
                  aria-label='Close'></button>
              )}
            </div>
            <div className='modal-body p-0'>
              <ProfileWidget/>
            </div>
          </div>
        </div>
      </div>

      {/* Main container with sticky header, scrollable content, and sticky footer */}
      <div className={styles['page-container']}>
        {/* Sticky Header */}
        <header className='position-sticky top-0 bg-transparent z-3'>
          <div className='d-flex flex-column flex-md-row align-items-center justify-content-between p-2'>
            <div className='d-flex align-items-center w-100 justify-content-between'>
              <div className='d-flex align-items-center'>
                <img
                  className='p-0'
                  src='/img/Logo/iwomm white logo.png'
                  alt='IWOMM logo'
                  width='110px'
                />
                <h1 className='m-0 ms-3 text-white lh-1 fs-5 fw-semibold d-none d-sm-block'>
                  Agency Operational Requirements
                </h1>
              </div>
            </div>
            <h1 className='m-0 my-4 text-white lh-1 fs-5 fw-semibold text-center d-sm-none'>
              Agency Operational Requirements
            </h1>
            <button
              className={`btn btn-sm dropdown-toggle ${styles['account-button-dropdown']}`}
              type='button'
              id='account-dropdown'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {userProfile.profile_pic ? (
                <img
                  alt='User Profile Icon'
                  src={
                    userProfile.profile_pic_icon.file
                      ? userProfile.profile_pic_icon.file
                      : MyProfileIcon
                  }
                />
              ) : (
                <MyAccountIcon />
              )} 
              <> My Account</>
            </button>
            <ul
              className={`dropdown-menu dropdown-menu-end ${styles['account-dropdown-menu']}`}
              aria-labelledby='account-dropdown'>
              <li>
                <button
                  className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                  data-bs-toggle='modal'
                  data-bs-target='#profileModal'>
                  <MyProfileIcon /> My Profile
                </button>
              </li>
              <li>
                <button
                  className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                  data-bs-toggle='modal'
                  data-bs-target='#feedbackModal'>
                  Help &amp; Feedback
                </button>
              </li>
              <li>
                <button
                  className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                  onClick={onLogout}>
                  Log out
                </button>
              </li>
            </ul>
          </div>
        </header>

        {/* Main Content - Scrollable */}
        <main className='flex-grow-1 overflow-auto position-relative d-flex flex-column'>
          <div className={styles['form-container']}>
            {/* Buttons */}
            {!trackerTypeValue && (
              <div className={`${styles['tracker-type-buttons']}`}>
                <div className={styles['option-card']}>
                  <h2>Upload a Report</h2>
                  <p>
                    Submit your agency's operational reports and track your
                    submission history. Upload multiple files and manage your
                    reporting requirements efficiently.
                  </p>
                  <button
                    className={styles['option-btn']}
                    onClick={() => setTrackerTypeValue('report')}>
                    Upload Report
                  </button>
                </div>

                <div className={styles['option-card']}>
                  <h2>Confirm a Milestone</h2>
                  <p>
                    Verify completion of operational milestones and provide
                    necessary documentation. Keep track of your agency's
                    progress and compliance status.
                  </p>
                  <button
                    className={styles['option-btn']}
                    onClick={() => setTrackerTypeValue('milestone')}>
                    Confirm Milestone
                  </button>
                </div>
              </div>
            )}

            {/* Form */}
            {trackerTypeValue && (
              <UploadReport
                setTrackerTypeValue={setTrackerTypeValue}
                trackerTypeValue={trackerTypeValue}
                className={animate ? styles['slide-up'] : ''}
              />
            )}
          </div>
        </main>

        {/* Sticky Footer */}
        <footer
          className={`footer d-flex flex-column flex-md-row ${
            styles['agency-footer']
          } position-sticky bottom-0 w-100 z-3 mt-auto ${
            animate ? styles['footer-slide-up'] : ''
          }`}
          style={{
            backgroundColor: '#858bb2',
            color: 'white',
          }}>
          <div
            className={`d-flex align-items-center justify-content-center p-1 pe-md-5 p-md-2  ${styles['responsive-borderTopRightRadius']}`}>
            <PatchQuestionFillIcon
              width='30px'
              height='100%'
              classNameProp='me-3'
              color='#fff'
            />
            <p>
              Questions and concerns can be addressed to the NYC Council's{' '}
              <a
                href='mailto:performance@council.nyc.gov'
                className='link-light link-offset-2 link-underline-opacity-50 link-underline-opacity-100-hover fw-bold'>
                Compliance Division
              </a>
            </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center flex-grow-1 p-1 p-md-2'
            style={{ backgroundColor: '#858bb2' }}>
            <div className='m-0 text-center text-white'>
              <span className='mb-auto fw-bold'>
                IWOMM (v {version.version})
              </span>
              <p className='fw-light'>
                Powered By New York City Council Web Dev Unit
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(AgencyView);
